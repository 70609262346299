<div class="page-layout blank fullwidth p-12"  style="height: 1px; overflow-y: scroll;    touch-action: pan-y pan-x;   top: 1px;z-index: 99;">
  <div class="container">
    <h2>My Employees</h2>
  
    <!-- Search Bar Section -->
    <div class="SearchBarPlaceholder">
      <div class="search">
        <div class="input-container">
          <span *ngIf="searchText" class="close-button" (click)="clearSearch()">✖</span>
          <div class="scroll-text-container">
            <div class="SearchBarIcon">
              <i class="material-icons-search">search</i>
            </div>
            <div class="scroll-text"  *ngIf="!searchText">
                <div id="animationText-1" class="SearchBarAnimationText">Search By "Role"</div>
                <div id="animationText-2" class="SearchBarAnimationText">Search By "Name"</div>
                <div id="animationText-3" class="SearchBarAnimationText">Search By "Mobile Number"</div>
            </div>
          </div>
          <input
            type="text"
            class="search-input"
            [(ngModel)]="searchText"
            (keydown.enter)="filterRequests()"
            (input)="filterRequests()"
          />
          
        </div>
  
        <!-- Add New Employee Button -->
        <div class="add-employee" *ngIf="access=='autoverse_employee' || access=='owners'">
          <button class="add-btn" (click)="openSidebar()" >Add New Employee</button>
        </div>
      </div>
    </div>
  
    <!-- Owners Section -->
    <div class="section">
      <h3>Owner</h3>
      <div class="card-container" *ngFor="let owner of employees" >
        <div class="card" *ngIf="owner.roleType=='owners'">
          <img src="../../../../assets/images/backgrounds/emploee.jpg" alt="Owner Image" class="profile-image" />
          <div class="details">
            <span class="role">{{getRoleValue(owner.roleType)}}</span>
            <h4 class="name">{{ owner.name }}</h4>
            <p class="phone">{{ owner.phone }}</p>
          </div>
          <button mat-icon-button class="menu-btn" (click)="openSidebar(owner)" mat-raised-button matTooltip="Edit Employee" *ngIf="access=='autoverse_employee' || access=='owners'">
            <mat-icon>edit</mat-icon>
          </button>
          <button mat-icon-button class="menu-btn" (click)="deleteDetails(owner)" mat-raised-button matTooltip="delete Employee" *ngIf="access=='autoverse_employee' || access=='owners'">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </div>
    </div>
  
    <!-- Employees Section -->
    <div class="section">
      <h3 style="margin-top: -19px;">Employees</h3>
      <div class="card-container"  *ngFor="let owner of employees">
        <div class="card" *ngIf="owner.roleType!='owners'">
          <img src="../../../../assets/images/backgrounds/emploee.jpg" alt="Employee Image" class="profile-image" />
          <div class="details">
            <span class="role">{{getRoleValue(owner.roleType)}}</span>
            <h4 class="name">{{ owner.name }}</h4>
            <p class="phone">{{ owner.phone }}</p>
          </div>
          <!-- <button class="menu-btn">⋮</button> -->
          <button mat-icon-button class="menu-btn" (click)="openSidebar(owner)" mat-raised-button matTooltip="Edit Employee" *ngIf="access=='autoverse_employee' || access=='owners'">
            <mat-icon>edit</mat-icon>
          </button>
          <button mat-icon-button class="menu-btn" (click)="deleteDetails(owner)" mat-raised-button matTooltip="delete Employee" *ngIf="access=='autoverse_employee' || access=='owners'">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
  
  <!-- Sidebar Container -->
  <div class="overlay" [class.visible]="isSidebarOpen" (click)="closeSidebar()"></div>

  <div class="sidebar-container" *ngIf="isSidebarOpen">
    <div class="sidebar-header">
      <h2>{{ isEditMode ? 'Edit Employee' : 'Add New Employee' }}</h2>
      <button mat-icon-button aria-label="Close" (click)="closeSidebar()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <h4 class="select">Select Role</h4>
    <!-- Role Tabs -->
    <mat-tab-group [(selectedIndex)]="selectedRoleTab" class="custom-role-tabs" (selectedTabChange)="onRoleChange($event)">
      <div  >
        <mat-tab *ngFor="let role of roleType; let i = index"  [data-value]="role.roleType" label="{{role.roleValue}}"></mat-tab>
        
        
        <!-- <mat-tab label="Part Manager"></mat-tab>
        <mat-tab label="Service Advisor"></mat-tab> -->
        </div>
      </mat-tab-group>

    <!-- Form for Employee Details -->
     <h4 class="details">Details</h4>
    <form [formGroup]="employeeForm" class="employee-form">
  <!-- Name Field -->
  <mat-form-field appearance="outline" style="margin-bottom: -7px; margin-top: 14px;">
    <mat-label>Name</mat-label>
    <input pattern="[A-Za-z\s]+"  type="text"  matInput formControlName="name" required />
    <span class="maticon">
      <i class="material-icons">person</i>
    </span>
    <mat-error *ngIf="employeeForm.get('name')?.hasError('required') && employeeForm.get('name')?.touched" class="hasError">
      Enter Valid Name
    </mat-error>
  </mat-form-field>

  <!-- Mobile Number Field -->
  <mat-form-field appearance="outline" style="margin-bottom: -7px;">
    <mat-label>Mobile Number</mat-label>
    <input maxlength="10" matInput formControlName="phone" required />
    <span class="maticon">
      <i class="material-icons">phone</i>
    </span>
    <mat-error *ngIf="employeeForm.get('phone')?.hasError('required') && employeeForm.get('phone')?.touched" class="hasError">
      Enter Valid Mobile Number
    </mat-error>
    <mat-error
    *ngIf="employeeForm.get('phone')?.hasError('pattern') && employeeForm.get('phone')?.touched"
    class="hasError"
  >
    Enter a valid 10-digit mobile number
  </mat-error>
  </mat-form-field>

  <!-- Email ID Field -->
  <mat-form-field appearance="outline" style="margin-bottom: -7px;" *ngIf="isOwnerRole">
    <mat-label>Email ID</mat-label>
    <input matInput formControlName="email" required />
    <span class="maticon">
      <i class="material-icons">email</i>
    </span>
    <mat-error *ngIf=" employeeForm.get('email')?.hasError('required') && employeeForm.get('email')?.touched" class="hasError">
      Enter Valid Email ID
    </mat-error>
  </mat-form-field>

  <mat-form-field appearance="outline" style="margin-bottom: -7px;" *ngIf="!isOwnerRole">
    <mat-label>Email ID</mat-label>
    <input matInput formControlName="email"  />
    <span class="maticon">
      <i class="material-icons">email</i>
    </span>
    
  </mat-form-field>

      
  
      <!-- CTA Button -->
      <button
        mat-raised-button
        color="primary"
        class="cta-button"
        (click)="onSubmit()"
      >
        {{ isEditMode ? 'Edit Employee' : 'Add Employee' }}
      </button>
    </form>
  </div>

  <div class="overlay1" [class.visible1]="PopupOpenEmployee" (click)="closePopup()"></div>
  <div class="popup" *ngIf="PopupOpenEmployee">
    <div class="popup-content order-success-sec">
        <mat-icon class="close-btn" (click)="closePopup()">close</mat-icon>
              <h3>Are you sure you want to delete this employee?</h3>
              <div class="popup-buttons">
                <button class="no-btn" (click)="closePopup()">No</button>
                <button class="yes-btn" (click)="deleteEmployee()">Yes</button>
              </div>
    </div>
  </div>
  

  <!-- Pop-up content -->
  <div class="popup1" *ngIf="isPopupOpen">
    <div class="popup-content1 order-success-sec">
      <!-- <button mat-button (click)="closePopup()">Close</button> -->
      <img src="{{ successIcon }}" alt="Success Icon">
      <button class="close-popup" (click)="closePopupSucess()">×</button>
      <!-- <h3>Booking Created!</h3> -->
      
      <h3 *ngIf="!isEditMode && !successDeleted">Employee Added </h3>
      <h3  *ngIf="isEditMode  && !successDeleted">Employee Updated</h3>
      <h3 *ngIf="successDeleted">Employee Deleted</h3>
      <p class="order-label" *ngIf="!isEditMode && !successDeleted">Employee Name:<span class="order-id"> {{resDataCreate.name}} </span></p>
      <p class="order-label"  *ngIf="isEditMode  && !successDeleted">Employee Name:<span class="order-id">{{resData.name}}</span></p>
      <p class="order-label" *ngIf="successDeleted">Employee Name:<span class="order-id">{{employeeToDelete.name}}</span></p>
      <p class="date"><span>{{ formattedTime }}</span></p>
      <p class="thank-you">let us deliver an awesome experience!</p>
      <button class="goto-order" (click)="closePopupSucess()">OK, GOT IT </button>
    </div>
  </div>