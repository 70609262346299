<!-- <div class="main-container" > -->
    <div class="page-layout blank fullwidth p-12" (scroll)="onScroll()" fusePerfectScrollbar style="height: 1px; overflow-y: scroll; margin-bottom: 5em;    top: 1px;">
        <div class="first-div">
            <!-- <div class="container" (window:scroll)="onScroll()"> -->
            <div class="container">
                <div class="left">
                    <div class="offer" style="text-align: left;" *ngIf="data.discount>0">{{discountType}} off</div>
                    <img [src]="data.imageUrl" alt="Product Image" [ngClass]="{'imgMobile': isMobile}" onerror="this.src='../../../../assets/images/logos/logo.png'; this.classList.add('fallback-logo')" 
                    >
                    <div class="sku-tags">
                        
                        <span *ngIf="previouslyBought" class="sku-tag-top-right previously-bought tag" [class.animate]="showAnimation">
                            Previously Bought
                        </span>
                    
                    
                        <span *ngIf="!previouslyBought && orderedCount > 0" class="sku-tag-top-right ordered-count tag " [class.animate]="showAnimation">
                            {{ orderedCount }} {{ orderedCount === 1 ? 'Unit' : 'Units' }} Sold
                        </span>
                    
                    
                        <span *ngIf="!previouslyBought && orderedCount === 0 && garageCount > 0" class="sku-tag-top-right garage-count tag ordered-count" [class.animate]="showAnimation">
                            Bought by {{ garageCount }} Garages
                        </span>
                    </div>
                </div>
                <div [ngClass]="isMobile ? 'rightMobile' : 'right'">
                    <div class="top-right">                    
                        <div class="partname">
                            <div class="partname-text"><span class="partname-text">{{ data.name }}</span></div>
                            <span *ngIf="dataplannedSku===1">
                                    <img src="../../../../assets/images/assured/assured.png" alt="assured" style="max-width: 15%;display: flex;margin-left: 515px;margin-top: -28px;margin-bottom: 10px;">
                                
                            </span>                           
                        </div>
                        <div class="info1">
                            <div class="price" *ngIf="data.sellingPrice > 0">
                                <span class="mrp" style="font-size: medium; font-weight: 700;"> ₹{{ data.sellingPrice | number }}</span>
                                <span class="line-through" style="margin-left: 10px;"> ₹{{ data.mrp | number }}</span>
                            </div>
                            <div class="price" *ngIf="data.sellingPrice <= 0">
                                MRP:<span class="bold"> ₹{{ data.mrp | number }}</span>
                            </div>
                            <div class="ratings">
                                <i class="material-icons star-icon">star</i>5 Ratings
                            </div>
                        </div>
                        <div class="info2">
                            <div class="inclusive" style="color: darkgray;">(Inclusive of all taxes)</div>
                            <div class="delivery-time">
                                <i class="material-icons">schedule</i>{{data.deliveryTime}}
                            </div>
                        </div>
                        <div class="info3">
                            <div class="save" style="color: green">You save ₹<span class="bold">{{data.discount}}</span></div>
                            <!-- <div class="free-delivery">
                                <i class="material-icons">local_shipping</i>{{ data.deliveryStatus }}
                            </div> -->
                        </div>
                    </div>
                    
                    <div class="bottom-right">
                        <div class="saveMore" (click)="saveMore()" *ngIf="data?.alternate?.length !==0 && data?.alternate !==null ">
                            
                            <img src="../../../../assets/images/save-OES/Group 5830.png" class="Group"  alt="Group">
                            <img src="../../../../assets/images/save-OES/image 1682.png" class="image"  alt="image">
                        <img src="../../../../assets/images/save-OES/flywheel (1) 2.png" class="flywheel"  alt="flywheel">
                        
                        <p class="textOES">Choose OES Save More!</p>
                        <div class="highlight"></div>
                        <div class="subheadings-container">
                            <!-- (x= OEM Selling Price - Lowest OES Selling Price) -->
                            
                            <div class="subheading">Save up to ₹{{ data.sellingPrice-minMrp |number}} more</div> 
                            <div class="subheading">Quality you can trust</div>
                            <div class="subheading">Prices you'll love</div>
                        </div>
                        </div>
                        <div class="info" style="margin-bottom: -20px; margin-top: 15px;"><i class="material-icons">checkcircle</i>Brand: <span class="bold"> {{ data?.brand?.name }}</span></div>
                        <div class="info" style="margin-bottom: -20px;"><i class="material-icons">checkcircle</i>Part Type: <span class="bold"> {{ data.partType }}</span></div>
                        <div class="info" style="margin-bottom: -20px;"><i class="material-icons">checkcircle</i>Part Number: <span class="bold"> {{ data.partNumber }}</span></div>
                        <div class="info"style="margin-bottom: -20px;"><i class="material-icons">checkcircle</i>Category: <span class="bold"> {{ data.category }}</span></div>
                        <div class="info"><i class="material-icons">checkcircle</i>SubCategory: <span class="bold"> {{ data.subCategory }}</span></div>

                    </div>
                    <div  [ngClass]="isMobile ? 'button-containerMobile' : 'button-container'">
                        <div [ngClass]="isMobile ? 'button-wrapperMobile' : 'button-wrapper'">
                            <!-- <button *ngIf="cartList.length<=0" class="add-to-cart" (click)="addCart(data, 1)" >ADD TO CART</button>
                            <button *ngIf="cartList.length>0 && !cartList[0][data.key]" class="add-to-cart" (click)="addCart(data, 1)">Add to Cart</button> -->
                            <!-- <button *ngIf="cartList.length<=0" class="add-to-cart" (click)="addCart(data, 1)" >ADD TO CART</button>
                            <button *ngIf="cartList.length>0 && !cartList[0][data.key]" class="add-to-cart" (click)="addCart(data, 1)">Add to Cart</button> -->
                            <ng-container *ngIf="!isMobile">
                                <button *ngIf="cartList.length<=0"  [ngClass]="isMobile ? 'add-to-cartMobile' : 'add-to-cart'" (click)="addCart(1)" >ADD TO CART</button>
                                <button #addButton *ngIf="cartList.length>0 && !cartList[0][data.key] && !isMobile" [ngClass]="isMobile ? 'add-to-cartMobile' : 'add-to-cart'"(click)="addCart(1)">Add to Cart</button>
                            </ng-container>
                            <div class="addtocartcompatiblecar"*ngIf="cartList[0] && cartList[0][data.key] && !isMobile">
                                <div class="spare-add-cart" style="width: 100%;">
                                    <div class="btn-group" *ngIf="data.key==cartList[0][data.key].key && cartList[0][data.key].cart_qty>0">
                                        <button mat-raised-button (click)="removeItem(cartList[0][data.key].cart_qty, data.key)">
                                            <mat-icon class="sidebuttons">remove</mat-icon>
                                        </button>
                                        <input type="text" [(ngModel)]="cartList[0][data.key].cart_qty" readonly >
                                        <button mat-raised-button (click)="addItem(cartList[0][data.key].cart_qty, data.key)">
                                            <mat-icon class="sidebutton">add</mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <button  (click)="scrollToCompatibility()" [ngClass]="isMobile ? 'compatible-carsMobile' : 'compatible-cars'">
                                <div class="icon"></div>
                                <div class="data.description" style="width: 100%;">
                                    <div style="color: #144579; font-weight: bold; background-color: d7f5fc;">Compatible Cars</div>
                                    <div style="color: black; font-weight: bold; font-size: 11px;">See all cars this part is compatible with</div>
                                </div>
                                <div class="arrow" style="color: #144579;">➔</div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="third-div" *ngIf="data.description || (whenIsItNeeded && whenIsItNeeded.length > 0)">
            <h3 style="font-weight: bold;"> Product Details</h3>
            <div class="product-details-tabs">
                <div class="tab"(click)="selectTab('data.description')" [ngClass]="{'active': activeTab === 'data.description'}">Description</div>
                <div class="tab" *ngIf="whenIsItNeeded && whenIsItNeeded.length > 0" (click)="selectTab('needed')" [ngClass]="{'active': activeTab === 'needed'}">When is it needed</div>
            </div>
            <div class="tab-content" *ngIf="activeTab === 'data.description'">
                <div [innerHtml]="data.description"></div>
            </div>
            <div class="tab-content" *ngIf="activeTab === 'needed' && whenIsItNeeded.length > 0">
                <ul>
                    <li *ngFor="let item of whenIsItNeeded" style="list-style: disc;">{{item}}</li>
                </ul>
            </div>
        </div>

        <div class="second-div" #secondDiv>
            <h3>Why Should You Order From Autoverse?</h3>
            <div class="features-wrapper">
                <div class="features">
                    <div class="feature-item">
                        <i class="material-icons">checkcircle</i>
                        <div>100% Genuine Parts</div>
                    </div>
                    <div class="feature-item">
                        <i class="material-icons">checkcircle</i>
                        <div>Easy Returns & Refunds</div>
                    </div>
                    <div class="feature-item">
                        <i class="material-icons">checkcircle</i>
                        <div>Free Shipment on App Orders</div>
                    </div>
                </div>
            </div>
            <div class="info-features">
                <div>
                    <img src="../../../../assets/images/productdetailsimage/ic_geniun_spare_parts.svg" alt="Genuine"/>
                    <span class="bold">Genuine Parts</span>
                </div>
                <div>
                    <img src="../../../../assets/images/productdetailsimage/ic_delivery.svg" alt="Delivery"/>
                    <span class="bold">Timely Delivery</span>
                </div>
                <div>
                    <img src="../../../../assets/images/productdetailsimage/ic_best_marging.svg" alt="Margins">
                    <span class="bold">Best Margins</span>
                </div>
                <div>
                    <img src="../../../../assets/images/productdetailsimage/ic_unmatched_price.svg" alt="Price">
                    <span class="bold">Unmatched Price</span>
                </div>
            </div>
            <!-- <div class="cart" style="background-color: black; color: #FFFFFF; padding: 20px; border-radius: 10px; float: left; width: 100%;"> -->
                <!-- <div class="price" style="width: 50%; text-align: left; float: left;">
                    ₹{{cartSubTotal}}
                </div>
                <div class="add-button-container">
                    <button class="add-button" style="width: 20%; text-align: center; float: right;">
                        <span *ngIf="this.cartCount==0">ADD</span>
                        <span *ngIf="this.cartCount>0">ADDED</span>
                    </button>
                </div> -->
                <!-- <div class="grand-total">&#8377;{{cartGrandTotal}}</div> -->
            
        </div>


        <div class="fourth-div faq-container" *ngIf="data?.faqs?.length > 0">
            <h2 style="font-weight: bold;">Frequently Asked Questions</h2>
            <div class="faq" *ngFor="let faq of data.faqs; let i = index">
                <div class="faq-item">
                    <input type="checkbox" id="faq{{i}}" class="faq-toggle">
                    <label for="faq{{i}}" class="question">
                        {{faq.question}}<span class="arrow">▼</span>
                    </label>
                    <div class="answer">
                        {{faq.answer}}
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="cart" >
            <div class="cart-content">
                <div class="price-details">
                    <div class="partname"><span>{{ partName }}</span></div>
                    <div class="price" *ngIf="sellingPrice > 0">
                        <div style="display: flex;">
                            <span class="mrp"> selling price ₹{{ sellingPrice | number }}</span>
                            <div class="discount">{{discountType}} off</div>
                            <button *ngIf="cartList.length<=0" class="add-to-cart-last" (click)="addCart(data, 1)" >ADD TO CART</button>
                            <button *ngIf="cartList.length>0 && !cartList[0][data.key]" class="add-to-cart-last" (click)="addCart(data, 1)">Add to Cart</button>
                            <div *ngIf="cartList[0] && cartList[0][data.key]" style="background: #144579; padding: 7px;">
                                <div class="spare-add-cart" style="width: 100%;">
                                    <div class="btn-group" *ngIf="data.key==cartList[0][data.key].key && cartList[0][data.key].cart_qty>0">
                                        <button mat-raised-button (click)="removeItem(cartList[0][data.key].cart_qty, data.key)" style="background-color: #144579;"><mat-icon>remove</mat-icon></button>
                                        <input style="text-align: center;width: 100px;padding: 10px 1px 1px 1px;font-size: 15px;" type=text class="part-count" [(ngModel)]="cartList[0][data.key].cart_qty" readonly>
                                        <button mat-raised-button (click)="addItem(cartList[0][data.key].cart_qty, data.key)"style="background-color: #144579;"><mat-icon>add</mat-icon></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        </div> -->

        <div #compatabilityDiv >
            <div class="comapatable">
                <h3 class="compatability">View Compatibility</h3>
                <p style="margin-top: 47px;">See all cars this part is compatible with</p>
                <!-- {{compatability}} -->
                <mat-tab-group>
                <div *ngFor="let vehicle of compatability">
                    <mat-tab [label]="vehicle[0]">
                    <table  [ngClass]="isMobile ? 'toggle-comp-btnMobile' : 'toggle-comp-btn'">
                        <tr>
                        <th>Model</th>
                        <th>Fuel</th>
                        <th>Varient</th>
                        <th>Year</th>
                        </tr>
            
                        <tr *ngFor="let model of getVisibleModels(vehicle[1], vehicle[0]); let i = index">
                        <td>{{ model.model }}</td>
                        <td>{{ model.fuelType }}</td>
                        <td>{{ model.description }}</td>
                        <td>{{ model.yearFrom }}-{{ model.yearTo }}</td>
                        </tr>
                    </table>
            
                    <button (click)="toggleShowMore(vehicle[0])" [ngClass]="isMobile ? 'toggle-show-btnMobile' : 'toggle-show-btn'">
                        
                        {{ showAllMap[vehicle[0]] ? 'Show Less' : 'Show More' }}
                        <i class="material-icons" style="color: #144579;">
                            {{ showAllMap[vehicle[0]] ? 'expand_less' : 'expand_more' }}
                        </i>
                    </button>
                    </mat-tab>
                </div>
                
                </mat-tab-group>
            </div>
        </div>

        <div class="bottom-fixed" *ngIf="!isMobile && showBottomDiv" >
            <div class="bottom-fixed-content">
                <!-- Image Section -->
                <img
                class="bottom-fixed-image"
                [src]="data.imageUrl || 'assets/images/default-image.jpg'"
                alt="Product Image"
                onerror="this.src='../../../../assets/images/logos/logo.png'; this.classList.add('fallback-logo')"
                />
            
                <!-- Left Section: Product Details -->
                <div class="bottom-fixed-details">
                    <div class="bottominfo"><span class="bold">{{ data?.brand?.name }}</span></div>
                    <div class="partname">{{ data.name }}</div>
                </div>
            
                <!-- Right Section: Price Details -->
                <div class="price-details">
                <div class="pricedetails-mrp">
                    <span class="linethroughmrp" >MRP</span>
                    <span class="line-through" *ngIf="data.mrp > data.sellingPrice"  > ₹{{ data.mrp | number }}</span>
                </div>
                <div class="linethrough-mrp">
                    <span class="mrp" > ₹{{ data.sellingPrice | number }}</span>
                    <span class="discount" *ngIf="data.discount > 0">( You Save ₹{{ data.discount }} )</span>
                    <span class="offer"
                        *ngIf="data.discount > 0"
                    >
                        {{ discountType }} off
                </span>
                    </div>
                </div>
            </div>
            
            <!-- Add to Cart and Quantity Controls -->
            <div class="bottom-fixed-actions">
                <div *ngIf="cartList[0] && cartList[0][data.key]">
                    <div class="spare-add-cart" style="width: 100%;">
                        <div  class="btn-group"*ngIf="data.key == cartList[0][data.key].key && cartList[0][data.key].cart_qty > 0">
                            <button class="quantity-btn" (click)="removeItem(cartList[0][data.key].cart_qty, data.key)">
                                <mat-icon class="sidebuttons">remove</mat-icon>
                            </button>
                            <input type="text" [(ngModel)]="cartList[0][data.key].cart_qty" readonly />
                            <button class="quantity-btn" (click)="addItem(cartList[0][data.key].cart_qty, data.key)">
                                <mat-icon class="sidebutton">add</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
                <button
                *ngIf="cartList.length <= 0"
                class="add-to-cart-btn"
                (click)="addCart(1)"
                >
                Add to Cart
                </button>
                <button
                *ngIf="cartList.length > 0 && !cartList[0][data.key]"
                class="add-to-cart-btn"
                (click)="addCart(1)"
                >
                Add to Cart
                </button>
            </div>
        </div>


        <div class="mobile-bottom-fixed" *ngIf="isMobile">
            <!-- Add to Cart and Quantity Controls -->
            <div class="bottom-fixed-actions">
                <!-- If product is in the cart -->
                <div *ngIf="cartList[0] && cartList[0][data.key]">
                <div class="btncartdiv">
                    <div class="btn-group">
                    <button
                        class="quantity-btn"
                        (click)="removeItem(cartList[0][data.key].cart_qty, data.key)">
                        <mat-icon class="sidebuttons">remove</mat-icon>
                    </button>
                    <input
                        type="text"
                        [(ngModel)]="cartList[0][data.key].cart_qty"
                        readonly
                    />
                    <button
                        class="quantity-btn"
                        (click)="addItem(cartList[0][data.key].cart_qty, data.key)" >
                        <mat-icon class="sidebutton">add</mat-icon>
                    </button>
                    </div>
                </div>


                <!-- ________________________________________________________ -->
                <!-- <div class="viewcartbtn">
                    <div class="viewcartbtnttl">
                        <div class="viewcartbtnitems"> -->
                            <!-- <div class="noofitems"> {{ cartList[0][data.key].cart_qty }} Items </div> -->
                            <!-- <div class="noofitems"> {{ cartList[0][data.key].cart_qty }}  {{ cartList[0][data.key].cart_qty > 1 ? 'Items' : 'Item' }}</div>      -->
                            <!-- <div class="price" *ngIf="data.sellingPrice > 0">
                                <div class="grand-total">&#8377;{{cartGrandTotal | number:'1.2-2'}}</div>
                            </div> -->
                            <!-- <span class="price" > ₹{{ data.sellingPrice | number }}</span>
                        </div> 
                        <button class="view-cart-btn" (click)="goToCart()">View Cart</button>   
                    </div>
                </div> -->
                <div class="viewcartbtn">
                    <div class="viewcartbtnttl">
                        <div class="viewcartbtnitems">
                            <div class="noofitems">{{ cartList[0][data.key].cart_qty }} {{ cartList[0][data.key].cart_qty > 1 ? 'Items' : 'Item' }}</div>
                            <span class="price">₹{{ data.sellingPrice | number }}</span>
                        </div>
                    </div>
                    <button class="view-cart-btn" (click)="goToCart()">View Cart</button>
                </div>
                
                <!-- ________________________________________________________________ -->
                </div>
                <!-- If product is NOT in the cart -->
                <button
                *ngIf="!cartList[0] || !cartList[0][data.key]"
                class="add-to-cart-btn"
                (click)="addCart(1)"
                >
                Add to Cart
                </button>
            </div>
        </div>

        <!-- <div class="mobile-top-fixed"*ngIf="isMobile">
            <div class="arrowbackandpartname">
                <i class="material-icons-arrowback-new" (click)="goBack()">arrow_back</i>
                <div class="partname">{{ data.name }}</div>
            </div>
            <span class="mrp" > ₹{{ data.sellingPrice | number }}</span>
            <span class="offer"*ngIf="data.discount > 0"> {{ discountType }} off</span>
        </div> -->
    </div>
<!-- </div> -->
