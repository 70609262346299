import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalVariable } from 'app/main/globals';

@Injectable({
  providedIn: 'root'
})
export class AspCheckinReportsService {

  constructor(private http: HttpClient) { }

    checkInDetails(garageKey,userKey,bookingId,jobCard){
      let param={
        garageKey:garageKey,
        userKey:userKey,
        bookingId: bookingId,
        jobCardId:jobCard
       
      }
      return this.http.post(`${GlobalVariable.BASE_API_URL_SPARES}/booking/v1/checkInReport`,param)
    }

    // getAccessoriesList(){
  
    //   // console.log(this.paramData);
    //   console.log('-----Registration Decode-----------');    
    //   return this.http.get(`${GlobalVariable.BASE_API_URL_SPARES}` + '/booking/v1/getServiceList');
    // }
}
