import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GlobalVariable } from 'app/main/globals';


@Injectable({
  providedIn: 'root'
})
export class AspMyDocumentsService {

  constructor(private http: HttpClient) { }  // Inject HttpClient here

  saveDocumentMetadata(mobile: string, documentType: string, link: string): Observable<any> {
    console.log('3', documentType);
    console.log('3', link);
    console.log('---------------');
    const documentData = {
      mobile: mobile
    };

    if (documentType == 'gstCertificate') {
      documentData['gstCertificate'] = link;
    } else if (documentType == 'aadharCard') {
      documentData['aadharCard'] = link;
    } else if (documentType == 'panCard') {
      documentData['panCard'] = link;
    } else if (documentType == 'establishmentCertificate') {
      documentData['establishmentCertificate'] = link;
    } else if (documentType == 'coi') {
      documentData['coi'] = link;
    } else if (documentType == 'msmeCertificate') {
      documentData['msmeCertificate'] = link;
    }
  
    return this.http.post(`${GlobalVariable.BASE_API_URL_SPARES}` + '/user/userDocuments', documentData);
    
  }
  viewUserDocument(mobile: string, view: string): Observable<any> {
    const requestData = {
      mobile: mobile,
      view: view
    };
    return this.http.post(`${GlobalVariable.BASE_API_URL_SPARES}` + '/user/view/userDocument', requestData,);
  }
  
getUploadedDocuments(spareUserKey): Observable<any> {

  // const requestData = {mobile};
  return this.http.get(`${GlobalVariable.BASE_API_URL_SPARES}`+'/user/listAllDocuments/'+spareUserKey);
}

}
