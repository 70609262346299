<div class="document-card">
  <h2 _ngcontent-omc-c351="" class="title ng-tns-c351-3">My Documents</h2>
  <div class="document-details">
    <div class="document-id">
      <div class="order-icon" style="width: 4%;">
        <i class="top-material-icons">check_box</i>
      </div>
      <h4 class="document-title">Copy of GST Certificate</h4>
    </div>
    <div class="document-footer">
      <div class="file-preview-container">
        <!-- Show image preview if selectedFilePreview contains image data -->
        <div *ngIf="certificate?.gstCertificate && isImage(certificate.gstCertificate)" class="file-preview-wrapper">
          <img [src]=certificate.gstCertificate alt="Image Preview" class="file-preview" />
          <mat-icon class="delete-btn-icon-new" (click)="deleteFile('gstCertificate', certificate.gstCertificate)">&#10005;</mat-icon>
        </div>
      
        <!-- Show the PDF icon and name for PDF files -->
        <div *ngIf="certificate?.gstCertificate && isPDF(certificate.gstCertificate)" class="file-preview-wrapper">
          <img src= "../../../../assets/images/pdfimageicon.png" alt="PDF Icon" class="file-preview" />
          <!-- <span>{{ selectedFileName }}</span> -->
          <mat-icon class="delete-btn-icon-new" (click)="deleteFile('gstCertificate', certificate.gstCertificate)">&#10005;</mat-icon>
        </div>
      
        <!-- Show the file name for non-image, non-PDF files -->
        <div *ngIf="!selectedFilePreview && selectedFileName">
          <span>{{ selectedFileName }}</span>
          <mat-icon class="delete-btn-icon-new" (click)="deleteFile('gstCertificate', certificate.gstCertificate)">&#10005;</mat-icon>
        </div>
      
        <!-- File input for selecting files -->
        <input type="file" id="fileInput_gstCertificate" (change)="onFileSelected($event, 'gstCertificate')" style="display: none;" />
      </div>
      
      <button mat-raised-button color="primary" class="upload-button" (click)="onUploadClick('gstCertificate')">
        
        <!-- Only show the 'View' option if the file is uploaded -->
        <div class="uploadbtnclass" *ngIf="certificate?.gstCertificate">
        </div>
        <div class="viewbtnclass" *ngIf="certificate?.gstCertificate" (click)="viewFile('gstCertificate', certificate.gstCertificate)">
          <mat-icon>visibility</mat-icon>
          <span>View</span>
        </div>
        
        <!-- Show 'Upload' if the file is not uploaded yet -->
         <div>
          <div class="newarwbtn" *ngIf="!certificate?.gstCertificate">
            <div>
              <mat-icon>arrow_upward</mat-icon>
            </div>
            <div>
              <span>Upload</span>
            </div>
          </div>
        </div>
      </button>
    </div>
  </div>

  
  <div class="document-details">
    <div class="document-id">
      <div class="order-icon" style="width: 4%;">
        <i class="top-material-icons">check_box</i>
      </div>
      <h4 class="document-title">Copy of PAN Card</h4>
    </div>
    <div class="document-footer">
      <div class="file-preview-container">
        <!-- Show image preview if selectedFilePreview contains image data -->
        <div *ngIf="certificate?.panCard && isImage(certificate.panCard)" class="file-preview-wrapper">
          <img [src]=certificate.panCard alt="Image Preview" class="file-preview" />
          <mat-icon class="delete-btn-icon-new" (click)="deleteFile('panCard', certificate.panCard)">&#10005;</mat-icon>
        </div>

        <!-- Show the PDF icon and name for PDF files -->
        <div *ngIf="certificate?.panCard && isPDF(certificate.panCard)" class="file-preview-wrapper">
          <img src= "../../../../assets/images/pdfimageicon.png" alt="PDF Icon" class="file-preview" />
          <!-- <span>{{ selectedFileName }}</span> -->
          <mat-icon class="delete-btn-icon-new" (click)="deleteFile('panCard', certificate.panCard)">&#10005;</mat-icon>
        </div>
  
        <!-- Show the file name for non-image, non-PDF files -->
        <div *ngIf="!selectedFilePreview && selectedFileName">
          <span>{{ selectedFileName }}</span>
          <mat-icon class="delete-btn-icon-new" (click)="deleteFile('panCard', certificate.panCard)">&#10005;</mat-icon>
        </div>
  
        <!-- File input for selecting files -->
        <input #fileInput id="fileInput_panCard" type="file" (change)="onFileSelected($event, 'panCard')" accept="image/*, .pdf" style="display: none;" />
      </div>
      <button mat-raised-button color="primary" class="upload-button" (click)="onUploadClick('panCard')">
        
        <!-- Only show the 'View' option if the file is uploaded -->
        <div class="viewbtnclass" *ngIf="certificate?.panCard" (click)="viewFile('panCard', certificate.panCard)">
          <mat-icon>visibility</mat-icon>
          <span>View</span>
        </div>
        <!-- Show 'Upload' if the file is not uploaded yet -->
        <div class="newarwbtn" *ngIf="!certificate?.panCard">
          <div>
            <mat-icon>arrow_upward</mat-icon>
          </div>
          <div>
            <span>Upload</span>
          </div>
        </div>
      </button>
      <input #fileInput id="fileInput_panCard" type="file" (change)="onFileSelected($event, 'panCard')" accept="image/*, .pdf" style="display: none;"/>
    </div>
  </div>

  <div class="document-details">
    <div class="document-id">
      <div class="order-icon" style="width: 4%;">
        <i class="top-material-icons">check_box</i>
      </div>
      <h4 class="document-title">Copy of Adhar Card</h4>
    </div>
    <div class="document-footer">
      <div class="file-preview-container">
        <!-- Show image preview if selectedFilePreview contains image data -->
        <div *ngIf="certificate?.aadharCard && isImage(certificate.aadharCard)" class="file-preview-wrapper">
          <img [src]=certificate.aadharCard alt="Image Preview" class="file-preview" />
          <mat-icon class="delete-btn-icon-new" (click)="deleteFile('aadharCard', certificate.aadharCard)">&#10005;</mat-icon>
        </div>
  
        <!-- Show the PDF icon and name for PDF files -->
        <div *ngIf="certificate?.aadharCard && isPDF(certificate.aadharCard)" class="file-preview-wrapper">
          <img src="../../../../assets/images/pdfimageicon.png" alt="PDF Icon" class="file-preview" />
          <!-- <span>{{ selectedFileName }}</span> -->
          <mat-icon class="delete-btn-icon-new" (click)="deleteFile('aadharCard', certificate.aadharCard)">&#10005;</mat-icon>
        </div>
  
        <!-- Show the file name for non-image, non-PDF files -->
        <div *ngIf="!selectedFilePreview && selectedFileName">
          <span>{{ selectedFileName }}</span>
          <mat-icon class="delete-btn-icon-new" (click)="deleteFile('aadharCard', certificate.aadharCard)">&#10005;</mat-icon>
        </div>
  
        <!-- File input for selecting files -->
        <input #fileInput id="fileInput_aadharCard" type="file" (change)="onFileSelected($event, 'aadharCard')" accept="image/*, .pdf" style="display: none;" />
      </div>
      <button mat-raised-button color="primary" class="upload-button" (click)="onUploadClick('aadharCard')">
        
        <!-- Only show the 'View' option if the file is uploaded -->
        <div class="viewbtnclass" *ngIf="certificate?.aadharCard" (click)="viewFile('aadharCard', certificate.aadharCard)">
          <mat-icon>visibility</mat-icon>
          <span>View</span>
        </div>
        <!-- Show 'Upload' if the file is not uploaded yet -->
        <div class="newarwbtn" *ngIf="!certificate?.aadharCard">
          <div>
            <mat-icon>arrow_upward</mat-icon>
          </div>
          <div>
            <span>Upload</span>
          </div>
        </div>
      </button>
      <input #fileInput id="fileInput_aadharCard" type="file" (change)="onFileSelected($event, 'aadharCard')" accept="image/*, .pdf" style="display: none;"/>
    </div>
  </div>
  <div class="document-details">
    <div class="document-id">
      <div class="order-icon" style="width: 4%;">
        <i class="top-material-icons">check_box</i>
      </div>
      <h4 class="document-title">Copy of Shop Establishment Certificate</h4>
    </div>
    <div class="document-footer">
      <div class="file-preview-container">
        <!-- Show image preview if selectedFilePreview contains image data -->
        <div *ngIf="certificate?.establishmentCertificate && isImage(certificate.establishmentCertificate)" class="file-preview-wrapper">
          <img [src]="certificate.establishmentCertificate" alt="Image Preview" class="file-preview" />
          <mat-icon class="delete-btn-icon-new" (click)="deleteFile('establishmentCertificate', certificate.establishmentCertificate)">&#10005;</mat-icon>
        </div>
  
        <!-- Show the PDF icon and name for PDF files -->
        <div *ngIf="certificate?.establishmentCertificate && isPDF(certificate.establishmentCertificate)"class="file-preview-wrapper">
          <img src="../../../../assets/images/pdfimageicon.png" alt="PDF Icon" class="file-preview" />
          <!-- <span>{{ selectedFileName }}</span> -->
          <mat-icon class="delete-btn-icon-new" (click)="deleteFile('establishmentCertificate', certificate.establishmentCertificate)">&#10005;</mat-icon>
        </div>
        <!-- Show the file name for non-image, non-PDF files -->
        <div *ngIf="!selectedFilePreview && selectedFileName">
          <span>{{ selectedFileName }}</span>
          <mat-icon class="delete-btn-icon-new" (click)="deleteFile('establishmentCertificate', certificate.establishmentCertificate)">&#10005;</mat-icon>
        </div>
  
        <!-- File input for selecting files -->
        <input #fileInput id="fileInput_establishmentCertificate" type="file" (change)="onFileSelected($event, 'establishmentCertificate')" accept="image/*, .pdf" style="display: none;" />
      </div>
      <button mat-raised-button color="primary" class="upload-button" (click)="onUploadClick('establishmentCertificate')">
        
        <!-- Only show the 'View' option if the file is uploaded -->
        <div class="viewbtnclass"  *ngIf="certificate?.establishmentCertificate" (click)="viewFile('establishmentCertificate', certificate?.establishmentCertificate)">
          <mat-icon>visibility</mat-icon>
          <span>View</span>
        </div>
        <!-- Show 'Upload' if the file is not uploaded yet -->
        <div class="newarwbtn" *ngIf="!certificate?.establishmentCertificate">
          <div>
            <mat-icon>arrow_upward</mat-icon>
          </div>
          <div>
            <span>Upload</span>
          </div>
        </div>
      </button>
      <input #fileInput id="fileInput_establishmentCertificate" type="file" (change)="onFileSelected($event, 'establishmentCertificate')" accept="image/*, .pdf" style="display: none;"/>
    </div>
  </div>

  <div class="document-details">
    <div class="document-id">
      <div class="order-icon" style="width: 4%;">
        <i class="top-material-icons">check_box</i>
      </div>
      <h4 class="document-title">Copy of COI</h4>
    </div>
    <div class="document-footer">
      <div class="file-preview-container">
        <!-- Show image preview if selectedFilePreview contains image data -->
        <div *ngIf="certificate?.coi && isImage(certificate.coi)" class="file-preview-wrapper">
          <img [src]="certificate.coi" alt="Image Preview" class="file-preview" />
          <mat-icon class="delete-btn-icon-new" (click)="deleteFile('coi', certificate.coi)">&#10005;</mat-icon>
        </div>
  
        <!-- Show the PDF icon and name for PDF files -->
        <div *ngIf="certificate?.coi && isPDF(certificate.coi)" class="file-preview-wrapper">
          <img src="../../../../assets/images/pdfimageicon.png" alt="PDF Icon" class="file-preview" />
          <!-- <span>{{ selectedFileName }}</span> -->
          <mat-icon class="delete-btn-icon-new" (click)="deleteFile('coi', certificate.coi)">&#10005;</mat-icon>
        </div>
  
        <!-- Show the file name for non-image, non-PDF files -->
        <div *ngIf="!selectedFilePreview && selectedFileName">
          <span>{{ selectedFileName }}</span>
          <mat-icon class="delete-btn-icon-new" (click)="deleteFile('coi', certificate.coi)">&#10005;</mat-icon>
        </div>
  
        <!-- File input for selecting files -->
        <input #fileInput id="fileInput_coi" type="file" (change)="onFileSelected($event, 'coi')" accept="image/*, .pdf" style="display: none;" />
      </div>
      <button mat-raised-button color="primary" class="upload-button" (click)="onUploadClick('coi')">
        <!-- Only show the 'View' option if the file is uploaded -->
        <div class="viewbtnclass" *ngIf="certificate?.coi" (click)="viewFile('coi',certificate?.coi)">
          <mat-icon>visibility</mat-icon>
          <span>View</span>
        </div>
        
        <!-- Show 'Upload' if the file is not uploaded yet -->
        <div class="newarwbtn" *ngIf="!certificate?.coi">
          <div>
            <mat-icon>arrow_upward</mat-icon>
          </div>
          <div>
            <span>Upload</span>
          </div>
        </div>
      </button>
      <input #fileInput id="fileInput_coi" type="file" (change)="onFileSelected($event, 'coi')" accept="image/*, .pdf" style="display: none;"/>
    </div>
  </div>

  <div class="document-details">
    <div class="document-id">
      <div class="order-icon" style="width: 4%;">
        <i class="top-material-icons">check_box</i>
      </div>
      <h4 class="document-title">Copy of MSME Certificate</h4>
    </div>
    <div class="document-footer">
      <div class="file-preview-container">
        <!-- Show image preview if selectedFilePreview contains image data -->
        <div *ngIf="certificate?.msmeCertificate && isImage(certificate.msmeCertificate)" class="file-preview-wrapper">
          <img [src]="certificate?.msmeCertificate" alt="Image Preview" class="file-preview" />
          <mat-icon class="delete-btn-icon-new" (click)="deleteFile('msmeCertificate', certificate.msmeCertificate)">&#10005;</mat-icon>
        </div>
      

        <!-- Show the PDF icon and name for PDF files -->
        <div *ngIf="certificate?.msmeCertificate && isPDF(certificate.msmeCertificate)" class="file-preview-wrapper">
          <img src="../../../../assets/images/pdfimageicon.png" alt="PDF Icon" class="file-preview" />
          <!-- <span>{{ selectedFileName }}</span> -->
          <mat-icon class="delete-btn-icon-new" (click)="deleteFile('msmeCertificate', certificate.msmeCertificate)">&#10005;</mat-icon>
        </div>

  
        <!-- Show the file name for non-image, non-PDF files -->
        <div *ngIf="!selectedFilePreview && selectedFileName">
          <span>{{ selectedFileName }}</span>
          <mat-icon class="delete-btn-icon-new" (click)="deleteFile('msmeCertificate', certificate.msmeCertificate)">&#10005;</mat-icon>
        </div>
        
  
        <!-- File input for selecting files -->
        <input #fileInput id="fileInput_msmeCertificate" type="file" (change)="onFileSelected($event, 'msmeCertificate')" accept="image/*, .pdf" style="display: none;" />
      </div>
      <button mat-raised-button color="primary" class="upload-button" (click)="onUploadClick('msmeCertificate')">
        <!-- Only show the 'View' option if the file is uploaded -->
        <div class="viewbtnclass" *ngIf="certificate?.msmeCertificate" (click)="viewFile('msmeCertificate',certificate?.msmeCertificate)">
          <mat-icon class="viewmaticon">visibility</mat-icon>
          <span>View</span>
        </div>
        <!-- Show 'Upload' if the file is not uploaded yet -->
        <div class="newarwbtn" *ngIf="!certificate?.msmeCertificate">
          <div>
            <mat-icon>arrow_upward</mat-icon>
          </div>
          <div>
            <span>Upload</span>
          </div>
        </div>
      </button>
      <input #fileInput id="fileInput_msmeCertificate" type="file" (change)="onFileSelected($event, 'msmeCertificate')" accept="image/*, .pdf" style="display: none;"/>
    </div>
  </div>


  <!-- Confirmation Popup -->
<div *ngIf="showConfirmationPopup" class="confirmation-popup">
  <div class="overlay" [class.visible]="isVisible" (click)="closePopup(false)"></div>
  <mat-icon class="close-popup" (click)="closePopup()">close</mat-icon>
  <!-- <button class="close-popup" (click)="closePopup()">×</button> -->
  <div class="popup-content">
    <h3>Are you sure You want to delete the copy of </h3>
    <h2>{{ getDocumentTitle(documentData.type) }} ?</h2>
    <div class="popup-actions">
      <button mat-raised-button color="warn" class="no-button"(click)="cancelDelete()">No</button>
      <button mat-raised-button color="primary" class="yes-button" (click)="confirmDelete()">Yes</button>
    </div>
  </div>
</div>
