import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AspViewDocumentService } from './asp-view-document.service';
import { ChangeDetectorRef } from '@angular/core';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-asp-view-document',
  templateUrl: './asp-view-document.component.html',
  styleUrls: ['./asp-view-document.component.scss']
})
export class AspViewDocumentComponent implements OnInit {
  documentData: { name: string; type: string; fileURL: string | null } = {
    name: '',
    type: '',
    fileURL: null,
  };
  showConfirmationPopup: boolean = false;

  constructor(private route: ActivatedRoute, private router: Router,private sanitizer: DomSanitizer,private aspViewDocumentService: AspViewDocumentService,  private cdr: ChangeDetectorRef,private toastr: ToastrService) {}

  // ngOnInit(): void {
  //   // Retrieve document data from localStorage under 'selectedDocument'
  //   const storedDocumentData = localStorage.getItem('selectedDocument');
  //   if (storedDocumentData) {
  //     this.documentData = JSON.parse(storedDocumentData);
  //   } else {
  //     console.error('No document found in localStorage');
  //   }
  // }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      const url = params['url']; 
      const fileType = params['title']
      const fileName = params['fileName'] || localStorage.getItem("fileName"); 
      console.log('File URL:', url); // Debugging URL
      console.log('File Type:', fileType); // Debugging Type
      if (url) {
        this.documentData.fileURL = url; 
      }
      if (fileType) {
        this.documentData.type = fileType; 
      }
      if (fileName) {
        this.documentData.name = fileName; 
      } else {
        this.documentData.name = 'No file uploaded'; 
      }
    });
    // this.confirmDelete();
  }
  
  showPopup() {
    this.showConfirmationPopup = true; 
  }

  closePopup(confirmDelete: boolean) {

    this.showConfirmationPopup = false; 
    
  }

  confirmDelete(){

    if (!this.documentData.type) {
      console.error('Document type is missing:', this.documentData);
      alert('Unable to determine the document type. Please try again.');
      return;
    }
    if (this.documentData.fileURL) {
      const mobile = localStorage.getItem('userMobile'); 
      const deleteType = this.documentData.type; 
      const imgUrl = this.documentData.fileURL; 
      
      this.aspViewDocumentService.deleteDocument(mobile, deleteType, imgUrl).subscribe({
        next: (response) => {
          console.log('Delete Response:', response); 
          this.toastr.success('File deleted successfully');
          this.documentData = { name: '', type: '', fileURL: null }; 
          // this.location.back();
          this.router.navigate(['my-documents']);


        }       
        
      });
    }
    this.showConfirmationPopup = false;
  }
  

  downloadFile(): void {
    if (this.documentData.fileURL) {
      console.log('Attempting to fetch file from URL:', this.documentData.fileURL);
  
      fetch(this.documentData.fileURL, { method: 'GET' })
        .then(response => {
          console.log('Fetch response:', response);
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.blob();
        })
        .then(blob => {
          const downloadLink = document.createElement('a');
          const objectURL = URL.createObjectURL(blob);
          downloadLink.href = objectURL;
          downloadLink.download = this.documentData.name || 'downloaded_file';
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
          URL.revokeObjectURL(objectURL);
          console.log('File download successful!');
        })
        .catch(error => {
          console.error('Download failed:', error.message);
          alert('Failed to download the file. Please try again later.');
        });
    } else {
      alert('No file URL found to download');
    }
  }
  
  // Function to check if the file is an image based on its file extension
  isImage(url: string): boolean {
    return (url && (url.endsWith('.jpg') || url.endsWith('.jpeg') || url.endsWith('.png') || url.endsWith('.gif')));
  }
  

  // Function to check if the file is a PDF based on its file extension
  isPDF(fileURL: string): boolean {
    return fileURL?.toLowerCase().endsWith('.pdf');
  }
  
  transform(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  getDocumentTitle(key: string): string {
    const titles: { [key: string]: string } = {
      gstCertificate: 'Copy of GST Certificate',
      aadharCard: 'Copy of Aadhaar Card',
      panCard: 'Copy of PAN Card',
      establishmentCertificate: 'Copy of Shop Establishment Certificate',
      coi: 'Copy of COI (in case of Company)',
      msmeCertificate: 'Copy of MSME Certificate (in case registered under MSME)'
    };
    return titles[key] || 'Unknown Document';
  }
  goBack(){
    this.router.navigate(['my-documents']);
  }
 
}