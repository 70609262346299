import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalVariable } from 'app/main/globals'; // Adjust the path if needed
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AspVehicleServiceService {

  constructor(private http: HttpClient) { }

  getVehicleHistoryList(registrationNumber: string, garageKey: string): Observable<any> {
    console.log('-----Fetching Vehicle History List-----------');
    const requestBody = {
      registrationNumber: registrationNumber,
      garageKey: garageKey,
    };
    return this.http.post( `${GlobalVariable.BASE_API_URL_SPARES}/booking/v1/vehicleHistoryList`,  requestBody, );
  }
}
