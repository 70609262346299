<div class="overlay" [class.visible]="isSidebarOpen" (click)="closeSidebar()"></div>
<div *ngIf="isSidebarOpen" class="sidebar">
    <div class="header">
      <h2>Enter Item List</h2>
      <button class="close-btn" (click)="closeSidebar()">×</button>
    </div>
    <p>Enter item names separated by commas or in separate lines. Click <strong>'Enter'</strong> when done.</p>
    <textarea [(ngModel)]="itemList" rows="20" placeholder="Enter items here..."></textarea>
    <button class="cta-btn" (click)="submitItems()">Enter</button>
    <p *ngIf="showError" class="Error" style="color: red;">Please enter at least one item name to proceed.</p>
  </div>


  