import { ChangeDetectorRef, Component, ComponentFactoryResolver, OnInit,  } from '@angular/core';
import { AspCustomerQuoteService } from './asp-customer-quote.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Router } from '@angular/router';
import { GlobalVariable } from 'app/main/globals';
@Component({
  selector: 'app-asp-customer-quote',
  templateUrl: './asp-customer-quote.component.html',
  styleUrls: ['./asp-customer-quote.component.scss']
})
export class AspCustomerQuoteComponent implements OnInit {

  isVisible: boolean;
  isBottomSheetOpen = false;
  quoteShipping: any;
  resIds: any;
  data: any;
  email: string;
  phone: string;
  quoteId: any;
  resData: any;
  isPopupOpen = false;
  selectedItems: any[];
  sidebarOpenQuote: boolean = false;
  requestDetails: any;
  customerQuotation: any = [];
  customerQuotationPrice: any = 0;
  item: any;
  regiNo: any;
  dataid: any;
  requestId: any;
  selectedItemsQuote: any[];
  selectedItemsCountQuote: any;
  totalSelectedPriceQuote: any;
  selectAllQuote:boolean = false;
  items: any;
  totalMRP: any;
  itemQuote: any = [];
  isPopupOpenEdit: boolean = false;
  successIcon: string;
  isMobile: boolean;
  isJobCardDetails: any;
  activeUrl: any;
  jobcardId:any = null;

  constructor(
    private aspCustomerQuoteService:AspCustomerQuoteService,
    private resolver: ComponentFactoryResolver,
    private breakpointObserver: BreakpointObserver,
    private cdr: ChangeDetectorRef,
    private router: Router,
  ) {
      this.breakpointObserver.observe([Breakpoints.Handset])
    .subscribe(result => {
      this.isMobile = result.matches;
    }); }

  openSidebarQuote(): void {
    this.clearOldData(); 
    this.getRequestDetails();
    this.sidebarOpenQuote = true; 
    this.closeSidebar(false);
    this.closeMenu();
    this.submitDataUpdate();    
  }
  clearOldData(): void {
    this.customerQuotation = []; 
    this.sidebarOpenQuote = false;
    this.customerQuotationPrice=0;
    this.selectAllQuote=false;
    this.selectedItemsCountQuote=[];
}
  CloseSidebarQuote(): void {
    this.sidebarOpenQuote = false;     
  }



  ngOnInit(): void {
    this.activeUrl = this.router.url;
    this.isJobCardDetails = this.activeUrl.includes('/my-job-cards-details');
    this.getQuoteDetails();
    this.email = localStorage.getItem('contactEmail');
    this.phone = localStorage.getItem('contactPhone');
    this.successIcon = '../../../../assets/icons/icons8-ok.gif';
  }
  
  closeSidebar(ishide: boolean) {
    this.isVisible = ishide;
    
    // this.getQuoteDetails();
    
  }
  opendetais(){
    this.closeSidebar(true);
    this.closePopup();
  }
  openMenu() {
    this.isBottomSheetOpen = true;
    
  }
  resId(id){
    this.resIds=id;
  }
  regNo(regNo){
    this.regiNo=regNo;
  }
  closeMenu(): void {
    this.isBottomSheetOpen = false;
  }
  shareForApproval(): void {
    let garageName = localStorage.getItem('spareSelectedGarageName');
    const message = `*Approval Needed for Your Spare Parts Quotation*\n\n`+
    `Dear Customer\n\n`+
    `We've prepared the spare parts quotation for your car's repair. 🚗\n`+
    `Please review and approve it at your earliest convenience by clicking the link below:\n\n`+
    `👉 ${GlobalVariable.AUTOVERSE_WEB}/quote-details/`+this.quoteId+`/`+this.jobcardId+` \n\n`+
    `Your prompt approval will help us speed up the repair process. 🛠\n\n`+
    `Thank you for choosing `+garageName+`. We value your trust in us!\n\n`+
    `Best Regards,\n`+
    `*`+garageName+`*`;

    // WhatsApp API or Intent to share
    window.open(`https://web.whatsapp.com/send?text=${encodeURIComponent(message)}`, '_blank');

    this.closeMenu();
  }

  async downloadPDF() {
    // Import html2pdf dynamically
    const html2pdf = await import('html2pdf.js');
    
    // Reference the container element
    const element = document.getElementById('quotationContent');
    
    if (!element) {
      console.error('Element not found!');
      return;
    }

    // Options for PDF generation
    const options = {
      margin: 1,
      filename: 'quotation.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
    };

    // Generate the PDF
    html2pdf.default().from(element).set(options).save();
  }
  
  getQuoteDetails() {
    const userKey = localStorage.getItem('spareUserKey');
    const garageKey = localStorage.getItem('garageKey');
    this.quoteId = this.resIds;
  
    this.aspCustomerQuoteService.getQuoteDetails(garageKey, this.quoteId, userKey).subscribe(
      result => {
        const res = JSON.parse(JSON.stringify(result));
        this.data = res.data;
        this.jobcardId = this.data?.items[0].jobcardId;
  
        // console.log("this.data---", this.data); // Debugging the response
        this.cdr.detectChanges();// Check if items array is populated
      },
      error => {
        console.error('Error fetching quote details:', error);
      }
    );
  }
  
  calculateTotalAmount(): number {
    return this.data?.items?.reduce((total, item) => {
      return total + (item.sellingPrice * item.quantity);
    }, 0) || 0; // Ensure a fallback value if items is undefined
  }

  calculateLabourAmount(): number {
    return this.data?.labourQuoteItemResponses?.reduce((total, item) => {
      return total + (item.labourAmount * item.quantity);
    }, 0) || 0; 
  }

  calculateTotalQuoteAmount(): number {
    const totalAmount = this.calculateTotalAmount();
    const labourAmount = this.calculateLabourAmount();
    return totalAmount + labourAmount;
  }
  


  

getRequestDetails() {
  const garageKey = localStorage.getItem('garageKey');
  const requestId = this.requestId;
  const userKey = localStorage.getItem('spareUserKey');

  this.aspCustomerQuoteService.requestDetails(garageKey, requestId, userKey).subscribe(result => {
    const res = JSON.parse(JSON.stringify(result));
  
    
    if (res.code === 200) {
      this.requestDetails = res.data;
      console.log("spare-request----",this.requestDetails);

      
      this.requestDetails.spareRequestItemList.forEach(item => {

        res.data.bidDetails.forEach(bid => {
          bid.estimateItem[item.itemName].forEach(bidItem => {
            
            bidItem.itemName = item.itemName;
            // bidItem.itemBrand=item.itemBrand;
            bidItem.itemQuantity = item.itemQuantity;                                
            bidItem.itemStatus = item.status; 
            bidItem.itemId = item.id;
            bidItem.lineitemId = item.lineitemId;
            bidItem.spareQuotationId = item.spareQuotationId;  
             
            if ([4, 5, 6, 7, 8, 9].includes(bidItem.itemStatus)) {                             
              this.customerQuotation.push(bidItem);            
              this.customerQuotationPrice = this.customerQuotationPrice + (bidItem.mrp *bidItem.itemQuantity);
            }

          });            
        });
        
      });
    }
  });
}


submitData() {
  if (!this.quoteId) {
    this.selectedItems = this.customerQuotation.filter(quote => quote.selected)
    this.selectedItems.forEach(item => {
      this.item=item.bidId;
    });

    const cleanData = {
      
      bidId: this.item,
      garageKey: localStorage.getItem('garageKey'),
      items: this.selectedItems.map(item => ({
        id: item.itemId,
        part_name: item.itemName,
        partNumber: item.partNumber,
        sellingPrice: item.mrp
      })),
      labourItems:[],
      registrationNumber: this.regiNo,
      requestId: this.requestId,
      userKey:localStorage.getItem('spareUserKey'),
    };

    this.aspCustomerQuoteService.submitData(cleanData).subscribe(response => {
      this.resData=response['data'];
      this.dataid=response['data'].id;
      console.error('response:',response );
    }, error => {
      console.error('Error occurred:', error);
    });
  }else{    
    if (this.quoteId) {
      this.submitDataUpdate();
    }
  }
}
submitDataUpdate() {
  this.selectedItems = this.customerQuotation.filter(quote => quote.selected)
  this.selectedItems.forEach(item => {
    this.item=item.bidId;
  });
  
  const cleanData1 = {
    bidId: this.item,
    garageKey: localStorage.getItem('garageKey'),
    items: this.selectedItems.map(item => ({
      id: item.itemId,
      part_name: item.itemName,
      partNumber: item.partNumber,
      sellingPrice: item.mrp
    })),
    quoteId: this.quoteId,
    registrationNumber: this.regiNo,
    requestId: this.requestId,
    userKey:localStorage.getItem('spareUserKey'),
  };
  
  this.aspCustomerQuoteService.submitDataUpdate(cleanData1).subscribe(response => {
    this.resData=response['data']
    console.error('response11:',response );
  });
}
updateSelectedItemsQuote(): void {
  this.selectedItemsQuote = this.customerQuotation.filter(quote => quote.selected)
  this.selectedItemsCountQuote = this.customerQuotation.filter(quote => quote.selected).length;
  // this.totalSelectedPrice = this.totalSelectedPrice + ((bidItem.mrp - (bidItem.mrp* bidItem.sellingDiscount /100))*bidItem.itemQuantity);
  this.totalSelectedPriceQuote = this.customerQuotation.filter(quote => quote.selected).reduce((total, quote) => total+(quote.mrp *quote.itemQuantity), 0);
  // this.quoteApprovedPrice = this.quoteApprovedPrice + ((bidItem.mrp - (bidItem.mrp* bidItem.sellingDiscount /100))*bidItem.itemQuantity);
  
}
 anyItemSelectedQuote() {
    return this.selectedItemsCountQuote > 0;
  }
  getMakeImageUrl(make: string): string {
    if(make){
      const makeWithoutSpaces = make.toLowerCase().replace(/\s+/g, '');
      return `https://cube.getpitstop.com/assets/img/${makeWithoutSpaces}.png`;
    }else {
      return
    }
  }
  totalAmount(): number {
    return this.items.filter(item => item.selected).reduce((total, item) => total + item.price, 0);
  }
  totalAmountQuote(): number {
    return this.items.filter(quote => quote.selected).reduce((total, item) => total + item.price, 0);
  }
  toggleSelectAllQuote(): void {
    this.selectAllQuote = !this.selectAllQuote;
    this.customerQuotation.forEach(quote => quote.selected = this.selectAllQuote);
    this.updateSelectedItemsQuote();
  }
  calculateMRP(item: any): number {
    this.customerQuotation.forEach
    return (item.mrp - (item.mrp * item.sellingDiscount / 100)) * item.itemQuantity;
  }
  calculateTotalMRP(): void {
    this.totalMRP = this.items.reduce((total, item) => {
      return total + this.calculateMRP(item);
    }, 0);
  }


  toggleItemSelectionQuote(): void {
    this.selectAllQuote = this.customerQuotation.every(quote => quote.selected);
    this.updateSelectedItemsQuote();
  }

  
  openEditDialog(itemQuotes){
    this.itemQuote = [];
    this.itemQuote=itemQuotes;
    this.itemQuote.discountedPrice = this.calculateDiscountedPrice(itemQuotes.mrp, itemQuotes.discount);
    this.isPopupOpenEdit = true;
    this.selectedItemsCountQuote = 0;
    this.customerQuotation.forEach(quote => {
      quote.selected = false;
    });
    this.customerQuotation.forEach(quote => quote.selected = this.selectAllQuote=false);
  }

  calculateDiscountedPrice(mrp: number, discount: number): number {
    return mrp - (mrp * discount / 100);
  }
  save(): void {
    // Find the index of the current item in the customerQuotation array
    const index = this.customerQuotation.findIndex(i => i.name === this.itemQuote.name);

    // If the item exists, update it
    if (index) {
        // Update the item in the list with the new unit price and recalculate total amount
        this.customerQuotation[index].mrp = this.itemQuote.mrp;
        this.customerQuotation[index].totalAmount = this.itemQuote.mrp * this.itemQuote.itemQuantity;
        

    }
    
    // Recalculate the total quotation price
    this.customerQuotationPrice = this.calculateTotalQuotationPrice();



    // Close the popup after saving
    this.closePopupEdit();

}
closePopupEdit(): void {
  this.isPopupOpenEdit = false;
}
calculateTotalQuotationPrice(): number {
  // Recalculate total price by summing up all items' total amounts
  return this.customerQuotation.reduce((sum, item) => sum + (item.mrp * item.itemQuantity), 0);
}

openPopup(): void {
  this.isPopupOpen = true;
  this.CloseSidebarQuote();
  this.submitData(); 
}

closePopup(): void {
  this.isPopupOpen = false;
}
}
