import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalVariable } from 'app/main/globals'; // Adjust the path if needed
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SideBarJobcardService {
  bookingId: any;
  getJobCardData: any;
  constructor(private http: HttpClient) {}

  regDecode(regNo: string) {
    // Assuming that regNo is passed correctly to the API endpoint
    console.log('-----Registration Decode-----------');
    return this.http.get(`${GlobalVariable.BASE_API_URL_SPARES}/vehicle/decode/${regNo}`);
  }

  createbookingId(param):Observable<any> {
    const paramData = param;
    paramData['source'] = "web";

    console.log("-------------Create Jobcard---------------------");
      return this.http.post(`${GlobalVariable.BASE_API_URL_SPARES}/booking/v1/jobCard/create`,paramData);
    
  }

  getServiceList(): Observable<any> {
    console.log('-----Fetching Service List-----------');
    return this.http.get(`${GlobalVariable.BASE_API_URL_SPARES}/booking/v1/getServiceList`);
    // return this.http.get(`${GlobalVariable.BASE_API_URL_SPARES}/vehicle/decode/${regNo}`);
  }

  getPreFilledBooking(registrationNumber: string, garageKey: string): Observable<any> {
    console.log('-----Fetching Pre-Filled Booking-----------');
    const requestBody = {
      registrationNumber: registrationNumber,
      garageKey: garageKey
    };
  
    return this.http.post(`${GlobalVariable.BASE_API_URL_SPARES}/booking/v1/preFilledBooking`, requestBody);
  }
  
}



//   createBooking(garageKey: string, name: string, phoneNumber: string) {
//     const paramData = {
//       garageKey: garageKey,
//       name: name,
//       phoneNumber: phoneNumber,
//       userId: 'f15c9e1c7e3eb40a1f1f420028c11d83',
//     };
//     console.log('Creating booking with data:', paramData);
//     return this.http.post(`${GlobalVariable.BASE_API_URL_SPARES}` + '/booking/v1/create', paramData);
//   }

// }


