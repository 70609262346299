import { Component, OnInit } from '@angular/core';
import { AspVehicleServiceService } from './asp-vehicle-service.service';


@Component({
  selector: 'app-asp-vehicle-service-history',
  templateUrl: './asp-vehicle-service-history.component.html',
  styleUrls: ['./asp-vehicle-service-history.component.scss']
})
export class AspVehicleServiceHistoryComponent implements OnInit {
  vehicleDetails: any;
  AspVehicleServiceService: any;
  registrationNumber: string;
  vehicleHistoryData: any[] = [];
  isSidebarOpen = true;
  
  constructor(
    private aspVehicleServiceService: AspVehicleServiceService, 
  ) { }

  ngOnInit(): void {
    this.loadVehicleDetails();
    this.fetchVehicleHistory();
  }

  openSidebar(isOpen: boolean): void {
    console.log('Sidebar open status:', isOpen);
    this.isSidebarOpen = isOpen;
    // Add your logic to show the sidebar
  }
  closeSidebar(): void{
    this.openSidebar(false);
  }
  loadVehicleDetails(): void {
    this.vehicleDetails = {
      make: localStorage.getItem('selectedMake'),
      model: localStorage.getItem('selectedModel'),
      year: localStorage.getItem('selectedYear'),
      fuelType: localStorage.getItem('fuelType'),
      vin: localStorage.getItem('vin'),
      variant: localStorage.getItem('variant'),
      registrationNumber: localStorage.getItem('registrationNumber')
    };
  }
  getMakeImageUrl(make: string): string {
    const makeWithoutSpaces = make.toLowerCase().replace(/\s+/g, '');
    return `https://cube.getpitstop.com/assets/img/${makeWithoutSpaces}.png`;
  }
  fetchVehicleHistory(): void {
    const garageKey = localStorage.getItem('garageKey');
    const registrationNumber = localStorage.getItem('registrationNumber');
    if (garageKey && registrationNumber) {
      this.aspVehicleServiceService.getVehicleHistoryList(registrationNumber, garageKey).subscribe(
        (response) => {
          if (response && response.data) {
            this.vehicleHistoryData = response.data; 
            console.log('Vehicle History Response:', response);

            // ----------------- SORTING------------
            this.vehicleHistoryData.sort((a, b) => {
              return new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime();
              const gstToIst = (dateStr: string): number => {
                const gstDate = new Date(dateStr);
                // GST to IST: Add 1 hour 30 minutes (90 minutes)
                return gstDate.getTime() + 90 * 60 * 1000;
              }
              
            });
            
          }
        },
        (error) => {
          console.error('Error fetching vehicle history:', error);
        }
      );
    } else {
      console.error('Garage Key or Registration Number is missing.');
    }
  }
}
