<div class="page-layout blank fullwidth p-12" style="height: 1px; overflow-y: scroll;    top: 1px;z-index: 1000;">

<div class="order-list">
  <h2 class="title">My Orders</h2>

    <!-- <mat-progress-bar class="mt-8" *ngIf="loading" mode="indeterminate"></mat-progress-bar> -->
    <div [ngClass]="isMobile? 'containerMobile' : 'container'">
      <div class="sidecontainer" *ngIf="!isMobile" >
        <div class="sidebar">
          <h3 >Filters</h3> 
          <hr />

          <!-- Conditionally show the Clear Filters button -->
          <div class="clear">
            <div *ngIf="hasAppliedFilters()" class="applied-filters">
                <h4>Applied Filters</h4>
                <div *ngFor="let appliedFilter of getAppliedFilters()" class="apply">
                    <span>{{ appliedFilter.option.label }}</span>
                    <mat-icon (click)="removeFilter(appliedFilter.orderTime, appliedFilter.option)" class="apply1" >cancel</mat-icon>
                </div>
                
                
            </div> 
            
            <div *ngIf="hasAppliedFilters()" (click)="clearFilters()" class="clear-filters-btn">Clear Filters</div>
            
        </div>
        
          
          
          <!-- Order-status -->
          <div class="filter-section">
            <h4 >ORDER STATUS</h4>
            <div *ngFor="let status of filters.orderStatus" class="filter-option">
              <label>
                <span>{{ status.label }}</span>
                <span class="radio-container">
                  <input type="radio" name="orderStatus" [(ngModel)]="selectedOrderStatus" [value]="status.value" (change)="toggleSelection('orderStatus', status)" class="filter-radio">
                </span>
              </label>
            </div>
          </div>
          <hr />
          
          <!-- Order-Time -->
          <div class="filter-section">
            <h4 >ORDER TIME</h4>
            <div *ngFor="let time of filters.orderTime">
              <div *ngIf="time.children; else singleRadio" class="filter-option">
                <h5>{{ time.label }}</h5>
                <div *ngFor="let child of time.children" class="filter-option">
                  <label>
                    <span>{{ child.label }}</span>
                    <span class="radio-container">
                      <input type="radio" name="orderTime" [(ngModel)]="selectedOrderTime" [value]="child.value" (change)="toggleSelection('orderTime', child)" class="filter-radio">
                    </span>
                  </label>
                </div>
              </div>
              <ng-template #singleRadio>
                <label class="filter-option">
                  <span>{{ time.label }}</span>
                  <span class="radio-container">
                    <input type="radio" name="orderTime" [(ngModel)]="selectedOrderTime" [value]="time.value" (change)="toggleSelection('orderTime', time)" class="filter-radio">
                  </span>
                </label>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
      <div [ngClass]="isMobile ? 'main-contentMobile' : 'main-content'">
        <div [ngClass]="isMobile ? 'searchMobile' : 'search'">
          
          <input 
          type="text" 
          [(ngModel)]="searchTerm" 
          (keydown.enter)="filterOrders()" 
          (input)="showClearIcon = searchTerm.length >= 0" 
          (click)="hideScrollText = true"
        />
        <span 
          class="close-button" 
          *ngIf="hideScrollText" 
          (click)="clearSearch()" 
          class="clear-btn"
        >✖</span>
        <div 
          class="scroll-text-container"
        >
          <div class="SearchBarIcon">
            <i class="material-icons-search">search</i>
          </div>
          <div 
            class="scroll-text" 
            [class.hidden]="hideScrollText"
          >
            <div id="animation-1" class="SearchBarAnimationText">Search By "Order ID"</div>
            <div id="animation-2" class="SearchBarAnimationText">Search By "Request ID"</div>
            <div id="animation-3" class="SearchBarAnimationText">Search By "Registration Number"</div>
            <div id="animation-4" class="SearchBarAnimationText">Search By "Car Make"</div>
            <div id="animation-5" class="SearchBarAnimationText">Search By "Car Model"</div>
            <div id="animation-6" class="SearchBarAnimationText">Search By "Fuel Type"</div>

          </div>

            </div>
          <button *ngIf="!isMobile" class="searchbutton" (click)="filterOrders()">🔍 Search Orders</button>
          <button *ngIf="isMobile" class="searchbutton" (click)="filterOrders()">🔍</button>
         
          <span  (click)="sidecontainer()" *ngIf="isMobile" class="filterslist">
            <mat-icon>filter_list</mat-icon>
            Filters
          </span>
        </div>



<!-- Conditionally show the sidecontainer based on the value of isSidebarOpen -->
<div class="overlay" [class.visible]="isSidebarOpen" (click)="sidecontainer()"></div>
<div *ngIf="isSidebarOpen" class="sidecontainerMobile">
  <div class="sidebarMobile">
    
    <h3 style="margin-left: -145px;"><i (click)="sidecontainer()" class="material-icons" style="color: black;font-weight: bold;     margin-right: 21px;top: 5px;position: relative;">arrow_back</i>Filters</h3>
    <hr />

    <!-- Conditionally show the Clear Filters button -->
    <div class="clear">
      <div *ngIf="hasAppliedFilters()" class="applied-filters">
        <h4>Applied Filters</h4>
        <div *ngFor="let appliedFilter of getAppliedFilters()" class="apply">
          <span>{{ appliedFilter.option.label }}</span>
          <mat-icon (click)="removeFilter(appliedFilter.orderTime, appliedFilter.option)" class="apply1">cancel</mat-icon>
        </div>
      </div>

      <div *ngIf="hasAppliedFilters()" (click)="clearFilters()" class="clear-filters-btn">
        Clear Filters
      </div>
    </div>

    <!-- Filter Sections -->
    <div class="filter-section" style="margin-left: 43px;">
      <h4>ORDER STATUS</h4>
      <div *ngFor="let status of filters.orderStatus" class="filter-option">
        <label>
          <span>{{ status.label }}</span>
          <span class="radio-container">
            <input type="radio" name="orderStatus" [(ngModel)]="selectedOrderStatus" [value]="status.value" (change)="toggleSelection('orderStatus', status)" class="filter-radio">
          </span>
        </label>
      </div>
    </div>
    <hr />

    <div class="filter-section" style="margin-left: 43px;">
      <h4>ORDER TIME</h4>
      <div *ngFor="let time of filters.orderTime">
        <div *ngIf="time.children; else singleRadio" class="filter-option">
          <h5>{{ time.label }}</h5>
          <div *ngFor="let child of time.children" class="filter-option">
            <label>
              <span>{{ child.label }}</span>
              <span class="radio-container">
                <input type="radio" name="orderTime" [(ngModel)]="selectedOrderTime" [value]="child.value" (change)="toggleSelection('orderTime', child)" class="filter-radio">
              </span>
            </label>
          </div>
        </div>
        <ng-template #singleRadio>
          <label class="filter-option">
            <span>{{ time.label }}</span>
            <span class="radio-container">
              <input type="radio" name="orderTime" [(ngModel)]="selectedOrderTime" [value]="time.value" (change)="toggleSelection('orderTime', time)" class="filter-radio">
            </span>
          </label>
        </ng-template>
      </div>
    </div>
<div class="applyFilter">
    <button (click)="sidecontainer()" class="applyFilterText">Apply Filter</button>
  </div>
  </div>
</div>
        
  
        <!-- Order Headers -->
        <!-- <div *ngIf="loading">Loading...</div> -->
        <div class=no-img *ngIf="!loading && filteredOrderList.length === 0">
          <img [src]="emptyList" alt="No data available" />
          <h1 class="no-request-text" >No Order Found</h1>
          <div class="eidt-text">Edit filter or go back to my order page</div>
          <button class="go-back-btn" (click)="clearFilters()" >Go to My Orders</button>
        </div>
        <div *ngIf="!loading && filteredOrderList.length > 0">
          <div *ngFor="let list of filteredOrderList">
            <div class="header" >  
              <div class="header-row">
                <!-- <div [ngClass]="isMobile ? 'order-iconMobile' : 'order-icon'">
                  <i class="material-icons">check_box</i>
                </div> -->
                <div class="order-info">
                  <div class="order-status">
                    <h1 *ngIf="list.titleName && list.titleName !== '0 minutes'" >
                      Order delivered in {{ list.titleName }}</h1>


                      <!-- <div>{{list.titleName}}</div> -->
                      <h1 *ngIf="list.titleName && list.titleName === '0 minutes'" >
                        Your order is in progress</h1>
                        
                    <div 
                      class="delivery-status" 
                      *ngIf="!isMobile"
                      [ngClass]="{
                          'delivered-status': list.orderCurrentStatus === 'Delivered',
                          'confirmed-status': list.orderCurrentStatus === 'Confirmed',
                          'packed-status': list.orderCurrentStatus === 'Packed',
                          'on-the-way':list.orderCurrentStatus==='On The Way'}">
                        {{list.orderCurrentStatus}}
                    </div>
                    <div 
                      class="delivery-statusMobile" 
                      *ngIf="isMobile"
                      [ngClass]="{
                          'delivered-status': list.orderCurrentStatus === 'Delivered',
                          'confirmed-status': list.orderCurrentStatus === 'Confirmed',
                          'packed-status': list.orderCurrentStatus === 'Packed',
                          'on-the-way':list.orderCurrentStatus==='On The Way'}">
                        {{list.orderCurrentStatus}}
                    </div>
                  </div>
                  <div class="request-ids" style="margin-top: -3px;">
                  <div class="request-id"  style="margin-top: 47px;border-top: 1px solid lightgray;" *ngIf="list?.orderNumber">Order No# {{list.orderNumber}}</div>
                  <div class="request-id"  style="margin-top: 47px;border-top: 1px solid lightgray;" *ngIf="!list?.orderNumber" >Order No# {{list.orderId}}</div>

                  <div class="request-id" *ngIf="list.requestId !== 0">Request ID# {{list.requestId}}</div>
                  <!-- <div class="order-amount">Total Amount - ₹{{list.grandTotal}} </div> -->
                  <div class="order-amount">
                    Total Amount - ₹{{ list.grandTotal + (list.shippingCharges > 0 ? list.shippingCharges : 0) }}
                  </div>
                  <div style="margin-top: 11px; margin-bottom: 23px;" *ngIf="list?.make">
                  <div class="logo-container"  >
                    <div class="logo">
                    <img 
                        [src]="'https://cube.getpitstop.com/assets/img/' + list.make.toLowerCase().replace(' ', '') + '.png'" 
                        alt="Make Image"
                      >
                    </div>
                  
                  </div>
                 
                  <div class="model-sec" style="width:80%;line-height: 2.4px;" *ngIf="list?.model">
                    <p style="font-weight:bold">{{list.model}}, {{list.fuelType}}</p>
                    <p>{{list.variant}} [{{list.mfgYear}}]</p>
                    <p *ngIf="list?.registrationNumber" style="margin-bottom: -12px;">{{list?.registrationNumber}}</p>
                </div>
              </div>
             
                      
                  
                </div>
              </div>

              </div>
              <div class="header-row1">
                <div class="order-item"><span *ngFor="let items of list.orderItems; index as i"><span *ngIf="i!=0">,</span> {{items.partName}}</span></div>
              </div>
              <div class="header-row2">
                <div class="order-date">
                  <span>Placed on: {{list.createdAt | date: 'dd MMM yyyy'}}, {{list.createdAt | date: 'hh:mm a'}}</span>
                  <button (click)="loadorderdetails(list.orderKey)" class="Viewbtn">
                    <span class="view">View Details</span>
                    <i class="material-icons">arrow_right</i> 
                  </button>
                </div>
              </div>
            </div>
          </div>
        
    

  <!-- <infinite-scroll
  [infiniteScrollDistance]="scrollDistance"
  [infiniteScrollUpDistance]="scrollUpDistance"
  [infiniteScrollDelay]="scrollDelay"
  [infiniteScrollImmediateCheck]="true"
  (scrolled)="onScroll()">
</infinite-scroll> -->
<mat-paginator
    (page)="handlePageEvent($event)"
    [length]="length"
    [pageSize]="pageSize"
    [showFirstLastButtons]="true"
    [pageSizeOptions]="pageSizeOptions"
    [pageIndex]="pageIndex"
    aria-label="Select page">
</mat-paginator>
</div>
</div>
</div>
</div>
</div>
<div #dynamicContainer></div>
<div *ngIf="loading">
<div #dynamicContainers></div>
</div>
