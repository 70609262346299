import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalVariable } from 'app/main/globals';

@Injectable({
  providedIn: 'root'
})
export class AspQuotationDetailsService {

  constructor(
    private http: HttpClient
  ) { }

  getQuoteDetails(garageKey, quoteId, jobId, userKey){
      let param={
        garageKey: garageKey,
        quoteId: quoteId,
        userKey: userKey,
        jobcardId: jobId,
        source: "web" 
      }
      return this.http.post(`${GlobalVariable.BASE_API_URL_SPARES}/garage/customer/quote/detail`,param)
  }

  approveQuotation(param){
    param.source = "web";

    return this.http.post(`${GlobalVariable.BASE_API_URL_SPARES}/garage/jobcardListStatus`,param)
  }
}
