<div class="overlay" [class.visible]="isVisible" (click)="closeSidebar(false)"></div>
<div class="sidebar" [ngClass]="{
  'visible': isVisible && !isMobile,
  'isVisibleMobile': isVisible && isMobile
}">
  <!-- Sidebar content -->

   <!-- Quotation Container inside the Sidebar -->
    <div class="quotation-container" >
        <!-- Header Section -->
        <header class="quotation-header">
                <div class="back-button">
                   <i (click)="closeSidebar(false)" class="material-icons" style="color: black;font-weight: bold; cursor: pointer;">arrow_back</i>
                   <div class="title">Customer Quotation</div>
                   <i class="material-icons" style="width: 5%;color: black;cursor: pointer;" (click)="openMenu()">more_vert</i>
                </div>
             </header>
          <div class="page" id="quotationContent">
            <span >
                <img src="../../../../assets/images/car-repair.png" alt="car-repait">
                <h3 [ngClass]="isMobile ? 'car-repairMobile' : 'car-repair'">CAR REPAIR DESCRIPTION</h3>
            </span>
    
        <!-- Garage Info Section -->
         <div class="garage-quote" [ngClass]="{'garage-quoteMobile': isMobile}">
        <div class="garage-info">
            <p>{{quoteShipping?.address}}</p>
            <p>{{phone}}</p>
            <p>{{email}}</p>
        </div>

        <!-- Quotation Details -->
        <div class="quotation-info">
            <div class="info-item">
                <span  [ngClass]="isMobile ? 'labelMobile' : 'label'">Date:</span>
                <span [ngClass]="isMobile ? 'valueMobile' : 'value'">{{data?.placedOn | date: 'dd-MM-yyyy'}}</span>
            </div>
              <div class="info-item">
                <span [ngClass]="isMobile ? 'labelMobile' : 'label'">Quotation #:</span>
                <span [ngClass]="isMobile ? 'valueMobile' : 'value'">{{ quoteId }}</span>
              </div>
            <div class="info-item" >
                <span [ngClass]="isMobile ? 'labelMobile' : 'label'">Registration Number:</span>

                <span [ngClass]="isMobile ? 'valueMobile' : 'value'">{{data?.registrationNo}}</span>
            </div>
        </div>
    </div>
<div class="spare-part-header">
  <h1>SPARE ITEMS</h1>
</div>
        <!-- Items Table -->
        <table class="items-table1">
            <thead>
                <tr class="items-table">
                    <th>SPARE PARTS</th>
                    <th>QUANTITY</th>
                    <th>UNIT PRICE</th>
                    <th>AMOUNT</th>
                </tr>
            </thead>
            <tbody>
              <tr *ngFor="let itemData of data?.items">
                <td>{{ itemData.itemName }}</td>
                <td>{{ itemData.quantity }}</td>
                <td>₹ {{ itemData.sellingPrice }}</td>
                <td>₹ {{ itemData.sellingPrice * itemData.quantity }}</td>
              </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td colspan="3">TOTAL</td>
                    <td>₹{{calculateTotalAmount()}}</td>
                </tr>
            </tfoot>
        </table>
        <div class="Labour-items-header">
          <h1>LABOUR CHARGES</h1>
        </div>

        <table class="items-table1">
          <thead>
            <tr class="items-table">
              <td>LABOUR DESCRIPTION</td>
              <td>QUANTITY</td>
              <td>UNIT PRICE</td>
              <td>AMOUNT</td>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let itemData of data?.labourQuoteItemResponses">
              <td>{{ itemData.labourName }}</td>
              <td>{{ itemData.quantity }}</td>
              <td>₹ {{ itemData.labourAmount }}</td>
              <td>₹ {{ itemData.labourAmount * itemData.quantity }}</td>
            </tr>
          </tbody>
   
        <tfoot>
          <tr>
              <td colspan="3">TOTAL</td>
              <td>₹{{ calculateLabourAmount() }}</td>
          </tr>
      </tfoot>
        </table>

        <div class="Final-Amount-header">
          <h1>TOTAL AMOUNT</h1>
        </div>
        <table class="items-table1">
          <thead>
            <tr class="items-table">
              <td>DESCRIPTION</td>
              <td>AMOUNT</td>
            </tr>
          </thead>
          <tr>
            <td>SPARE PARTS TOTAL</td>
            <td >₹{{calculateTotalAmount()}}</td>
          </tr>
          <tr>
            <td>LABOUR CHARGES TOTAL</td>
            <td >₹{{ calculateLabourAmount()}}</td>
          </tr>
   
        <tfoot>
          <tr>
              <td colspan="1">TOTAL</td>
              <td>₹{{ calculateTotalQuoteAmount() }}</td>
          </tr>
      </tfoot>
        </table>

        <!-- Disclaimer Section -->
        <div class="disclaimer">
            <p>Disclaimer: Quoted price is approximate price provided from our source at the time of giving quotation. The actual price may vary during the final billing based on availability and actual system price.</p>
        </div>
    </div>
    </div>
    <div class="overlay" [class.visible]="isBottomSheetOpen" (click)="closeMenu()"></div>
        <mat-bottom-sheet-container *ngIf="isBottomSheetOpen">
            <mat-nav-list>
                <mat-list-item>
                    <span class="names">Share For Approval</span>
                    <i class="material" (click)="shareForApproval()">share</i>
                </mat-list-item>
                <mat-divider></mat-divider>
                <mat-list-item>
                    <span class="names">Download Quotation</span>
                    <img src="../../../../assets/images/download.png" style="height: 24px;cursor: pointer;" (click)="downloadPDF()" alt="">
                    
                </mat-list-item>
                <mat-divider></mat-divider>
                <mat-list-item>
                    <span class="names">Edit Quotation</span>
                    <i class="material" (click)="openSidebarQuote()">edit</i>
                </mat-list-item>
            </mat-nav-list>
            
        </mat-bottom-sheet-container>
    </div>
    

<div class="edits">
<mat-sidenav-container>
    <div class="overlay" [class.visible]="sidebarOpenQuote" (click)="CloseSidebarQuote()" ></div>
    <div class="sidebar" [ngClass]="{
      'sidebar-openQuote': sidebarOpenQuote && !isMobile,
      'sidebar-openQuoteMobile': sidebarOpenQuote && isMobile
    }">
      <mat-list>
      <header>
        <div class="back-button">
           <i (click)="CloseSidebarQuote()" class="material-icons" style="color: black;font-weight: bold; cursor: pointer;">arrow_back</i>
           <span class="title1">Customer Quotation</span>
           <div *ngIf="quoteId">
            <h2 class="quoteid">{{ quoteId }}</h2>
          </div>
        </div>
     </header>
     <div class="detailsQuote">
      <div class="logoModel">
        <img [src]="getMakeImageUrl(requestDetails?.spareRequest?.make)" alt="Icon" class="img">
        <div class="model-info">
          {{requestDetails?.spareRequest}}
          <span class="model-name">{{requestDetails?.spareRequest?.make}}</span>
          <span class="model-details">{{requestDetails?.spareRequest?.model}}, {{requestDetails?.spareRequest?.fuelType}}</span>
        </div>
    </div>
      <mat-divider></mat-divider>

      <div  class="items" style="margin-bottom: 88px;">
        <div class="request-info" style="font-weight: 900;font-size: larger; padding: 10px 0;border-bottom: 1px solid #ddd;">Quote received for {{requestDetails?.spareRequestItemList?.length}} items </div>
        <div class="item" >
          <div class="checkboxselect">
            <input type="checkbox" [checked]="selectAllQuote" (change)="toggleSelectAllQuote()">
            <span style="margin-left: 5px;">Select All</span>
          </div>
          <div class="Total">
            <span style="margin-left: 97px;">₹ {{customerQuotationPrice | number:'1.2-2'}}</span>
            <div style="margin-left: 45px;"> Total Amount  </div>
          </div>
        </div>

        <div class="quotereceived-items" *ngFor="let quote of customerQuotation" [ngClass]="{'border-box': quote.selected}">

          <div class="checkboxselect">
            <div class="lines" >
              <input type="checkbox" [(ngModel)]="quote.selected" (change)="toggleItemSelectionQuote()">
              <span class="part-name" style="margin-left: 5px;">{{quote.itemName}}</span>
              <!-- <span class="ellipsis" >⋮</span> -->
              <i class="material-icons" (click)="openEditDialog(quote)">edit</i>

              <div class="overlay1" [class.visible]="isPopupOpenEdit" (click)="closePopupEdit()" ></div>
              <div *ngIf="isPopupOpenEdit" [ngClass]="isMobile ? 'bottom-sheetMobile' : 'bottom-sheet'">
              
                  <header class="back-button">
                  <h3 class="header-name">{{ itemQuote.itemName }}</h3>
                    <i (click)="closePopupEdit()" style="cursor: pointer;">close</i>
                  </header>
                  <div class="bottom-sheet-content">
                  <div>
                    <label>Brand</label>
                    <span >{{ itemQuote?.brand }}</span>
                  </div>
                  <div>
                    <label>Quantity</label>
                    <span>{{ itemQuote?.itemQuantity }}</span>
                  </div>
                  <div>
                    <label>Availability</label>
                    <span>{{ itemQuote?.edt  | date:'dd MMM yyyy, hh:mm a' }}</span>
                  </div>
                  <div>
<!--                   
                    <label style="    width: 80%;display: block;float: left;">Unit Price</label>
                     <span class="inputPrice"> 
                      <mat-form-field style="float: left;width: 20%;">
                    <input style="text-decoration: underline;" matInput  [(ngModel)]="itemQuote.mrp" />
                    
                  </mat-form-field> -->
                  <!-- </span> -->
                </div>
                  <div>
                    <label>Total Amount</label>
                    <span>₹ {{ itemQuote?.mrp * itemQuote?.itemQuantity  | number:'1.2-2'}}</span>
                  </div>
                  <button  (click)="save()">Save Quote</button>
                </div>
                
              </div>
              
              
            </div>
            <!-- <input type="checkbox" [(ngModel)]="item.selected" (change)="toggleItemSelection()"> -->
            <!-- <span>{{item.itemName}}</span> -->
            <div class="lines" >
              <span style="margin-left: 5px" class="availability"> Availability: </span>
              <span class="date">₹{{quote.mrp*quote.itemQuantity | number:'1.2-2'}}</span>

            </div>
          </div>
          <div class="lines">
            <span style="margin-left: 5px" class="date-time">{{quote.edt  | date:'dd MMM yyyy'}}</span>
            <!-- <span class="quantity">  </span> -->
            <p style="background-color: black;">hello workd</p>
          </div>
        </div>
       

      </div>
    </div>
    
    <div *ngIf="anyItemSelectedQuote()" [ngClass]="isMobile ? 'bottom-boxQuoteMobile' : 'bottom-boxQuote'">
      <div class="bottom-contentQuote">
        
          <div class="total-items">{{selectedItemsCountQuote}} items
          <p class="total-amount">₹{{totalSelectedPriceQuote | number:'1.2-2'}}</p>
        </div>
          <button type="button"  [ngClass]="isMobile ? 'createQuoteMobile' : 'createQuote'" (click)="openPopup()">Create Customer Quote</button>
          
           
      </div>
    </div>
    
    
    </mat-list>
    
    
    
    </div>
     <!-- Popup content -->
    
    <div *ngIf="isPopupOpen" class="popup">
      <div class="popup-content">
      <img src="{{successIcon}}" style="width: 36%;" alt="">
      <h3 *ngIf="quoteId === null || quoteId === 'null'">Quote Created</h3>
      <h3 *ngIf="quoteId !== null && quoteId !== 'null'">Quote Updated</h3>
      <p class="order-label" *ngIf="quoteId === null || quoteId === 'null'">
        <span class="order-id">Quotation id {{ resData?.id }}</span>
      </p>
      <p class="order-label" *ngIf="quoteId !== null && quoteId !== 'null'">
        <span class="order-id">Quotation id {{ quoteId }}</span>
      </p>
      <p class="date"><span>{{resData?.placedOn | date:'dd-MMM-yyyy, hh:mm  a': 'GMT+1060'}}</span></p>
      <p class="thank you">You have successfully created quotation</p>
    
      <button class="goto-order" *ngIf="quoteId === null || quoteId === 'null'" (click)="opendetais()">View Customer Quote</button>
      <button class="goto-order" *ngIf="quoteId !== null && quoteId !== 'null'" (click)="opendetais()">View Customer Quote</button>
    </div>
  </div>
  
</mat-sidenav-container>
</div>
