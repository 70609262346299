import { Component, ChangeDetectionStrategy, OnInit, NgZone, HostListener, ElementRef, ViewChild, QueryList, ViewChildren, ViewContainerRef, ComponentFactoryResolver, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import {FormControl, FormBuilder} from '@angular/forms';
import {Subscription, BehaviorSubject, Observable} from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import {AspLoginService} from '../asp-login/asp-login.service';
import { ToastrService } from 'ngx-toastr';import {AspReplaceCartPopupComponent} from '../asp-replace-cart-popup/asp-replace-cart-popup.component';
import { ReplaceCartItemsService } from "../../../shared/replace-cart-items.service";
import { AspShippingChargesPopupComponent } from "../asp-shipping-charges-popup/asp-shipping-charges-popup.component";
import { MatCheckboxChange } from '@angular/material/checkbox';
import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import { AspSparesListService } from './asp-spares-list.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { AspDynamicPopupComponent } from '../asp-dynamic-popup/asp-dynamic-popup.component';
import { AnimationOptions } from 'ngx-lottie';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AspCartService } from '../asp-cart/asp-cart.service';
interface FilterOption {
  label: string;
  value: string;
  checked: boolean;
  children?: FilterOption[];
}
interface Filters {
  brands: FilterOption[];
  category: FilterOption[];
}
@Component({
  selector: 'app-asp-spares-list',
  templateUrl: './asp-spares-list.component.html',
  styleUrls: ['./asp-spares-list.component.scss'],
})

export class AspSparesListComponent implements OnInit, AfterViewInit {
  loading: boolean;
  control = new FormControl('');
  spares: string[] = [];
  make: string = '';
  model: string = '';
  year: string = ''; 
  variant: string = '';
  makeImage:string = '';
  aspSpareAdded = [];
  sparesList = [];
  addedCart = [];
  cartList = [];
  cartCount = 0;
  cartSubTotal = 0;
  cartGrandTotal = 0;  
  newData = {};  
  searchText:string = '';
  openSearch:boolean = true;
  clearSearch:boolean = false;
  isAddedCart:boolean = false;
  isCartCount:boolean = false;
  spareSelectedGarageName: any;
  categories = [];
  defaultToggle:boolean = false;
  labelPosition = 'before';
  isReplacePopup:boolean = false;
  vehicleType:any = localStorage.getItem('vehicleType');
  clickEventSubscription:Subscription;
  isShippingCharges:boolean = false;
  prepayUpiDiscount: any;
  spareStocks: any = [];
  catSubCatData: any = [];
  selectedBrands: any = [];
  selectedCategory: any = {};
  filterParams: any = {
    subCategory: [],
    brand: [],
  };
  allBrands:any = [];
  defaultImg: any = './assets/images/logos/logo.png';
  @ViewChild('dynamicContainers', { read: ViewContainerRef }) containers!: ViewContainerRef;
  // Filters
  @ViewChild('filterSection', { static: false }) filterSection!: ElementRef;
  
  filters: Filters = {
    brands: [].map(brand => ({
      ...brand,
      label: brand.label.toUpperCase(),
      value: brand.value.toUpperCase()
    })),
    category:[].map(category => ({
      ...category,
      label: category.label.toUpperCase(),
      value: category.value.toUpperCase(),
    }))
  };
  // End Filter
  // @ViewChild('scrollContainer') scrollContainer: ElementRef;
  currentPage: number = 0;  // Current page number
  batchSize: number = 200;  // Batch size (number of part numbers per request)
  // loading: boolean = false;
  res: any[] = []; 

  @ViewChildren('catListItems') catListItems;
  selectedSparesList: any = JSON.parse(localStorage.getItem('selectedSparesList'));

  selectedCategory1 = null;  
  isFixed = false;
  isAssured: boolean = false;
  request: any;
  garagekey: any;
  isNearBottom: any;
  itemSummary: any = [];
  pageIndex : number = 0;
  pageSize : number ;
  totalPages : number = 0;
  length : number = 0;
  hasMore: boolean = true;
  data: any[] = [];
  Messages: any;
  randomMessage: any;
  isMobile: boolean;
  isFilterShow = 'left-filters-hide';
  searchKey: string;
  leftMenuTop: any;
  leftMenuBottom: any;
  sidebar: any;
  sidebarContent: any;
  img: any;
  sparesDiscount: number;
  shippingCharges: any;
  grandAmount: any;
  items: any=[];
  amount: any;
  discount: any;
  fuel: string;
  registrationNumber: any;
  selectedFuel: string;
  bannerImages: any;
  stopAutoScroll: any;

  // @HostListener('window:click', ['$event'])
  // handleOutsideClick(event: MouseEvent) {
  //   const categoryContainer = document.querySelector('.side-menu-container');
  //   const subcategoryContainer = document.querySelector('.subcategory-container');
  //   const target = event.target as HTMLElement;

  //   if (
  //     categoryContainer && !categoryContainer.contains(target) &&
  //     subcategoryContainer && !subcategoryContainer.contains(target)
  //   ) {
  //     this.selectedCategory1 = null; // Close subcategories if clicking outside
  //   }
  // }

  constructor(
    private router: Router,
    private zone: NgZone,
    private aspSparesListService: AspSparesListService,
    public dialog: MatDialog,
    private aspLoginService: AspLoginService,
    private toastr: ToastrService,
    private replaceCartItemsService: ReplaceCartItemsService,    
    private bottomSheet: MatBottomSheet,
    private cdr: ChangeDetectorRef,
    private breakpointObserver: BreakpointObserver,
    private aspCartService: AspCartService,
  ) { 

    this.breakpointObserver.observe([Breakpoints.Handset])
    .subscribe(result => {
      this.isMobile = result.matches;
    }); 

    this.clickEventSubscription = this.replaceCartItemsService.getClickEvent().subscribe(()=>{
      // this.getAllSpares();
      this.getSparesAPI();
      this.getCart();
      this.isReplacePopup = false;
    })

    // document.addEventListener('click', this.handleOutsideClick.bind(this));
  }
  @ViewChild('ScrollTop', { static: false }) ScrollTop!: ElementRef;

  ngOnInit(): void {

    this.loading = true;
    this.aspLoginService.checkLogin();
    
    localStorage.removeItem('aspSpareAdded');
    // this.getAllSpares();
    const storedAssuredState = localStorage.getItem('isAssured');
    this.isAssured = storedAssuredState === 'true';
    // this.getSparesAPI();
    this.getCart();

    // if (this.bannerImages.length > 1) {
    //   this.startAutoScroll();
    // }

    this.startAutoScroll();
  
    this.selectedFuel=localStorage.getItem('fuelType');

    if(localStorage.getItem('newMake'))
      this.make = localStorage.getItem('newMake');
    else if(localStorage.getItem('selectedMake'))
      this.make = localStorage.getItem('selectedMake');

    if(localStorage.getItem('newModel'))
      this.model = localStorage.getItem('newModel');
    else if(localStorage.getItem('selectedModel'))
      this.model = localStorage.getItem('selectedModel');

    if(localStorage.getItem('newYear'))
      this.year = localStorage.getItem('newYear');
    else if(localStorage.getItem('selectedYear'))
      this.year = localStorage.getItem('selectedYear');

    if(localStorage.getItem('newVariant'))
      this.variant = localStorage.getItem('newVariant');
    else if(localStorage.getItem('variant'))
      this.variant = localStorage.getItem('variant');
    if (localStorage.getItem('vehicleData')) {
      const vehicleData = JSON.parse(localStorage.getItem('vehicleData'));
      this.fuel = vehicleData['fuel'];
      this.registrationNumber=vehicleData['registrationNumber'];
  }
  
  
  
    if(this.make)
      this.makeImage = 'https://cube.getpitstop.com/assets/img/'+(this.make.toLowerCase()).replace(' ','')+'.png';

    this.spareSelectedGarageName = localStorage.getItem("spareSelectedGarageName"); 

    if(localStorage.getItem('newRowKey') && localStorage.getItem('newVariantKey') && this.isCartCount==true){
      this.isReplacePopup = true;
    }

    this.getLoadingMsg()

    const savedFilterParams = localStorage.getItem('filterParams');
    const savedSelectedCategory = localStorage.getItem('selectedCategory');
    const savedSelectedBrands = localStorage.getItem('selectedBrands');
  
    if (savedFilterParams) {
      this.filterParams = JSON.parse(savedFilterParams);
    }
    if (savedSelectedCategory) {
      this.selectedCategory = JSON.parse(savedSelectedCategory);
    }
    if (savedSelectedBrands) {
      this.selectedBrands = JSON.parse(savedSelectedBrands);
    }
  
    this.getSparesAPI();
    // (document.getElementsByClassName('mat-icon notranslate toolbar-hamburger-menu material-icons mat-icon-no-color')[0] as HTMLInputElement).innerHTML = 'menu';
    // (document.getElementsByClassName('mat-icon notranslate material-icons mat-icon-no-color')[0] as HTMLInputElement).innerHTML = 'search';
    // (document.getElementsByClassName('mat-icon notranslate garage-icon-mobile-view material-icons mat-icon-no-color')[0] as HTMLInputElement).innerHTML = 'build';
    // (document.getElementsByClassName('mywalletbtn')[0] as HTMLInputElement).innerHTML = '';
    // (document.getElementsByClassName('logo-icon')[0] as HTMLInputElement).innerHTML = '';
  }

  @ViewChild('targetDiv') targetDiv!: ElementRef;
  @ViewChild('targetDiv1') targetDiv1!: ElementRef;

  @ViewChild('targetLeftMenu') targetLeftMenu!: ElementRef;
  isFixed1 = false;
  @ViewChild('sidebarEl') sidebarEl!: ElementRef;
  @ViewChild('sidebarContentEl') sidebarContentEl!: ElementRef;

  ngAfterViewInit() {
    this.sidebar = this.sidebarEl.nativeElement;
    this.sidebarContent = this.sidebarContentEl.nativeElement;
  }
  ScrollToTop() {
    this.ScrollTop.nativeElement.scrollIntoView({
      behavior: 'smooth',  
      block: 'start'});
    
  }

    onScroll() {
      if(this.isMobile){
        const divPosition = this.targetDiv.nativeElement.getBoundingClientRect().top;
        const divPosition1 = this.targetDiv1.nativeElement.getBoundingClientRect().top;

        if (divPosition <= 64) {
          this.isFixed1 = true;
        } 
        if ((divPosition > 64 && divPosition != 64) || divPosition1>-34)
        {
          this.isFixed1 = false;
        }
      }

      // this.leftMenuTop = this.targetLeftMenu.nativeElement.getBoundingClientRect().top;
      // this.leftMenuBottom = this.targetLeftMenu.nativeElement.getBoundingClientRect().bottom;
      if(!this.isMobile){
      const scrollTop = window.scrollY;
    const viewportHeight = window.innerHeight;
    const contentHeight = this.sidebarContent.getBoundingClientRect().height;
    const sidebarRect = this.sidebar.getBoundingClientRect();
    const sidebarTop = sidebarRect.top + window.scrollY;

    if (scrollTop >= contentHeight - viewportHeight + sidebarTop) {
      const translateY = contentHeight - viewportHeight + sidebarTop;
      this.sidebarContent.style.transform = `translateY(-${translateY}px)`;
      this.sidebarContent.classList.add('fixed11');
    } else {
      this.sidebarContent.style.transform = '';
      this.sidebarContent.classList.remove('fixed11');
    }
  }
  

      let selectedSparesList = {};
      if(localStorage.getItem('selectedSparesList'))
        selectedSparesList = JSON.parse(localStorage.getItem('selectedSparesList'));

      // if(selectedSparesList && selectedSparesList['type']=='categorySpares' || selectedSparesList['type']=='brandSpares'){
      
        const container = document.querySelector('.page-layout');
        if (container.scrollTop + container.clientHeight >= container.scrollHeight - 10) {
          
          this.pageIndex++ ; 
          
          if (this.pageIndex && this.totalPages && (this.pageIndex<=this.totalPages) ) {  
            
            this.getSparesAPI();
          }
        // }
      }
    }    
    @HostListener('window:scroll', []) 


  // API call for getAllSpares
  getSparesAPI(searchKey=''){
      this.loading = true;
      let rowKey;
      let variantKey;
      let garageKey = localStorage.getItem('garageKey');
      let sparePartsDetails;
      let selectedSparesList: any = localStorage.getItem('selectedSparesList') ? JSON.parse(localStorage.getItem('selectedSparesList')) : null;
      let brandKey: any;
      const plannedSku = this.isAssured ? 1 : 0;
      const sortingField = "sold_quantity";
      const sortingOption = "desc"
      

      // if (selectedSparesList && selectedSparesList.type === "allSpares" && !this.filterParams.subCategory?.length && !this.filterParams.brand?.length) {
      //   this.pageIndex = 0;  
      // }
      console.log("selectedSparesList.type",selectedSparesList.type)
      if ( selectedSparesList.type === "brandSpares" || selectedSparesList.type === "categorySpares") {
        this.pageSize = 30;  
      }
      else {
        this.pageSize = 500;
      }

      if (this.vehicleType !== 'universal') {
          rowKey = localStorage.getItem('newRowKey') || localStorage.getItem('rowKey');
          variantKey = localStorage.getItem('newVariantKey') || localStorage.getItem('variantKey');
      }

      if (selectedSparesList && selectedSparesList.type === "brandSpares" && selectedSparesList.key && selectedSparesList.key !== '') {
          brandKey = [selectedSparesList.key];
      } else {
          brandKey = this.selectedBrands;
      }

      if(localStorage.getItem('preSearchValue') && localStorage.getItem('preSearchValue')!=''){
        this.searchKey = localStorage.getItem('preSearchValue');
      }

      if (this.selectedSparesList?.type !== 'brandSpares') {
          this.getBrands();
      }
      this.getCategories();

      this.aspSparesListService.getSparesList(rowKey, variantKey, this.searchKey, garageKey, this.pageIndex, this.pageSize, this.selectedCategory, brandKey,this.isAssured,sortingField,sortingOption).subscribe(
          (result) => {
              this.loading = false;

             console.log("sparelist response", result)
              let res = JSON.parse(JSON.stringify(result));
              this.img=res["data"].brand?.imageUrl;
              console.log("sparelistresponseimg", this.img)
              this.length = res.data.totalItems;
              this.totalPages = res.data.totalPage;
              this.pageIndex = res.data.currentPage;

              if (res.code === 200) {
                console.log('isMobile:', this.isMobile); // Debugging the value
                console.log('Response Data:', res.data);
                this.bannerImages = res.data.banner ? res.data.banner.split(',') : [];
                this.bannerImages = this.isMobile 
                ? (res.data.banner ? res.data.banner.split(',') : []) 
                : (res.data.webBanner ? res.data.webBanner.split(',') : []);
                console.log('Banner Images:', this.bannerImages); // Debugging the result
                // this.bannerImages = res.data.banner ? res.data.banner.split(',') : [];

                  let isFindMyCar = localStorage.getItem("isFindMyCar");
                  if(localStorage.getItem('isDynamicPopup') && (isFindMyCar !== "1" || isFindMyCar==null)){
                      if(this.selectedSparesList.type=='brandSpares'|| this.selectedSparesList.type=='categorySpares'){
                          this.bottomSheet.open(AspDynamicPopupComponent, {
                              panelClass: 'bottom-sheet-missmach'  
                          });
                      }
                  }
                  localStorage.removeItem('isDynamicPopup');
                  localStorage.removeItem("isFindMyCar");
                  // Save to local
                  sparePartsDetails = JSON.stringify(result);
                  this.setSparePartsDetails(sparePartsDetails);

                  if(this.isAssured==true){
                    this.sparesList=[];
                  }

                  Object.values(res.data.spareStocks).forEach((stock) => {
                      this.spareStocks[stock['partNumber']] = stock;
                  });

                  if (res.data?.itemSummary?.length > 0) {
                      Object.values(res.data.itemSummary).forEach((summary) => {
                          this.itemSummary[summary['partNumber']] = summary;
                      });
                  }

                  Object.values(res.data.spares).forEach((spares) => {
                      if (this.categories.indexOf(spares['category']) === -1 && spares['brand'] !== null) {
                          this.categories.push(spares['category']);
                      }
                  });

                  let data = [];
                  let dataLength = 0;
                  Object.values(res.data.spares).forEach((spares) => {
                      if (spares['brand'] !== null && this.spareStocks[spares['partNumber']] !== undefined) {
                          spares['spareStocks'] = this.spareStocks[spares['partNumber']];

                          // if (this.itemSummary && this.itemSummary?.length > 0) {
                          //     spares['itemSummary'] = this.itemSummary[spares['partNumber']];
                          // }

                          if (this.itemSummary && Array.isArray(res.data?.itemSummary) && res.data?.itemSummary.length > 0) {
                            const summary = res.data.itemSummary.find(item => item['partNumber'] === spares['partNumber']);
                            if (summary) {
                                spares['itemSummary'] = summary;
                            }
                        }

                          if ((this.filterParams.subCategory && (this.filterParams.subCategory.length === 0 || this.filterParams.subCategory.includes(spares['subCategory']))) &&
                              (this.filterParams.brand && (this.filterParams.brand.length === 0 || this.filterParams.brand.includes(spares['brand']['name'])))) {
                              data.push(spares);
                          }
                      }

                      if(data?.length>0){
                        dataLength = data.length;
                      }
                  });

                  this.sparesList.push(data);

                  // if (selectedSparesList && selectedSparesList.type === "allSpares" && dataLength == 0 && (this.pageIndex+1 <= this.totalPages)) {
                  //     this.pageIndex = this.pageIndex+1;
                  //     this.getSparesAPI();
                  // }else{
                  //   this.loading = false;
                  // }

                  if (res?.data?.shippingCharges?.length) {
                      this.isShippingCharges = true;
                  }

                  if (res.data.prepayUpiDiscount && res.data.prepayUpiDiscount !== '' && res.data.prepayUpiDiscount !== undefined) {
                      this.prepayUpiDiscount = res.data.prepayUpiDiscount;
                  }

                  localStorage.setItem("prepayUpiDiscount", this.prepayUpiDiscount);
              }else{
                this.loading = false;
              }

          }
      );
        //   if (!this.isAssured) {
        //     this.data.sort((a, b) => a.name.localeCompare(b.name)); 
        // }

        // this.sparesList = this.data; 
    
      
  }


  changeVehicle(){    
    localStorage.removeItem('selectedSparesList');
    localStorage.removeItem('vehicleData');
    this.router.navigate(['home']);
  }

  removeItem(count, item){ 
    this.addedCart = [];    
    let data;
    
    data = JSON.parse(sessionStorage.getItem('addedCart'))[0];
    if(count>1){
      let countval = count-1;
      data[item].cart_qty = countval;

      if(this.vehicleType=='universal'){
        data[item].type = 'universal';
      }

      this.addedCart[0] = data;
      sessionStorage.setItem('addedCart', JSON.stringify(this.addedCart));
      this.getCart();
    }else{
      this.deleteItem(item);
      this.toastr.error(data[item].name+' removed from cart', 'Cart Item');
    }
  }

  deleteItem(item){
    this.newData = {};
    let cartData;

    cartData = JSON.parse(sessionStorage.getItem('addedCart'));
    Object.values(cartData[0]).forEach(cart => { 
      if(cart['key']!=item) {
        this.newData[cart['key']] = cart;
      }
    });
    
    this.addedCart[0] = this.newData;
    sessionStorage.setItem('addedCart', JSON.stringify(this.addedCart));
    this.getCart();
    // console.log(this.addedCart);
    
  }

  addItem(count, item){
    let countval = count+1;
    
    // this.addCart(item,countval);
    this.addedCart = [];
    let data;
    
    // data = JSON.parse(sessionStorage.getItem('addedCart'))[0];
    // if(countval<=data[item].spareStocks.quantity){
    //   data[item].cart_qty = countval;

    //   if(this.vehicleType=='universal'){
    //     data[item].type = 'universal';
    //   }

    //   this.addedCart[0] = data;
    //   sessionStorage.setItem('addedCart', JSON.stringify(this.addedCart));
    //   this.getCart();
    // }else{
    //   this.toastr.error('Only '+data[item].spareStocks.quantity+' unit available for this item', 'Error');
    // }
    data = JSON.parse(sessionStorage.getItem('addedCart'))[0];
    // if(countval<=data[item].spareStocks.quantity){
      data[item].cart_qty = countval;
      this.addedCart[0] = data;
      console.log("this.addedCart",this.addedCart)
      sessionStorage.setItem('addedCart', JSON.stringify(this.addedCart));
      this.getCart();
  }


  addCart(items, count){
    console.log("items",items);
    console.log("count",count);
    delete items.rowKey;
    delete items.variantKey;

    // if(this.isReplacePopup==true){
    //   sessionStorage.setItem('replaceCart', JSON.stringify(items));
    //   this.zone.run(() => {
    //     this.dialog.open(AspReplaceCartPopupComponent);
    //   });
    // }else{
    //   this.setNewMakeAsMake();
        
      this.addedCart = [];
      if(this.vehicleType=='universal'){
        items.type = 'universal';
      }
      if(sessionStorage.getItem('addedCart')){
        this.newData = JSON.parse(sessionStorage.getItem('addedCart'))[0];
        console.log("items.cart_qty",items.cart_qty);
        items.cart_qty = count;
        this.amount=items.spareStocks;
        let make = localStorage.getItem('selectedMake');
        let model = localStorage.getItem('selectedModel');
        let registrationNumber = localStorage.getItem('registrationNumber');
        let variant = localStorage.getItem('variant');
        let mfgYear = localStorage.getItem('selectedYear');
        let fuelType = localStorage.getItem('selectedFuel');
        let rowKey = localStorage.getItem('rowKey');
        let variantKey = localStorage.getItem('variantKey');
        items = {
          ...items.spareStocks, 
          ...items, 
          selectedMake: make,
          selectedModel: model,
          registrationNumber: registrationNumber,
          variant: variant,
          selectedYear: mfgYear,
          selectedFuel: fuelType, 
          rowKey: rowKey, 
          variantKey: variantKey      
        };
        items.discount = items.discountAmount;  
        delete items.discountAmount;
        this.newData[items.key] = items;
        this.addedCart.push(this.newData);
        sessionStorage.setItem('addedCart', JSON.stringify(this.addedCart));
      }else{
        items.cart_qty = count;
        // this.amount=items.spareStocks;
        let make = localStorage.getItem('selectedMake');
        let model = localStorage.getItem('selectedModel');
        let registrationNumber = localStorage.getItem('registrationNumber');
        let variant = localStorage.getItem('variant');
        let mfgYear = localStorage.getItem('selectedYear');
        let fuelType = localStorage.getItem('selectedFuel');
        let rowKey = localStorage.getItem('rowKey');
        let variantKey = localStorage.getItem('variantKey');
        items = {
          ...items.spareStocks, 
          ...items,
          selectedMake: make,
          selectedModel: model,
          registrationNumber: registrationNumber,
          variant: variant,
          selectedYear: mfgYear,
          selectedFuel: fuelType,
          rowKey: rowKey, 
          variantKey: variantKey      
        };
        items.discount = items.discountAmount;  
        delete items.discountAmount;
        this.newData[items.key] = items;
        this.addedCart.push(this.newData);
        sessionStorage.setItem('addedCart', JSON.stringify(this.addedCart));
      }      
      this.getCart();
    // }
  }
  
  getCart(){
    let cartData;  
    this.cartList = [];
    this.cartCount = 0;
    this.cartSubTotal = 0;
    this.cartGrandTotal = 0;
    
    if(sessionStorage.getItem('addedCart')){   
      cartData = JSON.parse(sessionStorage.getItem('addedCart'));
      if(Object.keys(cartData[0]).length==0){
        this.isCartCount = false;
      } 
      
      Object.values(cartData[0]).forEach(cart => { 
        if(cart){
          this.isCartCount = true;
          this.cartCount++;
        

        // this.cartCount += cart['cart_qty'];
        if(cart['sellingPrice']>0){
          this.cartSubTotal += (cart['sellingPrice']*cart['cart_qty']);
        }else{
          this.cartSubTotal += (cart['mrp']*cart['cart_qty']);
        }
        this.cartList.push(cart);
      }
      });      

      this.cartList = cartData;
      this.cartGrandTotal = this.cartSubTotal;
      // console.log(this.cartList);

      // if(this.cartCount>0){
      //   (document.getElementsByClassName('cart_count_num')[0] as HTMLInputElement).innerHTML = ''+this.cartCount+' items <div class="right-amount-carts">₹'+this.cartGrandTotal.toFixed(2)+'</div>';
      // }else{
      //   (document.getElementsByClassName('cart_count_num')[0] as HTMLInputElement).innerHTML = 'My Cart';
      // }
      if(this.sparesDiscount>0){
        this.cartGrandTotal = (this.cartSubTotal - this.sparesDiscount);
    }else{
        this.cartGrandTotal = this.cartSubTotal;
    }
    this.getShippingCharges()
  
    }
    console.log("cartList",this.cartList)
  }

  goToCart(){
    this.router.navigate(['cart']);
  }

  // searchFilterType(){
  //   this.openSearch = false;
  //   this.clearSearch = true;
  //   let searchText = (document.getElementsByName("searchText")[0]  as HTMLInputElement).value;
  //   if(searchText.length>0 && searchText.length<=2){
  //     this.getAllSpares();
  //   }

  //   if(searchText.length==0){
  //     this.getAllSpares();
  //   }
  // }

  searchFilterBrand() {
    
    const searchBrand = (document.getElementsByName("searchBrand")[0] as HTMLInputElement).value;
    // console.log(searchBrand);

  
    // if (searchBrand.length != 0 && searchBrand.length >= 3) {
      this.filterBrand(searchBrand);
    // }
  }

  filterBrand(searchBrand){
    if(searchBrand){
      this.filters.brands = this.allBrands.filter(brand =>
        brand.label.toLowerCase().includes(searchBrand.toLowerCase())
      );
    }else{
      this.filters.brands = this.allBrands;
    }
  }

  // clearSearchFilter(){
  //   this.openSearch = true;
  //   this.clearSearch = false;
  //   this.searchText = '';
  //   this.getAllSpares();
  //   // this.control.reset();
  // }


  setSparePartsDetails(data){
    sessionStorage.setItem('sparePartsDetails', data); 
  }

  // shippingChargesBottomSheet(){
  //   this._bottomSheet.open(AspShippingChargesBottomSheetComponent);
  // }

  setNewMakeAsMake(){
    if(localStorage.getItem('newMake') && localStorage.getItem('newModel') && localStorage.getItem('newYear') && localStorage.getItem('newVariant')){
      localStorage.setItem('selectedMake', localStorage.getItem('newMake'));
      localStorage.setItem('selectedModel', localStorage.getItem('newModel'));
      localStorage.setItem('selectedYear', localStorage.getItem('newYear'));
      localStorage.setItem('variant', localStorage.getItem('newVariant'));
      localStorage.setItem('variantKey', localStorage.getItem('newVariantKey'));
      localStorage.setItem('rowKey', localStorage.getItem('newRowKey'));

      localStorage.removeItem('newMake');
      localStorage.removeItem('newModel');
      localStorage.removeItem('newYear');
      localStorage.removeItem('newVariant');
      localStorage.removeItem('newVariantKey');
      localStorage.removeItem('newRowKey');   
    } 
  }

  shippingChargesPopup(){
    this.zone.run(() => {
      this.dialog.open(AspShippingChargesPopupComponent);
    });
  }

  // Filters

  getBrands() {
    // this.loading = true;
    this.allBrands = [];
    let rowKey = localStorage.getItem('rowKey');
    let variantKey = localStorage.getItem('variantKey');

    this.aspSparesListService.getBrands(this.selectedCategory, rowKey, variantKey).subscribe(
      response => {
        if(response['code']==200){
          // this.loading = false;
          this.filters.brands = [];
          Object.entries(response['data']['sparePartBrandProjections']).forEach(([key, brand]) => {
            let checkedVal;
            this.filterParams.brand.includes(brand['name']) ? checkedVal=true : checkedVal=false;
            this.filters.brands.push({ label: brand['name'], value: brand['brandKey'], checked: checkedVal });
          });
          this.filters.brands.sort((a, b) => a.label.localeCompare(b.label));
          this.allBrands = this.filters.brands;
        }
       },
      error => {
        console.error('Error fetching brands', error);
      }
    );
  }
  
  getCategories() {
    // this.loading = true;
    let rowKey = localStorage.getItem('rowKey');
    let variantKey = localStorage.getItem('variantKey');
    let selectedSparesList= JSON.parse(localStorage.getItem('selectedSparesList'))
    // console.log(selectedSparesList.key)
    let brandKey:any;
    if (selectedSparesList?.type=="brandSpares" && selectedSparesList.key && selectedSparesList.key!='' && selectedSparesList.key!=undefined )
    {
      brandKey = [selectedSparesList.key];
    }
    else{
     brandKey = this.selectedBrands; 
    }

    this.aspSparesListService.getCategories(brandKey, rowKey, variantKey).subscribe(
      response => {
        if (response['code'] === 200) {
          // this.loading = false;
          this.filters.category = [];
    
          let catSubCat = response['data']['sparePartCategoryProjectionWithCount'];
          Object.entries(catSubCat).forEach(([key, cat]) => {
            
            if(selectedSparesList.type!="categorySpares"){     
              let catActive = false;
              let subCatData = []; 

              Object.entries(cat).forEach(([key1, subcat]) => {
                // Determine if the subcategory should be checked
                let checkedVal = this.filterParams.subCategory.includes(subcat['subCategory']) ? true : false;
      
                // If a subcategory is checked, mark the category as active
                if (checkedVal) {
                  catActive = true;
                }
      
                // Add the subcategory data
                subCatData.push({
                  label: subcat['subCategory'],
                  value: subcat['subCategory'],
                  checked: checkedVal
                });
              });

              this.filters.category.push({
                label: key,
                value: key,
                checked: catActive,
                children: subCatData
              });
            }else{
              if(selectedSparesList.name==key){
                let catActive = false;
                let subCatData = []; 

                Object.entries(cat).forEach(([key1, subcat]) => {
                  // Determine if the subcategory should be checked
                  let checkedVal = this.filterParams.subCategory.includes(subcat['subCategory']) ? true : false;
        
                  // If a subcategory is checked, mark the category as active
                  if (checkedVal) {
                    catActive = true;
                  }
        
                  // Add the subcategory data
                  subCatData.push({
                    label: subcat['subCategory'],
                    value: subcat['subCategory'],
                    checked: checkedVal
                  });
                });

                this.filters.category.push({
                  label: key,
                  value: key,
                  checked: catActive,
                  children: subCatData
                });
              }
            }         
                 
            // console.log(this.filters.category);
          })

        }
      },
      error => {
        console.error('Error fetching categories', error);
      }
    
    );
  }
  

  isSelectAllChecked(category: string, isChecked: boolean): void {
    this.loading = true;
    this.filters.category.forEach(item => {
      if (item.label === category) {
        let subCat = [];
        
        // Iterate through each subcategory (children) to update their state
        item.children?.forEach(subcat => {
          // Set the checked state for each subcategory based on the "Select All" state
          subcat.checked = isChecked;
          // console.log('subcat.checked--',subcat.checked)
          
          if (isChecked) {
            // If 'Select All' is checked, add all subcategories
            if (this.selectedCategory[category]) {
              if (!this.selectedCategory[category].includes(subcat['label'])) {
                this.selectedCategory[category].push(subcat['label']);
              }
            } else {

              subCat.push(subcat['label']);
              this.selectedCategory[category] = subCat;
             
            }
            if (!this.filterParams.subCategory.includes(subcat['label'])) {
              this.filterParams.subCategory.push(subcat['label']);
            }
          } else {
            
            const index = this.filterParams.subCategory.indexOf(subcat['label']);
            subcat.checked = false;
            if (index !== -1) {
              this.filterParams.subCategory.splice(index, 1);
              
            }
  
            if (this.selectedCategory[category]) {
              const index1 = this.selectedCategory[category].indexOf(subcat['label']);
              if (index1 !== -1) {
                this.selectedCategory[category].splice(index1, 1);
              }
  
              // Remove the category if no subcategories remain
              if (this.selectedCategory[category].length === 0) {
                delete this.selectedCategory[category];
              }
            }

            
            this.selectedCategory1 = null;
            this.isFixed = false;
            // console.log("selectedCategory1",this.selectedCategory1);
          }
        });
      }
    });
  
    this.sparesList = [];
    this.getSparesAPI();
  }
  

  // toggleSelection(categoryKey: keyof Filters, options: FilterOption, subcat = '') {

  //   this.sparesList = [];
  //   this.loading = true;

  //   if (categoryKey === 'brands') {
  //     // this.loading = true;
  //     // Brand filter logic
  //     if (options.checked) {
  //       if (!this.filterParams.brand.includes(options.value)) {
  //         this.selectedBrands.push(options.value);
  //         this.filterParams.brand.push(options.label);
  //       }
  //     } else {
  //       const index = this.filterParams.brand.indexOf(options.label);
  //       if (index !== -1) {
  //         this.filterParams.brand.splice(index, 1);
  //       }

  //       const index1 = this.selectedBrands.indexOf(options.value);
  //       if (index1 !== -1) {
  //         this.selectedBrands.splice(index1, 1);
  //       }
  //     }
  //   } else if (categoryKey === 'category') {
  //     // this.loading = true;
  //     // Category filter logic
  //     let subCat = [];
  //     let option: any = options;

  //     if (subcat['checked']) {
  //       // If subcategory is checked, add to filter
  //       if (this.selectedCategory[option]) {
  //         this.selectedCategory[option].push(subcat['label']);
  //       } else {
  //         subCat.push(subcat['label']);
  //         this.selectedCategory[option] = subCat;
  //       }
  //       this.filterParams.subCategory.push(subcat['label']);
  //     } else {
  //       // If subcategory is unchecked, remove from filter
  //       const index = this.filterParams.subCategory.indexOf(subcat['label']);
  //       if (index !== -1) {
  //         this.filterParams.subCategory.splice(index, 1);
  //       }

  //       if (this.selectedCategory[option]) {
  //         const index1 = this.selectedCategory[option].indexOf(subcat['label']);
  //         if (index1 !== -1) {
  //           this.selectedCategory[option].splice(index1, 1);
  //         }
  //       }
  //     }
  //   }

  //   // Update selectedCategory by removing any empty arrays
  //   this.selectedCategory = Object.keys(this.selectedCategory).reduce((acc, key) => {
  //     if (this.selectedCategory[key].length > 0) {
  //       acc[key] = this.selectedCategory[key];
  //     }
  //     return acc;
  //   }, {});


  //   let selectedSparesList = {};
  //   if(localStorage.getItem('selectedSparesList'))
  //     selectedSparesList = JSON.parse(localStorage.getItem('selectedSparesList'));

  //   this.getSparesAPI();  
  // }
  toggleSelection(categoryKey: keyof Filters, options: FilterOption, subcat = '') {
    this.pageIndex = 0;
    this.sparesList = [];
    this.loading = true;

    if (categoryKey === 'brands') {
      // Brand filter logic
      if (options.checked) {
        if (!this.filterParams.brand.includes(options.value)) {
          this.selectedBrands.push(options.value);
          this.filterParams.brand.push(options.label);
        }
      } else {
        const index = this.filterParams.brand.indexOf(options.label);
        if (index !== -1) {
          this.filterParams.brand.splice(index, 1);
        }

        const index1 = this.selectedBrands.indexOf(options.value);
        if (index1 !== -1) {
          this.selectedBrands.splice(index1, 1);
        }
      }
    } else if (categoryKey === 'category') {
      // Category filter logic
      let subCat = [];
      let option: any = options;

      if (subcat['checked']) {
        // If subcategory is checked, add to filter
        if (this.selectedCategory[option]) {
          this.selectedCategory[option].push(subcat['label']);
        } else {
          subCat.push(subcat['label']);
          this.selectedCategory[option] = subCat;
        }
        this.filterParams.subCategory.push(subcat['label']);
      } else {
        // If subcategory is unchecked, remove from filter
        const index = this.filterParams.subCategory.indexOf(subcat['label']);
        if (index !== -1) {
          this.filterParams.subCategory.splice(index, 1);
        }

        if (this.selectedCategory[option]) {
          const index1 = this.selectedCategory[option].indexOf(subcat['label']);
          if (index1 !== -1) {
            this.selectedCategory[option].splice(index1, 1);
          }
        }
      }
    }

    // Update selectedCategory by removing any empty arrays
    this.selectedCategory = Object.keys(this.selectedCategory).reduce((acc, key) => {
      if (this.selectedCategory[key].length > 0) {
        acc[key] = this.selectedCategory[key];
      }
      return acc;
    }, {});

    // Save the current filter state to localStorage
    localStorage.setItem('filterParams', JSON.stringify(this.filterParams));
    localStorage.setItem('selectedCategory', JSON.stringify(this.selectedCategory));
    localStorage.setItem('selectedBrands', JSON.stringify(this.selectedBrands));

    this.getSparesAPI();
    this.ScrollToTop();
}


 
  clearFilters() {
    this.pageIndex = 0;
    this.loading = true;
    // Clear the checked status of all filter options
    const clearOptions = (options: FilterOption[]) => {
      options.forEach(option => {
        option.checked = false;
        if (option.children) {
          clearOptions(option.children); // Recursively clear child filters if any
        }
      });
    };

    // Clear all filters: brands and categories
    (Object.keys(this.filters) as (keyof Filters)[]).forEach(categoryKey => {
      clearOptions(this.filters[categoryKey]);
    });
    this.filterParams.brand = [];
    this.filterParams.subCategory = [];
    this.selectedCategory = {};
    this.selectedBrands = [];
    this.sparesList = [];
    // this.getAllSpares();
    localStorage.removeItem('filterParams');
    localStorage.removeItem('selectedCategory');
    localStorage.removeItem('selectedBrands');

    this.getSparesAPI();
  }


  hasAppliedFilters(): boolean {
    return (Object.keys(this.filters) as (keyof Filters)[]).some(categoryKey =>
      this.filters[categoryKey].some(option => {
        if (option.checked) {
          return true;
        }
        if (option.children) {
          return option.children.some(child => child.checked);
        }
        return false;
      })
    );
  }

  getAppliedFilters(): { category: keyof Filters, option: FilterOption }[] {
    const appliedFilters: { category: keyof Filters, option: FilterOption }[] = [];

    const addFilter = (categoryKey: keyof Filters, option: FilterOption) => {
      if (option.checked) {
        appliedFilters.push({ category: categoryKey, option });
      }
    };

    (Object.keys(this.filters) as (keyof Filters)[]).forEach(categoryKey => {
      this.filters[categoryKey].forEach(option => {
        addFilter(categoryKey, option);
        if (option.children) {
          option.children.forEach(child => {
            addFilter(categoryKey, child);
          });
        }
      });
    });

    return appliedFilters;
  }

  removeFilter(categoryKey: keyof Filters, option: FilterOption) {
    option.checked = false;

    if(categoryKey=='brands'){
      const index = this.filterParams.brand.indexOf(option.label);
      if (index !== -1) {
        this.filterParams.brand.splice(index, 1);
      }
    }

    if(categoryKey=='category'){
      const index = this.filterParams.subCategory.indexOf(option.label);
      if (index !== -1) {
        this.filterParams.subCategory.splice(index, 1);
      }

      if(this.selectedCategory[option.label]){
        const index1 = this.selectedCategory.indexOf(option.label)
        if (index1 !== -1) {
          this.selectedCategory.splice(index, 1);
        }  
      }

      Object.keys(this.selectedCategory).forEach(key => {
        this.selectedCategory[key] = this.selectedCategory[key].filter(item => item !== option.label);
      });
    }

    this.selectedCategory = Object.keys(this.selectedCategory).reduce((acc, key) => {
      if (this.selectedCategory[key].length > 0) {
        acc[key] = this.selectedCategory[key];
      }
      return acc;
    }, {});  

    // this.getAllSpares();
    this.getSparesAPI();
  }

  areAllSelected(children: FilterOption[]): boolean {
    return children.every(child => child.checked);
  }
  // End Filters


  calculateDiscount(mrp: number, sellingPrice: number): number {
    const discount = ((mrp - sellingPrice) / mrp) * 100;
    return Math.round(discount); // Rounded to the nearest whole number
  }

  selectCategory(category) {
    if (this.selectedCategory1 === category) {
      this.selectedCategory1 = null;
      this.isFixed = false;
    } else {
      this.selectedCategory1 = category;      
      this.isFixed = true;
    }
  }

  closeFilters(){
    this.selectedCategory1 = null;
    this.isFixed = false;
  }
  onAssuredCheckboxChange(event: MatCheckboxChange): void {
    this.isAssured = event.checked;
    this.sparesList = [];        
    this.pageIndex = 0;
    // this.getAllSpares();
    this.getSparesAPI();
  }
  options: AnimationOptions = {
    path: './assets/images/loader.json' // Replace with your JSON animation file URL
  };
  
  animationCreated(animationItem: any): void {
    // Animation is created and can be interacted with here
  }

  getLoadingMsg() {
    this.aspSparesListService.getLoadingMsg().subscribe(
      result => {

        let res = JSON.parse(JSON.stringify(result));
        this.Messages = res.data.appLoadingMessages;
  
        // Call getRandomMessage only after the Messages array is populated
        this.getRandomMessage();
        this.cdr.detectChanges();
      },
      error => {
        console.error('Error fetching loading messages', error);
      }
    );
  }
  
  getRandomMessage() {
    if (this.Messages && this.Messages.length > 0) {
      const randomIndex = Math.floor(Math.random() * this.Messages.length);
      this.randomMessage = this.Messages[randomIndex];
    } else {
      console.error('No messages available to pick from');
    }
  }

  
  mobileShowFilters(){
    this.isFilterShow = 'left-filters-show';
  }

  closeFilter(){
    this.isFilterShow = 'left-filters-hide';
  }


  // ngAfterViewInit() {
  //   // Wrap in setTimeout to ensure it triggers after view load
  //   setTimeout(() => {
  //     if (this.bannerImages.length > 1) {
  //       this.startAutoScroll();
  //     }
  //   }, 0);

  //   // Trigger change detection to ensure the initial render completes
  //   this.cdr.detectChanges();
  // }

  // ngOnDestroy() {
  //   this.stopAutoScroll();
  // }


  currentIndex = 0;
  autoScrollInterval: any;

  scrollLeft() {
      if (this.currentIndex > 0) {
          this.currentIndex--;
      }
      // this.updateCarousel();
  }

  
  scrollRight() {
    this.currentIndex = (this.currentIndex + 1) % this.bannerImages.length; 
    // this.updateCarousel(); 
  }

// updateCarousel() {
//   const track = document.querySelector('.carousel-track') as HTMLElement;
//   const imageWidth = track.firstElementChild ? (track.firstElementChild as HTMLElement).offsetWidth : 0;
//   track.style.transform = `translateX(-${this.currentIndex * imageWidth}px)`;
// }
startAutoScroll() {
  this.autoScrollInterval = setInterval(() => {
    this.scrollRight(); 
  }, 7000); 
}


onMouseEnter() {
  this.stopAutoScroll();
}

onMouseLeave() {
  this.startAutoScroll();
}
getShippingCharges(){    
  // this.loading = true; 
  let garageKey = localStorage.getItem('garageKey');
  this.aspCartService.getShippingCharges(this.cartGrandTotal, garageKey).subscribe(
    result => {
      let res = JSON.parse(JSON.stringify(result)); 
      if(res.code==200){
        // this.shippingChargesApplicable = res.data.shippingChargesApplicable;
        this.shippingCharges = res.data.shippingCharges;
        // this.avoidShippingCharges = res.data.avoidShippingCharges;
        this.grandAmount= res.data.cartValue;
        // this.loading = false;
          this.cartGrandTotal = this.grandAmount+this.shippingCharges;

          if(!this.isMobile){
            if(this.cartCount>0){
              (document.getElementsByClassName('cart_count_num')[0] as HTMLInputElement).innerHTML = ''+this.cartCount+' items <div class="right-amount-carts">₹'+this.cartGrandTotal.toFixed(2)+'</div>';
            }else{
              (document.getElementsByClassName('cart_count_num')[0] as HTMLInputElement).innerHTML = 'My Cart';
            }
          }else{
            if(this.cartCount>0){
              (document.getElementsByClassName('cart_count_mobile')[0] as HTMLInputElement).innerHTML = '<div class="cart_count">'+this.cartCount+'</div>';
            }else{
              (document.getElementsByClassName('cart_count_mobile')[0] as HTMLInputElement).innerHTML = '';
            }
          }            
        
      } else {
        if(!this.isMobile){
          if(this.cartCount>0){
            (document.getElementsByClassName('cart_count_num')[0] as HTMLInputElement).innerHTML = ''+this.cartCount+' items <div class="right-amount-carts">₹'+this.cartGrandTotal.toFixed(2)+'</div>';
          }else{
            (document.getElementsByClassName('cart_count_num')[0] as HTMLInputElement).innerHTML = 'My Cart';
          }
        }else{
          if(this.cartCount>0){
            (document.getElementsByClassName('cart_count_mobile')[0] as HTMLInputElement).innerHTML = '<div class="cart_count">'+this.cartCount+'</div>';
          }else{
            (document.getElementsByClassName('cart_count_mobile')[0] as HTMLInputElement).innerHTML = '';
          }
        }
      }
    }
  );
}

}

