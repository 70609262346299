import { Component, ComponentFactoryResolver, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { AspMyJobCardsService } from './asp-my-job-cards.service';
import { HttpErrorResponse } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { SideBarJobcardComponent } from '../side-bar-jobcard/side-bar-jobcard.component';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { AspVehicleServiceHistoryComponent } from '../asp-vehicle-service-history/asp-vehicle-service-history.component';
import { Console } from 'console';

interface JobCard {
  id: number;
  bookingId: number;
  phoneNumber: string;
  name: string;
  odometer: number;
  rowKey: string;
  variantKey: string;
  make: string;
  model: string;
  carSize: string;
  variant: string;
  fuelType: string;
  registrationNumber: string;
  createdAt: any;
  requestId: number;
  
}

interface RequestData {
  totalPages: number;
  currentPage: number;
  totalItems: number;
  jobCards: JobCard[];
}

interface FilterOption {
  label: string;
  value: string;
  checked: boolean;
  children?: FilterOption[];
}

interface Filters {
  jobCardStatus: FilterOption[];
  jobTime: FilterOption[];
}

@Component({
  selector: 'app-asp-my-job-cards',
  templateUrl: './asp-my-job-cards.component.html',
  styleUrls: ['./asp-my-job-cards.component.scss']
})
export class AspMyJobCardsComponent implements OnInit, OnDestroy {
  loading: boolean = false;
  request: RequestData | null = { totalPages: 0, currentPage: 0, totalItems: 0, jobCards: [] }; // Default initialization
  formSubmitted: boolean = false;
  isSidebarOpen: boolean = false;
  customerName: string = '';
  customerMobile: string = '';
  isPopupOpen: boolean = false;
  successIcon: string = '../../../../assets/icons/icons8-ok.gif';
  currentText: string = "";
  searchOptions = ["Search By 'Job Card ID'", "Search By 'Name'", "Search By 'Mobile Number'"];
  currentIndex: number = 0;
  textInterval: any;
  searchText: string = '';
  isConfirmationSidebarOpen: boolean = false;
  make: any = '';
  filteredJobCards: JobCard[] = [];
  bookingId: any;
  search: any;
  isVisible:any;
  isMobile: boolean;
  selectedJobCardStatus: string = '';  
  selectedJobCardTime: string = '';
  emptyList: any = 'assets/images/no-cart.png';

  statusList = [
    { label: 'Quote Due', value: '1', checked: false, statusclass: 'quote-due'},
    { label: 'Approval Due', value: '2', checked: false, statusclass: 'approval-due'},
    { label: 'In Progress', value: '3', checked: false, statusclass: 'inprogress'},
    { label: 'Ready', value: '4', checked: false, statusclass: 'ready'},
    { label: 'Invoice', value: '5', checked: false, statusclass: 'invoice'},
  ];
  
  filters: Filters = {
    jobCardStatus: this.statusList,
    jobTime: [
      { label: 'Today', value: 'TODAY', checked: false },
      { label: 'Yesterday', value: 'YESTERDAY', checked: false },
      { label: 'Last 7 days', value: 'LAST_7_DAYS', checked: false },
      { label: 'Last 30 days', value: 'LAST_30_DAYS', checked: false },
      { label: 'This month', value: 'THIS_MONTH', checked: false },
      { label: 'Last Month', value: 'LAST_MONTH', checked: false },
      { label: 'Any Time', value: 'ANY_TIME', checked: false }
    ]
  };
  curRegistrationNumber: any;

  constructor(
    private aspMyJobCardsService: AspMyJobCardsService,
    private resolver: ComponentFactoryResolver,
    private breakpointObserver: BreakpointObserver,
    private bottomSheet: MatBottomSheet,
  ) {
      this.breakpointObserver.observe([Breakpoints.Handset])
    .subscribe(result => {
      this.isMobile = result.matches;
    }); }

  ngOnInit(): void {
    this.fetchJobCards();
    this.scrollText();
    this.successIcon = '../../../../assets/icons/icons8-ok.gif';
    this.fetchJobCards();
    
    if(localStorage.getItem('jobcard')  && localStorage.getItem('jobcard') == '1'){
      this.loadorderdetails();
    }
  }

  ngOnDestroy() {
    clearInterval(this.textInterval);
  }

  scrollText() {
    this.textInterval = setInterval(() => {
      this.currentText = this.searchOptions[this.currentIndex];
      this.currentIndex = (this.currentIndex + 1) % this.searchOptions.length;
    }, 2000);
  }

  onInputChange(): void {
    if (this.searchText.length === 0) {
      this.scrollText();
    } else {
      clearInterval(this.textInterval);
    }
    this.filterRequest();
  }

  clearSearch(): void {
    this.searchText = '';
    this.scrollText();
    this.filteredJobCards = this.request?.jobCards || [];
  }

  toggleSidebar(): void {
    this.isSidebarOpen = !this.isSidebarOpen;
    if (this.isSidebarOpen) {
      this.isConfirmationSidebarOpen = false;
    }
  }

  fetchJobCards(): void {
    this.loading = true; // Set loading to true when fetching data
  
    const garageKey = localStorage.getItem('garageKey'); // Get garageKey from localStorage
   
  
    this.aspMyJobCardsService.getMyJobCards(garageKey, this.bookingId, this.search).subscribe({
      next: (response: any) => {
        if (response && response.code === 200) {
          this.request = response.data;
          
          if (this.request.jobCards) {
            this.request.jobCards.sort((a: JobCard, b: JobCard) => {
              return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
            });
            this.filteredJobCards = this.request.jobCards;

          }
        } else {
          console.error('Unexpected response code:', response.code);
        }
        this.loading = false; // Stop loading after data is fetched
      },
      error: (error: HttpErrorResponse) => {
        console.error('API error:', error);
        this.loading = false; // Stop loading on error
      }
    });
  }
  
  onSubmit(form:NgForm): void {
    this.formSubmitted = true;

    if (form.valid) {
      this.toggleSidebar();
      this.formSubmitted = false;
    } else {
      form.controls['odometer'].markAsTouched();
      form.controls['car-number'].markAsTouched();
    }
  }

  openPopup(): void {
    this.formSubmitted = true;
    if (this.customerName && this.customerMobile) {
      this.isPopupOpen = true;
    }
  }

  closePopup(): void {
    this.isPopupOpen = false;
  }

  toggleConfirmationSidebar(): void {
    this.isConfirmationSidebarOpen = !this.isConfirmationSidebarOpen;
    if (this.isConfirmationSidebarOpen) {
      this.isSidebarOpen = false; // Close other sidebars if needed
    }
  }

  confirmCarDetails(): void {
    if (this.isDataValid()) {
      // this.toggleConfirmationSidebar();
      this.isPopupOpen = true;
    }
  }
  isDataValid(): boolean {
  return this.request?.jobCards?.some(jobCard => jobCard.make && jobCard.model && jobCard.registrationNumber);
}

  shareOnWhatsApp(): void {
    if (this.request && this.request.jobCards && this.request.jobCards.length > 0) {
      const jobCard = this.request.jobCards[0];
      const message = `
        👋 Hello ${jobCard.name},
        
        🛠️ Your job card has been created successfully.
        
        📋 Job Card ID: ${jobCard.bookingId}
        🚗 Odometer: ${jobCard.odometer}  
        📝 Registration Number: ${jobCard.registrationNumber}
        🏷️ Make: ${jobCard.make}  
        🚙 Model: ${jobCard.model}  
        📅 Variant: ${jobCard.variant}  
        📆 MFG Year: ${jobCard.createdAt} 
        ⛽ Fuel: ${jobCard.fuelType} 
        🔑 VIN: ${jobCard.rowKey}  
        
        We will soon send you a quotation for approval.
        
        Best regards,
        AUTOVERSE GARAGE - DEMO
      `;
      const url = `https://web.whatsapp.com/send?text=${encodeURIComponent(message)}`;
      window.open(url, '_blank');
      alert('Your job card details have been shared on WhatsApp.');
    } else {
      alert('No job card data available to share.');
    }
  }
  getMakeImageUrl(make: string): string {
    const makeWithoutSpaces = make.toLowerCase().replace(/\s+/g, '');
    return `https://cube.getpitstop.com/assets/img/${makeWithoutSpaces}.png`;
  }
  filterRequest() {
    console.log("Status filter:",this.selectedJobCardStatus);
    console.log("List:",this.request.jobCards);
    this.filteredJobCards = this.request.jobCards.filter(jobCard => {
      let status= jobCard['jobcardStatus']?.toString();
      // let statusMatch = true; 
      const statusMatch = this.selectedJobCardStatus ? status === this.selectedJobCardStatus : true;
      const timeMatch = this.selectedJobCardTime ? this.filterByTime(jobCard.createdAt, this.selectedJobCardTime) : true;


      const searchValue = this.searchText.trim().toLowerCase();
      const searchMatch = searchValue ? 
        (
          jobCard.id.toString().includes(searchValue) || 
          jobCard.name.toLowerCase().includes(searchValue) || 
          jobCard.bookingId.toString().includes(searchValue) || 
          jobCard.phoneNumber.includes(searchValue) ||
          jobCard.make.toLowerCase().includes(searchValue) ||
          jobCard.model.toLowerCase().includes(searchValue) ||
          jobCard.registrationNumber.toLowerCase().includes(searchValue)
        ) 
        : true;
  
      return statusMatch && timeMatch && searchMatch;
    });

    // console.log("Filtered Data:",this.filteredJobCards);
  }
  

  onEnterPress(event: any): void {
    this.filterRequest();

  }


  
     // Reference to the button using ViewChild
  @ViewChild('myButton') myButton!: ElementRef<HTMLButtonElement>;
  @ViewChild('dynamicContainer', { read: ViewContainerRef }) container!: ViewContainerRef;
  @ViewChild('bottomSheetTemplate') bottomSheetTemplate!: TemplateRef<any>;
  @ViewChild('vehicleServiceHistoryContainer', { read: ViewContainerRef }) historyContainer!: ViewContainerRef;

  // constructor(private resolver: ComponentFactoryResolver) {}

  ngAfterViewInit(): void {
    if(localStorage.getItem('jobcard') == '1'){
      this.loadorderdetails();
    }
  }

  onCreateJobCardClick(): void {
    // console.log('Create Job Card button clicked!');
    // console.log(localStorage.getItem('jobcard'));
    this.loadorderdetails();
 
  }

 
  loadorderdetails(): void {
    // console.log('Job card details loaded and sidebar opened.');
    this.container.clear();
    
    // Dynamically create the component
    const factory = this.resolver.resolveComponentFactory(SideBarJobcardComponent);
    const componentRef = this.container.createComponent(factory);
    
    // Access the component instance
    const componentInstance = componentRef.instance as SideBarJobcardComponent;
  
    // Toggle the form sidebar (instead of the confirmation sidebar)
    if(localStorage.getItem('jobcard')  && localStorage.getItem('jobcard') == '1'){
      // this.loadorderdetails();

    componentInstance.isVisible = false;  // Open form sidebar instead of confirmation sidebar
    componentInstance.isConfirmationSidebarOpen = true; // Ensure confirmation sidebar is closed
  }
  else{
    componentInstance.isVisible = true;  // Open form sidebar instead of confirmation sidebar
    componentInstance.isConfirmationSidebarOpen = false;
  }
    // console.log('Form Sidebar component is opened.');
  }


  // Filters
  toggleSelection(filterType: keyof Filters, option: FilterOption) {
    if (filterType === 'jobCardStatus') {
        // Update request status filters
        this.filters.jobCardStatus.forEach(status => {
            status.checked = (status.value === option.value);
        });
        this.selectedJobCardStatus = option.value;
    } else if (filterType === 'jobTime') {
        // Update request time filters
        this.filters.jobTime.forEach(time => {
            time.checked = (time.value === option.value);
            if (time.children) {
                time.children.forEach(child => {
                    child.checked = (child.value === option.value);
                });
            }
        });
        this.selectedJobCardTime = option.value;
    }

    // Save selected request status and request time to localStorage
    localStorage.setItem('selectedJobCardStatus', this.selectedJobCardStatus);
    localStorage.setItem('selectedJobCardTime', this.selectedJobCardTime);

    this.filterRequest();
  }


  clearFilters() {
    // Reset selected filter values
    this.selectedJobCardStatus = '';
    this.selectedJobCardTime = '';

    // Function to uncheck all options recursively
    const clearOptions = (options: FilterOption[]) => {
        options.forEach(option => {
            option.checked = false;
            if (option.children) {
                clearOptions(option.children);
            }
        });
    };

    // Clear all filter options in filters object
    (Object.keys(this.filters) as (keyof Filters)[]).forEach(jobTimeKey => {
        clearOptions(this.filters[jobTimeKey]);
    });

    // Clear filters in localStorage
    localStorage.removeItem('selectedJobCardStatus');
    localStorage.removeItem('selectedJobCardTime');
    this.searchText = '';

    // Call filterRequest to update the displayed data
    this.filterRequest();
  }


  hasAppliedFilters(): boolean {
    return this.selectedJobCardStatus !== '' || this.selectedJobCardTime !== '';
  }

  getAppliedFilters(): { jobTime: keyof Filters, option: FilterOption }[] {
    const appliedFilters: { jobTime: keyof Filters, option: FilterOption }[] = [];

    const addFilter = (jobTimeKey: keyof Filters, option: FilterOption) => {
      if (option.checked) {
        appliedFilters.push({ jobTime: jobTimeKey, option });
      }
    };

    (Object.keys(this.filters) as (keyof Filters)[]).forEach(jobTimeKey => {
      this.filters[jobTimeKey].forEach(option => {
        addFilter(jobTimeKey, option);
        if (option.children) {
          option.children.forEach(child => {
            addFilter(jobTimeKey, child);
          });
        }
      });
    });

    return appliedFilters;
  }

  removeFilter(jobTimeKey: keyof Filters, option: FilterOption) {
    option.checked = false;
    if (jobTimeKey === 'jobCardStatus') {
      this.selectedJobCardStatus = '';
    } else if (jobTimeKey === 'jobTime') {
      this.selectedJobCardTime = '';
    }
    this.filterRequest();
  }
  // End Filters


  openBottomSheet(jobCard): void {
    localStorage.setItem('selectedMake', jobCard.make)
    localStorage.setItem('selectedModel', jobCard.model)
    localStorage.setItem('fuelType', jobCard.fuelType)
    localStorage.setItem('registrationNumber', jobCard.registrationNumber)
    localStorage.setItem('variant', jobCard.variant)
    this.curRegistrationNumber = jobCard.registrationNumber;

    this.bottomSheet.open(this.bottomSheetTemplate,{
     panelClass: 'custom-bottom-sheet-links'
    }
    );
  }

  closeBottomSheet(): void {
    if (this.bottomSheet) {
      this.bottomSheet.dismiss();
    }
  }

    
  filterByTime(jobcardTime: string, filter: string): boolean {
    const now = new Date();
    const jobDate = new Date(jobcardTime);

    switch (filter) {
      case 'TODAY':
        // Check if the jobDate is the same day as today
        return jobDate.toDateString() === now.toDateString();
        
      case 'YESTERDAY':
        // Check if the jobDate is the same day as yesterday
        const yesterday = new Date(now);
        yesterday.setDate(now.getDate() - 1);
        return jobDate.toDateString() === yesterday.toDateString();
        
      case 'LAST_7_DAYS':
        // Check if the jobDate is within the last 7 days
        const weekAgo = new Date(now);
        weekAgo.setDate(now.getDate() - 7);
        return jobDate >= weekAgo && jobDate <= now;
        
      case 'LAST_30_DAYS':
        // Check if the jobDate is within the last 30 days
        const monthAgo = new Date(now);
        monthAgo.setDate(now.getDate() - 30);
        return jobDate >= monthAgo && jobDate <= now;
        
      case 'LAST_MONTH':
        // Check if the jobDate is within the last month
        const lastMonthStart = new Date(now.getFullYear(), now.getMonth() - 1, 1);
        const lastMonthEnd = new Date(now.getFullYear(), now.getMonth(), 0);
        return jobDate >= lastMonthStart && jobDate <= lastMonthEnd;
        
      case 'ANY_TIME':
      default:
        return true;
    }
  }

  openServiceHistory(): void {
    this.closeBottomSheet();

    // Dynamically create the component
    const factory = this.resolver.resolveComponentFactory(AspVehicleServiceHistoryComponent);
    const componentRef = this.historyContainer.createComponent(factory);
  
    // Access the instance and call the closeSidebar or similar method
    const serviceHistoryInstance = componentRef.instance as AspVehicleServiceHistoryComponent;
    serviceHistoryInstance.openSidebar(true);    
  }
  
}
  