 <div class="overlay" [class.visible]="isSidebarOpen" (click)="closeSidebar()" ></div> 
 
<div class="history-sidebar" *ngIf="isSidebarOpen" >
    <div class="container">
        <div class="history-backwodkey" (click)="closeSidebar()">
            <mat-icon class="historybackbtn">keyboard_backspace</mat-icon>
            <div class="header">Service History</div>
        </div>
        <div class="car-details">
            <div class="car-image">
                <img [src]="getMakeImageUrl(vehicleDetails.make)" alt="Car Make Image" class="model-icon">
            </div>
            <div>
                <div class="title">{{ vehicleDetails.make }}, {{ vehicleDetails.fuelType }}</div>
                <div class="info"> {{ vehicleDetails.model }} {{ vehicleDetails.variant }}[{{ vehicleDetails.year }}]</div>
                <div class="regnum">{{ vehicleDetails.registrationNumber }}</div>
                <div class="vinnum">{{ vehicleDetails.vin }}</div>
            </div>
        </div>

        <div class="main-card"> 
            <div class="card">
                <div *ngFor="let item of vehicleHistoryData" class="record-container">
                    <div  class="details" >
                        <div class="order-icon" >
                            <i class="top-material-icons">check_box</i>
                        </div>
                        <div >
                            <div><span>Job Card ID:</span> {{ item.jobCardId }}</div>
                            <div><span>Name:</span> {{ item.name }}</div>
                            <div><span>Mobile Number:</span> {{ item.mobile }}</div>
                            <div style="display: flex;">
                            <div><span>Placed On:</span> {{ item.createdAt | date:'dd MMM yyyy, h:mm a' }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
  
