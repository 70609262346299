import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AspQuotationDetailsComponent } from './asp-quotation-details.component';
import { Routes, RouterModule } from '@angular/router';
import { MatTabsModule } from '@angular/material/tabs';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';

const routes: Routes = [
  {
    path: 'quote-details/:id/:jobId',
    component: AspQuotationDetailsComponent,
  },
];

@NgModule({
  declarations: [
    AspQuotationDetailsComponent,
    // ConfirmationPopupComponent
  ],
  imports: [
    RouterModule.forChild(routes),
    MatTabsModule,
    CommonModule,
    MatCheckboxModule,
    FormsModule
    // MatProgressBarModule,
    // MatIconModule,
    // MatDialogModule,
    // FormsModule,
    // ReactiveFormsModule, // Add ReactiveFormsModule
    // MatFormFieldModule,
    // MatInputModule // Add MatInputModule
  ],
  // entryComponents: [
    // ConfirmationPopupComponent
  // ],
  exports: [AspQuotationDetailsComponent],
})

export class AspQuotationDetailsModule { }
