<div class="navigation-buttons" *ngIf="(images?.length>0)">
  <button (click)="goToPreviousPage()" [disabled]="currentPage === 1" class="previous"><</button>
  <button (click)="goToNextPage()" [disabled]="currentPage === totalPages" class="next">></button>
</div>
<header class="quotation-header" *ngIf="(currentPage === 1 || currentPage === 0)||((currentPage === 2 || currentPage === 0) && (images?.length>0))">
  <div class="back-button">
     <!-- <i (click)="closeSidebar(false)" class="material-icons" style="color: black;font-weight: bold; cursor: pointer;">arrow_back</i> -->
     <span class="title">CHECK IN REPORT</span>
     <i class="material-icons" style="width: 5%;color: black;cursor: pointer;z-index: 9;" (click)="openBottomSheet()">more_vert</i>
  </div>
</header>
<div style="width: 100%;    top: -70px;position: relative;" id="quotationContent">
<div class="form-container" id="page1" *ngIf="currentPage === 1 || currentPage === 0" >
 

  <div  >
  <div class="documents1 form-page">
    <header class="form-header">
      <div class="business-info">
        <h1>{{data?.garageName}}</h1>
        <p class="p">{{data?.garageAddress}}, {{data?.city}}</p>
        <p class="p"><strong>Mobile:</strong> {{data?.garagePhone}}</p>
        <p class="p"><strong>Email:</strong>{{ data?.email}}</p>
      </div>
      <h2>VEHICLE CHECK IN REPORT JOB CARD ID</h2>
    </header>
  
        <!-- Main Layout -->
        <div class="form-grid">
          <!-- Left Section -->
          <div class="form-left">
            <!-- Collection and Delivery -->
            <div class="vehicle-details">
              <h3>CUSTOMER DETAILS</h3>
              <div class="content">
                <p><strong>Name:</strong> {{data?.customerName}}</p>
                <p><strong>Mobile:</strong> {{data?.customerMobile}}</p>
                <p><strong>Email:</strong> {{data?.customerEmail}}</p>
              </div>
            </div>
      
            <!-- Vehicle Details -->
            <div class="vehicle-details">
              <h3>VEHICLE DETAILS</h3>
              <div class="content">
              <p><strong>Reg No:</strong> {{data?.registrationNumber}}</p>
              <p><strong>Make:</strong> {{data?.make}} &nbsp; <strong>Model:</strong> {{data?.model}}</p>
              <p><strong>Fuel:</strong> {{data?.fuelType}} &nbsp; <strong>Year:</strong> {{data?.year}}</p>
              <p><strong>Varient:</strong> {{data?.variant}}</p>
              <p *ngIf="data?.vinNumber"><strong>VIN:</strong> {{data?.vinNumber}}</p>
              <p *ngIf="data?.engineNumber"><strong>Engine No:</strong> {{data?.engineNumber}}</p>
              </div>
            </div>
      
            <!-- Documents -->
              <div class="documents-section" *ngIf="accessoriesYes?.length > 0">
                <div class="documents-header">
                  <span>ACCESSORIES</span>
                  <div class="yes-no">
                    <span>Yes</span>
                    <span>No</span>
                  </div>
                </div>
                <div class="documents-list" *ngFor="let list of accessoriesYes">
                  <div class="document-item">
                    <span>{{list}}</span>
                    <div class="status-icons">
                      <span  class="yes-icon">&#10003;</span> <!-- Checkmark for Yes -->
                      <!-- <span *ngIf="!list.isYes" class="no-icon">&#10003;</span>  -->
                      <span class="no-icon"></span>
                    </div>
                  </div>
                  
                </div>
              </div>
              
            </div>
         
      
          <!-- Right Section -->
          <div class="form-right">
            <!-- Items -->
            <!-- <div class="items">
              <h3>ITEMS</h3>
              <p>Spare Key: Yes [] No []</p>
              <p>Locking Wheel Nuts: Yes [] No []</p>
              <p>Sat. Nav. Disc: Yes [] No []</p>
            </div> -->
            <div class="documents-section" *ngIf="data?.serviceType?.length > 0">
              <div class="documents-header">
                <span>SERVICE TYPE</span>
              </div>
              
              <div class="documents-list" *ngFor="let types of data?.serviceType">
                <div class="document-item">
                  <span>{{types}}</span>
                </div>
                <!-- <div class="document-item">
                  <span>Service Book</span>
                  <div class="status-icons">
                    <span class="yes-icon"></span>
                    <span class="no-icon">&#10003;</span>
                  </div>
                </div> -->
              </div>
            </div>
            <div class="documents-section" *ngIf="data?.issues?.length > 0">
              <div class="documents-header">
                <span>ISSUES</span>
              </div>
              
              <div class="documents-list" *ngFor="let issue of data.issues">
                <div class="document-item">
                  <span>{{issue}}</span>
                </div>
                <!-- <div class="document-item">
                  <span>Service Book</span>
                  <div class="status-icons">
                    <span class="yes-icon"></span>
                    <span class="no-icon">&#10003;</span>
                  </div>
                </div> -->
              </div>
            </div>
            <!-- Fuel & Mileage -->
            <div class="fuel-mileage">
              <h3>FUEL & MILEAGE (only if driven)</h3>
              <img src="../../../../assets/images/Fuel.png" class="fuelImg">
              <div class="content">
                <p>Fuel Level:{{data?.fuelLevel}}% </p>
                <!-- <p>Delivery Mileage: _____________</p> -->
              </div>
            </div>
      
          </div>
      </div>
      

  <div class="overlay" [class.visible]="isBottomSheetOpen" (click)="closeBottomSheet()"></div>
        <!-- <mat-bottom-sheet-container *ngIf="isBottomSheetOpen">
            <mat-nav-list>
                <mat-list-item>
                    <span class="names">Share For Approval</span>
                    <i class="material" (click)="shareForApproval()">share</i>
                </mat-list-item>
                <mat-divider></mat-divider>
                <mat-list-item>
                    <span class="names">Download Quotation</span>
                    <img src="../../../../assets/images/download.png" style="height: 24px;cursor: pointer;" (click)="downloadPDF()" alt="">
                    
                </mat-list-item>
                <mat-divider></mat-divider>
                <mat-list-item>
                    <span class="names">Edit Quotation</span>
                    <i class="material" (click)="openSidebarQuote()">edit</i>
                </mat-list-item>
            </mat-nav-list>
            
        </mat-bottom-sheet-container> -->
        <ng-template #bottomSheetTemplate >
          <div class="bottom-sheet-content">
            <div class="bottom">
              <span class="names">Share For Approval</span>
              <i class="material" (click)="shareForApproval()">share</i>
          </div>
          <div class="bottom">
              <span class="names">Download Check-in Report</span>
              <img src="../../../../assets/images/download.png" style="height: 24px;cursor: pointer;" (click)="downloadPDF()" alt="">
              
          </div>
          <div class="bottom">
              <span class="names">Edit Check-in Report</span>
              <i class="material" (click)="openInformationTab()">edit</i>
          </div>
          </div>
        </ng-template> 
      </div>

      
    </div>
    </div>
    <div class="form-container"  id="page2"  *ngIf="(currentPage === 2 || currentPage === 0) && (images?.length>0)">
    <div class="documents1 form-page">
    
      <header class="form-header">
        <div class="business-info">
          <h1>{{data?.garageName}}</h1>
          <p class="p">{{data?.garageAddress}}, {{data?.city}}</p>
          <p class="p"><strong>Mobile:</strong> {{data?.garagePhone}}</p>
          <p class="p"><strong>Email:</strong>{{ data?.email}}</p>
        </div>
        <h2>VEHICLE CHECK IN REPORT JOB CARD ID</h2>
      </header>
  
      <div class="image-container">
        <div *ngFor="let imageUrl of images" style="width: 48%;">
          <img [src]="imageUrl.url" alt="Image" class="image" crossorigin="anonymous" />
          <p class="tag">{{imageUrl.tag}}</p>
        </div>
      </div>

  <div class="overlay" [class.visible]="isBottomSheetOpen" (click)="closeBottomSheet()"></div>
        <!-- <mat-bottom-sheet-container *ngIf="isBottomSheetOpen">
            <mat-nav-list>
                <mat-list-item>
                    <span class="names">Share For Approval</span>
                    <i class="material" (click)="shareForApproval()">share</i>
                </mat-list-item>
                <mat-divider></mat-divider>
                <mat-list-item>
                    <span class="names">Download Quotation</span>
                    <img src="../../../../assets/images/download.png" style="height: 24px;cursor: pointer;" (click)="downloadPDF()" alt="">
                    
                </mat-list-item>
                <mat-divider></mat-divider>
                <mat-list-item>
                    <span class="names">Edit Quotation</span>
                    <i class="material" (click)="openSidebarQuote()">edit</i>
                </mat-list-item>
            </mat-nav-list>
            
        </mat-bottom-sheet-container> -->
        <ng-template #bottomSheetTemplate >
          <div class="bottom-sheet-content">
            <div class="bottom">
              <span class="names">Share For Approval</span>
              <i class="material" (click)="shareForApproval()">share</i>
          </div>
          <div class="bottom">
              <span class="names">Download Check-in Report</span>
              <img src="../../../../assets/images/download.png" style="height: 24px;cursor: pointer;" (click)="downloadPDF()" alt="">
              
          </div>
          <div class="bottom">
              <span class="names">Edit Check-in Report</span>
              <i class="material"  (click)="openInformationTab()">edit</i>
          </div>
          </div>
        </ng-template> 

      </div>

      </div>
    </div>
    <div>
    