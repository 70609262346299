import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AspMyEmployeeService } from './asp-my-employee.service';
import { MatTab, MatTabChangeEvent } from '@angular/material/tabs';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-asp-my-employee',
  templateUrl: './asp-my-employee.component.html',
  styleUrls: ['./asp-my-employee.component.scss']
})
export class AspMyEmployeeComponent implements OnInit {
  PopupOpenEmployee: boolean;
  employeeToDelete: any = null;
  searchText: string;
  isPopupOpen: boolean;
  successIcon: string = '../../../../assets/icons/icons8-ok.gif';
  editIndex: number | null = null;
  result: any;
  employees: any;
  owners: any;
  role: unknown[];
  roleType: any;
  selectedRoleTab: any;
  tabName: string;
  employeesId: any;
  resData: any;
  resDataCreate: any;
  selectedTabName: any;

  @ViewChildren(MatTab) tabs!: QueryList<MatTab>;
  dataValue: any;
  selectedRoleTabArray: any;
  onlyOwner: any;
  resultData: any;
  formattedTime: string;

  
  isSidebarOpen = false;
  isEditMode = false;
  successDeleted = false;

  // Form group for employee form
  employeeForm: FormGroup;
  access: string;

  constructor(private fb: FormBuilder,
    private aspMyEmployeeService:AspMyEmployeeService,
     private toastr: ToastrService
  ) {
    // Initialize the form with email conditionally required based on selectedRoleTab
    this.employeeForm = this.fb.group({
      name: ['', Validators.required], // Name field required
      phone: ['',
        [
          Validators.required,
          Validators.pattern(/^[6-9]\d{9}$/), // Regex for a valid 10-digit phone number starting with 6-9
        ],
      ],
      email: ['', []] // Start with no validator, we will add dynamically
    });
  }

  ngOnInit(): void {
    this.EmployeeDetails();
    this.roleTypes();
    this.updateTime();
    setInterval(() => this.updateTime(), 1000);
    this.access=localStorage.getItem('roleType')
  }
  EmployeeDetails(){
    let garageKey = localStorage.getItem('garageKey');

    this.aspMyEmployeeService.getEmployeeDetails(garageKey).subscribe(
      res =>{
        console.log(res)
        this.result = JSON.parse(JSON.stringify(res));
        this.resultData=this.result.data;
        this.employees=this.result.data;
        this.owners=this.result.data;
      }
    )

  }
  getRoleValue(roleType: string): string {
    const role = this.roleType.find(role => role.roleType === roleType);
    return role ? role.roleValue : roleType; // Return the roleValue if found, else fallback to roleType
  }
  
  // Function to open the sidebar
  openSidebar(employee?: any) {
    this.employeeForm.reset();
    if (employee) {
      this.isEditMode = true;
      // Populate form fields with existing employee data
      this.employeeForm.patchValue({
        roleType: employee['roleType'],
        name: employee['name'],
        phone: employee['phone'],
        email: employee['email'] || '',  
      });
      this.employeesId=employee.employeeKey;
      this.selectedRoleTab = this.roleType.findIndex(role => role.roleType ===employee.roleType);
      this.selectedRoleTabArray = this.roleType.find(role => role.roleType ===employee.roleType);

   

  
      // Dynamically set validators for the email field based on selectedRoleTab
      this.updateEmailValidator();
    } else {
      this.employeesId='';
      this.isEditMode = false;
      // Ensure email field has no validator when creating a new employee
      this.employeeForm.get('email')?.clearValidators();
      this.selectedRoleTab=0 ; 

    }
    this.isSidebarOpen = true;
    
  }
  
  // Function to dynamically update the email validator based on selectedRoleTab
  updateEmailValidator() {
    if (this.selectedRoleTab ==this.roleType.indexOf("Owners")) { // Assume 0 is for Owners
      this.employeeForm.get('email')?.setValidators([Validators.required, Validators.email]);
    } else {
      this.employeeForm.get('email')?.clearValidators();
    }
  
    // After setting validators, you must call updateValueAndValidity() to apply them
    this.employeeForm.get('email')?.updateValueAndValidity();
  }
  
  // Call this method whenever the tab selection changes
  onRoleChange(event: MatTabChangeEvent): void {
    this.updateEmailValidator(); // Update email validator when role tab changes
    // this.tabName = event.tab.textLabel; // Get the tab label (e.g., "Admin", "User")
    const selectedTabIndex = event.index;
    const selectedRole = this.roleType[selectedTabIndex];
    this.dataValue = selectedRole.roleType; // This will get the data-value (roleType)

  }
  

  // Function to close the sidebar
  closeSidebar() {
    this.isSidebarOpen = false;
  }

  // Getter to check for Owner Role (conditional email)
  get isOwnerRole() {
    this.onlyOwner=this.roleType[this.selectedRoleTab]?.roleType || '';
    return this.selectedRoleTab ==this.onlyOwner.indexOf("owners"); // Owner role tab
  }

  // Handle form submission
  onSubmit() {
    this.employeeForm.markAllAsTouched();
    
    // this.selectedTabName = this.tabs.toArray()[this.selectedRoleTab]?.textLabel || '';
    // this.selectedRoleTab = this.tabs.find(role => role.roleType ===employee.roleType);
    this.selectedTabName = this.roleType[this.selectedRoleTab]?.roleType || '';

  // Check if the form is valid
  if (this.employeeForm.valid) {
     const employeeData = this.employeeForm.value;

        // this.employees.push(employeeData);
    if (this.isEditMode) {
      // Edit existing employee
      this.employees[this.editIndex] = employeeData;
      const employeeData1= {
          avatar:"",
          employeeEmail:employeeData.email,
          employeeKey:this.employeesId,
          employeeName:employeeData.name,
          garageKey:localStorage.getItem('garageKey'),
        roleType:this.selectedTabName,
        userKey:localStorage.getItem('spareUserKey'),
      }
    
  
      this.aspMyEmployeeService.submitDataUpdate(employeeData1).subscribe(response => {
        if(response['code']==200){
        this.resData=response['data']
        
        console.error('response11:',response );
        this.isPopupOpen = true;
        }
        else {
        console.error('response11:',response );

          this.toastr.error(response['data'].description, 'Error');
        }
      });
      // this.isEditMode = false;
      this.editIndex = null;
    } else {
      // Add new employee
      // this.employees.push(employeeData);
      const employeeCreate= {
        avatar:"",
        employeeEmail:employeeData.email,
        employeeName:employeeData.name,
        employeePhone:employeeData.phone,
        garageKey:localStorage.getItem('garageKey'),
        roleType:this.selectedTabName,
        userKey:localStorage.getItem('spareUserKey'),
    }


  
    this.aspMyEmployeeService.DataCreate(employeeCreate).subscribe(response => {
      this.resDataCreate=response['data']
      if(response['code']==200){
        console.error('responsecreae:',response );
        this.isPopupOpen = true;
        }
        else {
        console.error('responsecreae:',response );

          this.toastr.error(response['data'].description, 'Error');
        }
    });
    }
   
    // Close the sidebar
    this.closeSidebar();

    // Open a popup to indicate success or confirmation
  }
  }
deleteDetails(employee: any){
  this.employeeToDelete = employee;
  this.PopupOpenEmployee = true;
}

closePopup() {
  this.PopupOpenEmployee=false;
}

// Trigger employee deletion
deleteEmployee() {
  if (this.employeeToDelete) {
    const deletedata = {
      employeeKey: this.employeeToDelete.employeeKey,
      garageKey: localStorage.getItem('garageKey'),
      userKey: localStorage.getItem('spareUserKey'),
    };
    this.aspMyEmployeeService.DataDelete(deletedata).subscribe(
      (response) => {
        if (response['code'] === 200) {
          this.employees = this.employees.filter(emp => emp.employeeKey !== this.employeeToDelete.employeeKey);
          this.owners = this.owners.filter(owner => owner.employeeKey !== this.employeeToDelete.employeeKey);
          this.successDeleted = true; // Update the flag here
          this.isPopupOpen = true;
          this.PopupOpenEmployee=false;
        }
      },
      (error) => {
        console.error('Error deleting employee:', error);
      }
    );
  }
}

closePopupSucess(){
  this.isPopupOpen=false;
  window.location.reload();

}
clearSearch(): void {
  this.searchText = '';
  this.filterRequests();
}

filterRequests(): void {
  let searchValue = this.searchText.trim().toLowerCase();
  this.employees = this.resultData.filter(request => {
    // // const idMatch = request.bookingId.toString().toLowerCase().includes(searchValue);
    let nameMatch = request.name.toLowerCase().includes(searchValue);
    let phoneMatch = request.phone.includes(searchValue);
    let roleValue = this.getRoleValue(request.roleType);
    let roleType = roleValue.toLowerCase().includes(searchValue);
    return  nameMatch || phoneMatch || roleType ;
  });


}
roleTypes(){
  let userkey = localStorage.getItem('spareUserKey');

  this.aspMyEmployeeService.getRoleType(userkey).subscribe(
    res =>{
      this.roleType=res['data'];
});
}
updateTime() {
  const indiaTime = new Date().toLocaleString('en-IN', {
      timeZone: 'Asia/Kolkata',
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
  });
  this.formattedTime = indiaTime;
}

}
