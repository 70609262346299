<div class="my-request">
  <!-- <mat-progress-bar class="mt-8" mode="indeterminate"></mat-progress-bar> -->
  <div class="container">
    <div class="main-content">
      <h2 class="title">My Bookings</h2>
      <div class="SearchBarPlaceholder">
          <div class="search">
            <div class="input-container">
              <input type="text" class="search-input" [(ngModel)]="searchText" (keydown.enter)="onSearch()" (input)="filterRequests()">
              <span *ngIf="searchText" class="close-button" (click)="clearSearch()">✖</span>
              <div class="scroll-text-container" *ngIf="!searchText">
                <div class="SearchBarIcon">
                  <i class="material-icons-search">search</i>
                </div>
                <div class="scroll-text">
                  <div id="animationText-1" class="SearchBarAnimationText">Search By "Booking ID"</div>
                  <div id="animationText-2" class="SearchBarAnimationText">Search By "Name"</div>
                  <div id="animationText-3" class="SearchBarAnimationText">Search By "Mobile Number"</div>
                </div>
              </div>
            </div>
            <button class="create-booking" (click)="toggleSidebar()">Create Booking</button>
          </div>

      <!-- Sidebar controlled by *ngIf -->
      <div class="overlay" [class.visible]="isSidebarOpen" (click)="toggleSidebar()"></div> 
      <div class="sidebar" *ngIf="isSidebarOpen">
        <div class="sidebar-content">
          <div class="sidebarheading">
            <h2 class="crtbkng">Create Booking</h2>
            <button class="close-sidebar" (click)="toggleSidebar()">×</button>
          </div>
          <form #bookingForm="ngForm" (ngSubmit)="onSubmit(bookingForm)" >
            <div class="form-group">
              <input type="text" id="name" placeholder="Name *" required [(ngModel)]="customerName" name="customerName" #name="ngModel" pattern="[a-zA-Z ]*">
              <small>Your Customer Name</small>
              <div *ngIf="(name.invalid && name.touched) || (name.invalid && formSubmitted)" style="color: red;" >
                Enter Valid Customer Name 
              </div>
            </div>
            <div class="form-group">
              <div class="mbl-number">
                Mobile Number <span class="required">*</span>
              </div>              
              <input type="text" id="mobile" placeholder="+91" required pattern="^\d{10}$" [(ngModel)]="customerMobile" name="customerMobile" #mobile="ngModel" pattern="[0-9]*" maxlength="10">
              <small>Minimum 10 digits required</small>
              <div *ngIf="(mobile.invalid && mobile.touched) || (mobile.invalid && formSubmitted)" style="color: red;">
                Enter Valid Mobile Number
              </div>
            </div>
            <button type="submit" class="submit-btn">Create Booking</button>
          </form>
        </div>
      </div>
      <!-- Pop-up content -->
      <div class="popup" *ngIf="isPopupOpen">
        <div class="popup-content order-success-sec">
          <!-- <button mat-button (click)="closePopup()">Close</button> -->
          <img src="{{ successIcon }}" alt="Success Icon">
          <button class="close-popup" (click)="closePopup()">×</button>
          <h3>Booking Created!</h3>
          <p class="order-label"><span class="order-id">Booking ID  {{orderID.bookingId}}</span></p>
          <p class="date"><span>{{orderID.createdAt | date:'dd MMM yyyy, hh:mm a'}}</span></p>
          <p class="thank-you">let us deliver an awesome experience!</p>
          <button class="goto-order" (click)="closePopup()">OK, GOT IT </button>
        </div>
      </div>
      <!-- Other content -->
        <div class="header" *ngFor="let request of requestList">
          <div class="header-row">
            <div class="request-info">
              <div class="header-top-row">
                <div class="order-icon" style="width: 4%;">
                  <i class="top-material-icons">check_box</i>
                </div>
                <div class="book-id">Booking ID {{request.bookingId}}</div>
                <div class="right-container">
                  <div class="right-container-icon" (click)="shareOnWhatsApp(request)">
                    <mat-icon class="share-icon">share</mat-icon>
                  </div>
                  <!-- <div class="right-container-icon">⋮</div> -->
                </div>
              </div>
              <div class="name-mbl-num">
                <div class="name">{{ request.name }}</div>
                <div class="mobile-number">{{ request.phoneNumber }}</div>
              </div>
            </div>
          </div>
          <div class="header-row2">
            <!-- {{requestList | json}} -->
            <div class="placed-on-date">{{ request.createdAt | date:'dd MMM yyyy, hh:mm a'}}</div>
            <a class="view-btn" style="float: right; border: none; background-color: white; color: #4caf50;" (click)="loadorderdetails(request.bookingId,request.name,request.phoneNumber)">
              <span class="job-card" style="float: left;">Create Job Card</span>
            </a>
              <!-- <a class="view-btn" href="/request-details/YourRequestID" style="float: right;border: none;background-color: white;color: #4caf50;"> -->
          </div>
        </div>
    </div>
  </div>

<!-- Job Card Sidebar -->
<div class="overlay" [class.visible]="isJobCardSidebarOpen" (click)="toggleJobCardSidebar()"></div>
<div class="job-card-sidebar" *ngIf="isJobCardSidebarOpen">
  <form class="container">
    <!-- <label for="odometer" style="font-weight: bolder;">
      Enter Odometer Reading <span class="required">*</span>
    </label> -->
    <!-- <div class="input-group">
      <span class="small-img">
        <img src="assets/images/my-job-card-images/carkm.png" class="input-icon" alt="Car KM"> KM
      </span>
      <input id="odometer" type="text" placeholder="10000">
    </div>
    <small>Enter Odometer Reading</small> -->

    <label for="regNo" style="font-weight: bolder;">
      We recommend entering the car number <span class="required">*</span>
    </label>
    <div class="input-group">
      <span class="small-img">
        <img src="assets/images/my-job-card-images/circle.png" class="input-icon" alt="Car Number"> IND
      </span>
      <input id="regNo" placeholder="KA 04 JA54**" maxlength="12" style="font-weight: 800;">
    </div>
    <small>Please enter car number</small>

    <!-- <div class="action-buttons">
      <button mat-icon-button>
        <mat-icon>camera_alt</mat-icon> Scan
      </button>
      <button mat-icon-button>
        <mat-icon>mic</mat-icon> Voice
      </button>
    </div> -->

    <ul class="info-list">
      <li>
        <mat-icon class="circle">check_circle</mat-icon>
        <span class="get">Get accurate details</span>
      </li>
      <li>
        <mat-icon class="circle">check_circle</mat-icon>
        <span class="get">Get 2X faster results</span>
      </li>
    </ul>

    <button mat-raised-button color="accent" class="submit-btn" aria-label="VEHICLE">
      FIND MY CAR
    </button>
    <p class="manual-selection">Or select car manually</p>
  </form>
</div>


<!-- Confirmation Sidebar -->
<div class="overlay" [class.visible]="isConfirmationSidebarOpen" (click)="toggleConfirmationSidebar()"></div>
<div class="confirmation-sidebar" *ngIf="isConfirmationSidebarOpen">
    <div class="confirmation-content">
        <div class="header-container">
            <mat-icon class="back-arrow" (click)="toggleConfirmationSidebar()">arrow_back</mat-icon>
            <mat-icon style="color: #144579;">directions_car</mat-icon>
            <img src="../../../../assets/images/my-job-card-images/img_share.png" alt="">
        </div>
        <h3>Confirm your car details</h3>
        <div class="details-item">
            <img src="../../../../assets/images/my-job-card-images/ic_check.png" class="circle-img" alt="">
            <div class="label">Make:</div>
            <div class="value">{{ jobCard.make }}</div>
        </div>
        <div class="details-item">
            <img src="../../../../assets/images/my-job-card-images/ic_check.png" alt="">
            <div class="label">Model:</div>
            <div class="value">{{ jobCard.model }}</div>
        </div>
        <div class="details-item">
            <img src="../../../../assets/images/my-job-card-images/ic_check.png" alt="">
            <div class="label">Variant:</div>
            <div class="value">{{ jobCard.variant }}</div>
        </div>
        <div class="details-item">
            <img src="../../../../assets/images/my-job-card-images/ic_check.png" alt="">
            <div class="label">MFG Year:</div>
            <div class="value">{{ jobCard.mfgYear }}</div>
        </div>
        <div class="details-item">
            <img src="../../../../assets/images/my-job-card-images/ic_check.png" alt="">
            <div class="label">Fuel:</div>
            <div class="value">{{ jobCard.fuelType }}</div>
        </div>
        <div class="details-item">
            <img src="../../../../assets/images/my-job-card-images/ic_check.png" alt="">
            <div class="label">VIN:</div>
            <div class="value">{{ jobCard.vin }}</div>
        </div>
        <button class="confirm-btn" (click)="confirmCarDetails()">Confirm</button>
        <button class="change-car-btn" (click)="toggleConfirmationSidebar()">Change Car</button>
    </div>
</div>

</div>


<div #dynamicContainer></div>