<div class="page-layout blank fullwidth p-12" style="height: 1px; overflow-y: scroll; top: 1px;">

<div *ngIf="!loading" style="margin-top: 23px; width: 100%;">
  <!-- <div class="text_cust" *ngIf="isMobile && filteredOrderList ">
    <i (click)="closeQuote()" class="material-iconsarrow" >arrow_back</i>
    <p>Customer quotation</p>
  </div> -->
  <h2 class="title">Customer Quotations</h2>

    <div *ngFor="let list of filteredOrderList">
      <div class="header-row0" [ngClass]="{'header-row0Mobile': isMobile}" >  
        <div class="header-row">
          <div class="order-icon" style="width: 5%;">
            <i class="material-icons">check_box</i>
          </div>
          <div class="order-info" [ngClass]="{'order-infoMobile': isMobile}">

            <div class="request-id"  style="margin-top: 43px;" >Order No# {{list.id}}</div>

            <!-- <div class="request-id" *ngIf="list.requestId !== 0">Request ID# {{list.requestId}}</div> -->
            <div class="order-amount">Total Amount - ₹{{list.sellingPrice}} </div>
            <div class="order-amount" *ngIf="list.registrationNo">Registration Number - {{list.registrationNo}} </div>
          </div>
        </div>
        <div class="header-row1">
          <div class="order-item"><span > {{list.noOfItems}} items</span></div>
        </div>
        <div class="header-row2">
          <div class="order-date" [ngClass]="{'order-dateMobile': isMobile}">
            <span>Placed on: {{list.placedOn | date: 'dd MMM yyyy'}}, {{list.placedOn | date: 'hh:mm a': 'GMT+1060'}}</span>
            <button (click)="loadorderdetails(list.id,list.registrationNo)" class="Viewbtn">
              <span class="view">View Details</span>
              <i class="material-icons">arrow_right</i> 
            </button>
          </div>
        </div>
      </div>
    </div>
      </div>
</div>

      <div #dynamicContainer1></div>
      <div *ngIf="loading">
        <div #dynamicContainers></div>
        </div>