import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalVariable } from 'app/main/globals';

@Injectable({
  providedIn: 'root'
})
export class AspMyEmployeeService {

  constructor(private http: HttpClient,) { }

  getEmployeeDetails(garageKey){
    // let garageKey = localStorage.getItem('garageKey');
    return this.http.get(`${GlobalVariable.BASE_API_URL_SPARES}` + '/employee/all/'+garageKey);
  
  }
  submitDataUpdate(payload: any){
    const paramData=payload;
    console.log('-----submitDataUpdate-----------');    
    return this.http.post(`${GlobalVariable.BASE_API_URL_SPARES}/employee/update`, paramData);
  
  }
  DataDelete(payload: any){
    const paramData=payload;
    console.log('-----DataDelete-----------');    
    return this.http.post(`${GlobalVariable.BASE_API_URL_SPARES}/employee/delete`, paramData);
  
  }
  DataCreate(payload: any){
    const paramData=payload;   
    console.log('-----DataCreate-----------');    
    return this.http.post(`${GlobalVariable.BASE_API_URL_SPARES}/employee/create`, paramData);
  
  }
  getRoleType(userkey){
    // let garageKey = localStorage.getItem('garageKey');
    return this.http.get(`${GlobalVariable.BASE_API_URL_SPARES}` + '/user/roles/'+userkey);
  
  }
}