<div class="container">
    <div class="header" *ngIf="!request?.jobCard?.requestId">
      <i class="material-icons" style="color: black; float: left; cursor: pointer;" (click)="goBack()" >keyboard_backspace</i> 
      <span class="title">Job Card Details</span>
      <div class="right">
        <!-- Placeholder for Request ID -->
        <!-- *ngIf="showServiceHistory"------------------------------ -->
        <div class="changebtn" *ngIf="showServiceHistory">
          <button class="history-btn" (click)="openServiceHistory()">SERVICE HISTORY</button>
          <span class="request-number">{{ request?.jobCard?.id}}</span>
        </div>
        
      </div>
    </div>
  
    <div class="details" *ngIf="!request?.jobCard?.requestId">
      <div class="logo-and-model">
        <img [src]="getMakeImageUrl(request?.jobCard?.make)" alt="Car Make Image" class="model-icon">
        <div class="model-info">
          <span class="model-name">{{ request?.jobCard?.make || 'Unknown Make' }}</span>
          <span class="model-details">{{ request?.jobCard?.model || 'Unknown Model' }}, {{ request?.jobCard?.fuelType || 'Unknown Fuel Type' }}</span>
          <span class="model-details">{{ request?.jobCard?.registrationNumber }}</span>
        </div>
      </div>
  
      <div class="right-details">
        <div class="timestamp">{{ request?.jobCard?.createdAt | date: 'medium' }}</div>
        <!-- <button type="button" class="QuoteBtn"  (click)="openSidebarQuote()" *ngIf="requestId && (activeTab === 'quote-received') && (quotationreceived.length > 0 || quotationapproved.length > 0)" >Create Customer Quote</button> -->

        <!-- Share Job Card Button -->
        <div *ngFor="let status of filters.jobCardStatus">
          <label class="{{status.statusclass}}" *ngIf="request?.jobCard?.jobcardStatus && status.value==request?.jobCard?.jobcardStatus">{{status.label}}</label>   
        </div>
        <!-- <button class="share-job-card-button" (click)="shareOnWhatsApp()">
          <img src="../../../../assets/images/my-job-card-images/img_share.png" alt="Share Icon" class="share-icon">
          Share Job Card
        </button> -->
      </div>
    </div>
  
    <!-- Information Section -->
    <!-- <div class="second-div-details" *ngIf="!request?.jobCard?.requestId">
      <div class="line">
        <div class="strong">Name</div>
        <div class="label">{{ request?.jobCard?.name || 'N/A' }}</div>
      </div>
      <div class="line">
        <div class="strong">Mobile Number</div>
        <div class="label">{{ request?.jobCard?.phoneNumber || 'N/A' }}</div>
      </div>
      <div class="line">
        <div class="strong">Odometer</div>
        <div class="label">{{ request?.jobCard?.odometer || 'N/A' }} km</div>
      </div>
      <div class="line">
        <div class="strong">Registration Number</div>
        <div class="label">{{ request?.jobCard?.registrationNumber || 'N/A' }}</div>
      </div>
      <div class="line">
        <div class="strong">Request ID</div>
        <div class="label">{{ request?.jobCard?.requestId || 'N/A' }}</div>
      </div>
    </div> -->

    <!-- <app-asp-request-details></app-asp-request-details> -->
    <div id="myDynamicSec" #myDynamicSec></div>
  
    <!-- <div class="third-div">
      <div class="buttons">
        <button class="request-button"[ngClass]="{ active: activeTab === 'requests' }"(click)="setActiveTab('requests')">REQUESTS</button>
        <button class="request-button"[ngClass]="{ active: activeTab === 'quoteReceived' }"(click)="setActiveTab('quoteReceived')">QUOTE RECEIVED</button>
        <button class="request-button"[ngClass]="{ active: activeTab === 'quoteApproved' }"(click)="setActiveTab('quoteApproved')">QUOTE APPROVED</button>
      </div> -->
  
      <!-- Content for Requests Tab -->
      <!-- <div class="request-item" *ngIf="activeTab === 'requests'">
        <div class="request-info">Request made for {{ quotationReceived.length }} items</div>
        <div class="item" *ngFor="let item of quotationReceived">
          <span style="font-weight: 500;">{{ item.name }}</span>
          <span class="quantity">{{ item.quantity }} Unit(s)</span>
        </div>
      </div> -->
  
      <!-- Content for Quote Received Tab -->
      <!-- <div class="items" *ngIf="activeTab === 'quoteReceived'">
        <div class="request-info">Quote received for {{ quotationReceived.length }} items</div>
        <div class="item">
          <div class="checkboxselect">
            <input type="checkbox" (change)="selectAll($event)">
            <span style="margin-left: 5px;">Select All</span>
          </div>
          <div>
            <span style="margin-left: 97px;">₹ </span>
            <div style="margin-left: 15px;">Total Selling Price</div>
          </div>
        </div>
  
        <div class="quotereceived-items">
          <div class="checkboxselect" *ngFor="let item of quotationReceived">
            <div class="lines">
              <input type="checkbox" [(ngModel)]="item.selected" (change)="updateTotalSellingPrice()">
              <span class="part-name" style="margin-left: 5px;">{{ item.name }}</span>
              <span class="ellipsis" (click)="openSidebar(item)">⋮</span>
            </div>
            <div class="lines">
              <span style="margin-left: 5px" class="availability">Availability:</span>
              <span class="date">₹ {{ item.price }}</span>
            </div>
          </div>
        </div>
      </div> -->
  
      <!-- Content for Quote Approved Tab -->
    <!-- <div class="items" *ngIf="activeTab === 'quoteApproved'">
        <div class="request-info">Quote approved for 1 item</div>
        <div class="total-selling-price">
          <span>Total Selling Price</span>
          <span class="total-price">₹</span>
        </div>
  
        <div class="quoteapproved-items">
          <div class="line" *ngFor="let item of quotationApproved">
            <span class="part-name">name</span>
            <span class="ellipsis" (click)="openSidebar(item)">⋮</span>
          </div>
          <div class="line">
            <span class="availability">Availability</span>
            <span class="date">₹</span>
          </div>
          <div class="line">
            <span class="date-time">date and time</span>
            <span class="amount">Total Selling Price</span>
          </div>
        </div>
      </div>
    </div> -->
  
    <!-- Sidebar Section -->
    <!-- <div class="overlay" *ngIf="sidebarOpen" (click)="closeSidebar()"></div>
    <div class="sidebar" [ngClass]="{'open': sidebarOpen}">
        <div class="sidebar-header">
        <span class="sidebar-title">{{ itemDetails?.name }}</span>
        <span class="close-btn" (click)="closeSidebar()">&times;</span>
      </div>
      <div class="sidebar-content">
        <div class="sidebar-item">
          <span class="label">Brand</span>
          <span class="value">{{ itemDetails?.brand }}</span>
        </div>
        <div class="sidebar-item">
          <span class="label">Quantity</span>
          <span class="value">{{ itemDetails?.quantity }}</span>
        </div>
        <div class="sidebar-item">
          <span class="label">Availability</span>
          <span class="value">{{ itemDetails?.availability }}</span>
        </div>
        <div class="sidebar-item">
          <span class="label">MRP (Unit)</span>
          <span class="value">₹{{ itemDetails?.mrpUnit }}</span>
        </div>
        <div class="sidebar-item">
          <span class="label">MRP Total</span>
          <span class="value">₹{{ itemDetails?.mrpTotal }}</span>
        </div>
        <div class="sidebar-item">
          <span class="label">Selling Price (Unit)</span>
          <span class="value">₹{{ itemDetails?.sellingPriceUnit }}</span>
        </div>
        <div class="sidebar-item">
          <span class="label">Total Selling Price</span>
          <span class="value">₹{{ itemDetails?.totalSellingPrice }}</span>
        </div>
        <div class="sidebar-item">
          <span class="label">Discount</span>
          <span class="value">{{ itemDetails?.discount }}%</span>
        </div>
        <div class="sidebar-item">
          <span class="label">Part Number</span>
          <span class="value">{{ itemDetails?.partNumber }}</span>
        </div>
      </div>
      <button class="okay-btn" (click)="closeSidebar()">Okay, got it</button>
    </div> -->
    <!-- Bottom Box for Selected Items -->
    <!-- <div *ngIf="activeTab === 'quoteReceived'">
      <div *ngIf="anyItemSelected()" class="bottom-box">
        <div class="bottom-content">
          <div>
            <div class="total-items">{{ selectedItemsCount }} items</div>
            <div class="total-amount">Total Selling Price ₹{{ totalSelectedPrice | number: '1.2-2' }}</div>
          </div>
        </div>
        <button class="approve-quote-button" (click)="approveQuote()">Approve Quote</button>
      </div> -->
    <!-- </div> -->
  </div>

  <ng-container #vehicleServiceHistoryContainer></ng-container>