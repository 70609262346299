import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AspMyDocumentsComponent } from './asp-my-documents.component';
import { RouterModule, Routes } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { AspViewDocumentComponent } from '../asp-view-document/asp-view-document.component';



const routes: Routes = [
  { path: 'my-documents',component: AspMyDocumentsComponent,  },
  { path: 'view-document', component: AspViewDocumentComponent }
];

@NgModule({
  declarations: [AspMyDocumentsComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    HttpClientModule,
    MatIconModule,
    MatDialogModule,
  ],
    exports: [AspMyDocumentsComponent,RouterModule],
})
export class AspMyDocumentsModule { }
