<div class="view-document-page">
  <div class="header">
    <div class="close-btn-side-slider" (click)="goBack()" >
      <mat-icon>keyboard_backspace</mat-icon>
    </div>
    <h1>{{ getDocumentTitle(documentData.type) }}</h1>
    <div class="header-icons">
      <button class="Viewbtn" (click)="downloadFile()">
        <img src="../../../../assets/images/download.png" alt="download" style="width: 21%;">
        <span class="view" >Download</span>
      </button>
      <button class="Viewbtn" (click)="showPopup()">
        <mat-icon>delete</mat-icon>
        <span class="view">Delete</span>
      </button>
    </div>
  </div>

  <div class="content">
    
    <div class="imagediv"*ngIf="documentData.fileURL">
      <!-- Display Image if it's an image -->
      <img
        *ngIf="isImage(documentData.fileURL)"
        [src]="documentData.fileURL"
        alt="Document Preview"
        class="file-preview-image"
      />

      <!-- Display PDF if it's a PDF -->
      <iframe
        *ngIf="isPDF(documentData.fileURL)"
        [src]="transform(documentData.fileURL)"
        width="100%"
        height="500px">
      </iframe>

      <!-- Display a generic message for unsupported file types -->
      <p *ngIf="!isImage(documentData.fileURL) && !isPDF(documentData.fileURL)">
        This file cannot be previewed, but you can download it.
      </p>
      <!-- Display the raw S3 link -->
      <!-- <p>{{ documentData.fileURL }}</p> -->
    </div>
  </div>

  <!-- Confirmation Popup -->
<div *ngIf="showConfirmationPopup" class="confirmation-popup">
  <!-- <div class="overlay" (click)="closePopup(false)"></div> -->
  <div class="popup-content">
    <div>
      <h3>Are you sure you want to delete the copy of  </h3>
      <h2>{{ getDocumentTitle(documentData.type) }}?</h2>
      <mat-icon class="close-popup" (click)="closePopup()">close</mat-icon>
    </div>
    <div class="popup-actions">
      <button mat-raised-button color="warn" class="no-button" (click)="closePopup()">No</button>
      <button color="primary" class="yes-button" (click)="confirmDelete()">Yes</button>
    </div>
  </div>
</div>
</div>
