import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FuseConfigService } from '@fuse/services/config.service';
import { AspQuotationDetailsService } from './asp-quotation-details.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-asp-quotation-details',
  templateUrl: './asp-quotation-details.component.html',
  styleUrls: ['./asp-quotation-details.component.scss']
})
export class AspQuotationDetailsComponent implements OnInit {
  quoteId: any;
  jobId: any;
  quoteDetails: any = [];
  quoteReceivedDetails: any = [];
  quoteApprovedDetails: any = [];
  quoteReceivedTotal: number = 0;
  quoteApprovedTotal: number = 0;
  selectAllQuote:boolean = false;
  isActiveApprove:boolean = false;
  quoteReceivedSelectedTotal: number = 0;
  quoteReceivedSelectedCount: number = 0;
  isActiveComplete:boolean = false;
  isPopupOpen: boolean = false;
  successIcon:string = '../../../../assets/icons/icons8-ok.gif';
  make:any;
  model:any;
  fuelType:any;
  makeImg: string;
  activeTabIndex: number = 0;

  constructor(
    private route: ActivatedRoute,
    private _fuseConfigService: FuseConfigService,
    private aspQuotationDetailsService: AspQuotationDetailsService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this._fuseConfigService.config = {
      layout: {
        navbar: { hidden: true },
        toolbar: { hidden: true },
        footer: { hidden: true },
        sidepanel: { hidden: true }
      }
    };

    this.route.paramMap.subscribe(params => {
      this.quoteId = params.get('id');
      this.jobId = params.get('jobId');
    });

    this.getQuoteDetails();
  }

  getQuoteDetails() {
    this.quoteDetails = [];
    this.quoteReceivedDetails = [];
    this.quoteApprovedDetails = [];
    const userKey = localStorage.getItem('spareUserKey');
    const garageKey = localStorage.getItem('garageKey');
  
    this.aspQuotationDetailsService.getQuoteDetails(garageKey, this.quoteId, this.jobId, userKey).subscribe(
      result => {
        const res = JSON.parse(JSON.stringify(result));console.log(res)
        this.quoteDetails = res.data;

        if(this.quoteDetails.make){
          const makeWithoutSpaces = this.quoteDetails.make.toLowerCase().replace(/\s+/g, '');
          this.makeImg =  `https://cube.getpitstop.com/assets/img/${makeWithoutSpaces}.png`;
          this.make = this.quoteDetails.make;
        }

        if(this.quoteDetails.model){
          this.model = this.quoteDetails.model;
        }

        if(this.quoteDetails.fuelType){
          this.fuelType = this.quoteDetails.fuelType;
        }
        
        this.getQuoteReceivedDetails();
        this.getQuoteApprovedDetails();
      },
      error => {
        console.error('Error fetching quote details:', error);
      }
    );
  }

  getQuoteReceivedDetails(){
    this.quoteDetails?.items?.forEach(received => {
      if(received.jobCardStatus==2){
        received.type = "parts";
        received.checked = false;
        this.quoteReceivedDetails.push(received);
        this.quoteReceivedTotal +=  (received.sellingPrice*received.quantity);
      }
    });

    this.quoteDetails?.labourQuoteItemResponses?.forEach(received => {
      if(received.jobCardStatus==2){
        received.type = "labour";
        received.checked = false;
        this.quoteReceivedDetails.push(received);
        this.quoteReceivedTotal +=  (received.labourAmount*received.quantity);
      }
    });
  }

  getQuoteApprovedDetails(){
    this.quoteDetails?.items?.forEach(approved => {
      if(approved.jobCardStatus==3){
        approved.type = "parts";
        approved.checked = false;
        this.quoteApprovedDetails.push(approved);
        this.quoteApprovedTotal +=  (approved.sellingPrice*approved.quantity);
      }
    });

    this.quoteDetails?.labourQuoteItemResponses?.forEach(approved => {
      if(approved.jobCardStatus==3){
         approved.type = "labour";
        approved.checked = false;
        this.quoteApprovedDetails.push(approved);        
        this.quoteApprovedTotal +=  (approved.labourAmount*approved.quantity);
      }
    });
  }

  toggleSelectAllQuote() {
    this.quoteReceivedDetails.forEach(received => (received.checked = this.selectAllQuote));
    this.enableAction();
  }

  checkIndividualOption() {
    this.selectAllQuote = this.quoteReceivedDetails.every(received => received.checked);
    this.enableAction();
  }

  enableAction(){
    this.quoteReceivedSelectedTotal = 0;
    this.quoteReceivedSelectedCount = 0;
    if(this.quoteReceivedDetails?.length>0){
      this.quoteReceivedDetails.forEach(selectedData => {
        if(selectedData.checked == true){
          let price:number = 0
          if(selectedData.type=="parts"){
            price = (selectedData.sellingPrice*selectedData.quantity);
          }else{
            price = (selectedData.labourAmount*selectedData.quantity);
          }

          this.quoteReceivedSelectedTotal =  this.quoteReceivedSelectedTotal + price;
          this.quoteReceivedSelectedCount++;            
        }
      });
    }
    
    if(this.quoteReceivedSelectedCount>0){
      this.isActiveApprove  = true;
    }else{
      this.isActiveApprove  = false;
    }
  }

  approveQuotation(){
    let customerQuoteItems: any = [];
    let jobcardId:number = 0;
    if(this.quoteReceivedDetails?.length>0){
      this.quoteReceivedDetails.forEach(selectedData => {
        if(selectedData.checked == true){
          let id:number = 0
          if(selectedData.type=="parts"){
            id = selectedData.itemId;
          }else{
            id = selectedData.labourId;
          }

          jobcardId = selectedData.jobcardId;
          
          customerQuoteItems.push({
                "id": id,
                "jobCardStatus": 3,
                "jobcardId": selectedData.jobcardId
            });
        }
      });
    }

    let param = {
      "jobcardId": jobcardId,
      "customerQuoteItems": customerQuoteItems,
      "customerQuoteId": this.quoteId
    }

    this.aspQuotationDetailsService.approveQuotation(param).subscribe(
      result => {
        const res = JSON.parse(JSON.stringify(result));
        console.log(res);

        this.isPopupOpen = true;
      },
      error => {
        console.error('Error approving quotation:', error);
      }
    );

  }

  viewApprovedQuote(){
    this.isPopupOpen = false;
    this.getQuoteDetails();
    this.cdr.detectChanges(); 
    this.switchToTab(1);
  }

  switchToTab(index: number): void {
    this.activeTabIndex = index;
  }

}
