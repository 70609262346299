import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class S3ImageUploadService {

  uploadInvoice(updatedFormData: any) {
    throw new Error('Method not implemented.');
  }
  private presignedUrlApi = 'https://iapi.testpitstop.com/v1.0/shipment/V3/presignedurl/create';
  // private presignedUrlApi = 'https://iapi.testpitstop.com/v1.0//pitstopV2/v2/presignedurl/create';

  constructor(private http:HttpClient,
    private router: Router
  ) { }

  getPresignedUrl(fileName): Observable<any> {
    fileName = fileName.replace(/\s+/g, '');
    const headers = new HttpHeaders({
      'x-api-key': 'h6S5lcvELn2XLh8XURiFW1HSynljQywm6lbH1Yf7',
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    });
    const body = [fileName];
    console.log("bgghtht----",headers);
  
    return this.http.post<any>(this.presignedUrlApi, body, { headers });
    // return this.http.post('/api/get-presigned-url', { fileName });

  }


}
