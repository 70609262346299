import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { S3ImageUploadService } from 'app/shared/s3-image-upload.service';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { ViewChild, TemplateRef } from '@angular/core';
import { AspMyDocumentsService } from './asp-my-documents.service';
import { AspViewDocumentService } from '../asp-view-document/asp-view-document.service';
import { NgZone } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';




@Component({
  selector: 'app-asp-my-documents',
  templateUrl: './asp-my-documents.component.html',
  styleUrls: ['./asp-my-documents.component.scss']
})
export class AspMyDocumentsComponent implements OnInit {
  
  newlyUploadedImages: any[];
  selectedImageUrls: any;
  showConfirmationPopup = false;
  loading: boolean;
  selectedFile: File | null = null;
  selectedFileName: string = '';
  selectedFilePreview: string | ArrayBuffer | null = null;
  uploadedDocuments: { [key: string]: { uploaded: boolean; fileURL?: string } } = {
    gstCertificate: { uploaded: false },
    aadharCard: { uploaded: false },
    panCard: { uploaded: false },
    establishmentCertificate: { uploaded: false },
    coi: { uploaded: false },
    msmeCertificate: { uploaded: false },
    
  };
  dialog: any;
  mobileNumber: string;
  certificate: any;
 
  documentData: { name: string; type: string; fileURL: string | null } = {
    name: '',
    type: '',
    fileURL: null,
  };
  route: any;
  constructor(
    private router: Router,
    private s3ImageUploadService: S3ImageUploadService,
    private http: HttpClient,
    private aspMyDocumentsService: AspMyDocumentsService, 
    private aspViewDocumentService: AspViewDocumentService ,
    private MatDialog: MatDialog,
    private ngZone: NgZone,
    private changeDetectorRef: ChangeDetectorRef,
    private toastr: ToastrService
  ) { }
    

  ngOnInit(): void {
    // for (const documentKey in this.uploadedDocuments) {
    //   const storedUrl = this.aspMyDocumentsService.getUploadedDocuments(documentKey);
    //   if (storedUrl) {
    //     // this.uploadedDocuments[documentKey] = { uploaded: true, fileURL: storedUrl };
    //   }
    // }
    this.getUploadedDocuments();
  
  }

  // Trigger File upload
  onFileSelected(event: Event, documentKey: string): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const files: FileList = input.files;
      this.newlyUploadedImages = [];  
      this.selectedFilePreview = null; 
      this.selectedFileName = ''; 
  
      Array.from(files).forEach((file) => {
        // Handle image files (show preview)
        if (file.type.startsWith('image/')) {
          const reader = new FileReader();
          reader.onload = (e: any) => {
            this.selectedFilePreview = e.target.result; 
          };
          reader.readAsDataURL(file);
        } else if (file.type === 'application/pdf') {
          // Handle PDF files (show PDF icon or file name)
          this.selectedFilePreview = 'assets/pdf-icon.png'; 
          this.selectedFileName = file.name; 
        } else {
          // Handle any other file types (you can add more logic for other types)
          this.selectedFilePreview = null;
          this.selectedFileName = file.name;
        }
  
        // Get the pre-signed URL for each file and upload it to S3
        this.s3ImageUploadService.getPresignedUrl(file.name).subscribe({
          next: (response) => {
            const presignedUrl = response[0]?.signedUrl;
            const link = response[0]?.link;
  
            if (presignedUrl && link) {
              this.newlyUploadedImages.push(link); // Track the newly uploaded images for this document
              console.log('1', documentKey);
              console.log('1', link);
              console.log('---------------');
              this.uploadFileToS3(documentKey, link, file, presignedUrl); // Upload the file for the specific document
              
            } else {
              console.error('Invalid presigned URL or link:', response);
              this.loading = false;
            }
          },
          error: (err) => {
            console.error('Error fetching presigned URL:', err);
          },
        });
      });
    }
  }
  
  
  uploadFileToS3( documentType: string, link: string, file, presignedUrl): void {  
    const contentType = file.type;
    this.http.put(presignedUrl, file, {
      headers: { 'Content-Type': contentType },
    }).subscribe({
      next: () => {
        console.log('File uploaded successfully.');
        this.uploadedDocuments[documentType] = { uploaded: true, fileURL: link };

          console.log('2', documentType);
          console.log('2', link);
          console.log('---------------');
          this.mobileNumber = localStorage.getItem("userMobile") || '';
          console.log('2', this.mobileNumber);
          this.aspMyDocumentsService.saveDocumentMetadata(this.mobileNumber, documentType, link).subscribe({
            next: (response) => {
              console.log('Document metadata saved successfully:', response);
              this.loading = false;
              this.ngOnInit();
            },
            error: (err) => {
              console.error('Error saving document metadata:', err);
            },
          });
      },
      error: (err) => {
        console.error('Error uploading file:', err);
      },
      complete: () => {
        this.loading = false;
      },
    });
  }
  
  onUploadClick(documentKey: string): void {
    const inputElement = document.querySelector<HTMLInputElement>(`#fileInput_${documentKey}`);
    if (inputElement) {
      console.log("Input element found:", inputElement);
      inputElement.click();
      this.ngOnInit();
    } else {
      console.warn(`Input element not found for ID: fileInput_${documentKey}`);
    }
  }
  

  viewFile(documentKey: string, file: string): void {
    const fileName = file.split('/').pop() || 'Unknown File'; 
    console.log("File Name:", fileName);  
  
    if (file) {

      this.aspMyDocumentsService.viewUserDocument(this.mobileNumber, documentKey).subscribe({
        next: (response) => {
          console.log('Document details fetched successfully:', response);
        }
      })
      // localStorage.setItem("fileName", fileName); 
      this.router.navigate(['view-documents'], {
        queryParams: {
          // title: this.getDocumentTitle(documentKey),
          title: documentKey,
          url: file,  // Pass the file URL
          fileName: fileName, // Pass the file name
        }
      }).then(() => {
        // Refresh my-documents state
        this.ngOnInit();
      });
    } else {
      console.warn(`No file uploaded for ${documentKey}.`);
    }
  }  
  
  getDocumentTitle(key: string): string {
    const titles: { [key: string]: string } = {
      gstCertificate: 'Copy of GST Certificate',
      aadharCard: 'Copy of Aadhaar Card',
      panCard: 'Copy of PAN Card',
      establishmentCertificate: 'Copy of Shop Establishment Certificate',
      coi: 'Copy of COI (in case of Company)',
      msmeCertificate: 'Copy of MSME Certificate (in case registered under MSME)'
    };
    return titles[key] || 'Unknown Document';
  }

  deleteFile(type, url) {
    this.documentData.type = type; 
    this.documentData.fileURL = url; 
    this.showConfirmationPopup=true;
  }

  showPopup() {
    this.showConfirmationPopup = true; 
  }

  closePopup(){
    this.showConfirmationPopup = false;
  }

  confirmDelete(): void {    
      const mobile = localStorage.getItem('userMobile'); 
      const deleteType = this.documentData.type; 
      const imgUrl = this.documentData.fileURL;
      
      console.log(mobile, deleteType, imgUrl);

      this.aspViewDocumentService.deleteDocument(mobile, deleteType, imgUrl).subscribe({
        next: (response) => {
          console.log('Delete Response:', response);
          this.toastr.success('File deleted successfully');
          // Clear document data
          this.documentData = { name: '', type: '', fileURL: null };
          this.ngOnInit();
        },
        error: (err) => {
          console.error('Error deleting document:', err);
          alert('An error occurred while deleting the document. Please try again.');
        }
      });
  
    this.showConfirmationPopup = false;
  }
  
  

  cancelDelete() {
    console.log('Delete canceled'); 
    this.showConfirmationPopup = false; 
  }
  getUploadedDocuments(): void {
    let spareUserKey = localStorage.getItem('spareUserKey');
    // this.mobileNumber = localStorage.getItem("contactPhone") 
    const storedDocuments = localStorage.getItem('uploadedDocuments');
    if (storedDocuments) {
      this.uploadedDocuments = JSON.parse(storedDocuments);
    } else {
      this.aspMyDocumentsService.getUploadedDocuments(spareUserKey).subscribe({
        next: (response) => {
          this.certificate = response.data;
          console.log("this.certificate---",this.certificate);
        },
        error: (err) => {
          console.error('Error fetching documents:', err);
        },
      });
    }
  }

  // Get image format
  getImageFormat(url: string): string {
    const extension = url.split('.').pop(); // Split the URL by '.' and get the last part
    return extension || ''; // Return the file extension, or an empty string if not found
  }

  isImage(url): boolean {
    return url?.match(/\.(jpeg|jpg|gif|png)$/i) !== null;
  }
  
  isPDF(url): boolean {
    return url?.endsWith('.pdf');
  }
  
}
