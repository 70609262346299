import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { AspCheckinReportsComponent } from './asp-checkin-reports.component';
// import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
// import { MatIconModule } from '@angular/material/icon';
// import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
// import { MatListModule } from '@angular/material/list';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserModule } from '@angular/platform-browser';

// import { MatDividerModule } from '@angular/material/divider';
import { MatCardModule } from '@angular/material/card';
// import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

const routes:Routes = [
  {
      path: 'checkin',
      component: AspCheckinReportsComponent,
  },
];

@NgModule({
  declarations: [AspCheckinReportsComponent],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    // MatBottomSheetModule,
    MatButtonModule,
    MatIconModule,
    // MatListModule,
    MatDialogModule,
    // MatDividerModule,
    BrowserModule,
    MatCardModule,
    // MatBottomSheet,
    MatFormFieldModule,
    MatInputModule
  ],
  exports:[AspCheckinReportsComponent],
})
export class AspCheckinReportsModule { }
