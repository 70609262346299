import { Component, OnInit,Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'app-asp-add-item-sidebar',
  templateUrl: './asp-add-item-sidebar.component.html',
  styleUrls: ['./asp-add-item-sidebar.component.scss']
})
export class AspAddItemSidebarComponent implements OnInit {
  @Output() close = new EventEmitter<void>();
  itemList: string = '';
  showError: boolean = false;
  isSidebarOpen = false;

  constructor(

  ) { }

  ngOnInit(): void {
  }
  submitItems(): void {
    if (!this.itemList.trim()) {
      this.showError = true; 
    } else {
      this.showError = false; 
      console.log('Items submitted:', this.itemList);
      this.closeSidebar(); 
    }
  }
  openSidebar() {
    this.isSidebarOpen = true;
    console.log('Sidebar opened!');
  }
  closeSidebar() {
    this.isSidebarOpen = false;
    console.log('Sidebar closed!');
  }
}
