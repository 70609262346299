// import { HttpClient } from '@angular/common/http';
// import { Injectable } from '@angular/core';
// import { GlobalVariable } from 'app/main/globals';
// import { Observable } from 'rxjs';

// @Injectable({
//   providedIn: 'root'
// })
// export class AspViewDocumentService {
//   private http: HttpClient;

//   constructor() { }
//   deleteDocument(mobile: string, deleteType: string, imgUrl: string): Observable<any> {
//     const payload = {
//       mobile: mobile,
//       delete: deleteType,
//       imgUrl: imgUrl
//     };

//     return this.http.post(`${GlobalVariable.BASE_API_URL_SPARES}` +'/pitstopspare/user/delete/document', payload, {
//       headers: { 'Content-Type': 'application/json' }
//     });
//   }
// }


import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalVariable } from 'app/main/globals';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AspViewDocumentService {
  constructor(private http: HttpClient) {} // Inject HttpClient here

  deleteDocument(mobile: string, deleteType: string, imgUrl: string) {
    const payload = {
      mobile: mobile,
      delete: deleteType,
      imgUrl: imgUrl,
    };
  
    return this.http.post(`${GlobalVariable.BASE_API_URL_SPARES}/user/delete/document`, payload, {
      headers: { 'Content-Type': 'application/json' }
    }).pipe(
      catchError(error => {
        console.error('Error deleting document:', error);
        throw error; // Rethrow the error after logging it
      })
    );    
  }
  
}
