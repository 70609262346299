<div class="container">
  <div class="my-request">

    <!-- Job Card Sidebar (Find My Car Sidebar) -->
    <div class="overlay" [class.visible]="isVisible" (click)="closeAll()" ></div>
    <!-- <div class="scroll-container" > -->
    <div class="job-card-sidebar sidebar" *ngIf="isVisible" (click)="$event.stopPropagation()">
      <!-- <div class="sidebar-header" (click)="onCreateJobCardClick()"> -->
      <div class="sidebar-header" >
        <h2>Create Job Card</h2>
        <button class="close-sidebar" (click)="closeSidebar(false)">×</button>
      </div>
      <form class="container" [formGroup]="vehicleForm1" (ngSubmit)="onRegDecodeSubmit()">
        <!-- <div *ngIf="!bookingId">
          <label for="name" style="font-weight: bolder;">Enter your name <span class="required">*</span></label>
          <div class="input-group">
            
          <span class="small-img"><mat-icon class="input-icon" alt="">person_outline</mat-icon> NM</span>
            <input id="name" type="text" placeholder="Enter your name" formControlName="name"> -->
           <!-- <TextField id="outlined-basic" label="Outlined" variant="outlined" /> -->
          <!-- </div>
          <div *ngIf="(vehicleForm1.get('name').invalid && vehicleForm1.get('name').touched || formSubmitted) || (vehicleForm1.get('name').invalid && formSubmitted)" style="color: red;">
            Enter Valid Name
          </div>
        </div> -->
    
        <!-- <div *ngIf="!bookingId">
          <label for="phoneNumber" style="font-weight: bolder;">Enter your Mobile Number <span class="required">*</span></label>
          <div class="input-group">
            
          <span class="small-img"><mat-icon class="input-icon" alt=""> phone</mat-icon>+91</span>
            <input id="phoneNumber" type="text" placeholder="Enter your Mobile Number" formControlName="phoneNumber" pattern="[0-9]*" maxlength="10">
          </div>
          <div *ngIf="(vehicleForm1.get('phoneNumber').invalid && vehicleForm1.get('phoneNumber').touched || formSubmitted) || (vehicleForm1.get('phoneNumber').invalid && formSubmitted)" style="color: red;">
            Enter Valid Mobile Number
          </div>
        </div> -->
    
        <!-- <label for="odometer" style="font-weight: bolder;">Enter Odometer Reading <span class="required">*</span></label>
        <div class="input-group">
          <span class="small-img"><img src="assets/images/my-job-card-images/carkm.png" class="input-icon" alt="Car KM"> KM</span>
          <input id="odometer" type="text" placeholder="10000" formControlName="odometer">
        </div>

        <div *ngIf="(vehicleForm1.get('odometer').invalid && vehicleForm1.get('odometer').touched || formSubmitted) || (vehicleForm1.get('odometer').invalid && formSubmitted)" style="color: red;">
          Enter Valid Odometer Reading
        </div> -->
    
        <label for="regNo" style="font-weight: bolder; ">We recommend to enter car number <span class="required">*</span></label>
        <div class="input-group">
          <span class="small-img"><img src="assets/images/my-job-card-images/circle.png" class="input-icon" alt="Car Number"> IND</span>
          <input id="regNo" placeholder="KA 04 JA54**" maxlength="12" style="font-weight: 800;" formControlName="regNo">
        </div>
        <div *ngIf="(vehicleForm1.get('regNo').invalid && vehicleForm1.get('regNo').touched || formSubmitted) || (vehicleForm1.get('regNo').invalid && formSubmitted)" style="color: red;">
        <!-- <div *ngIf="formSubmitted && vehicleForm1.invalid" style="color: red; margin-top: 10px;"> -->
          Please fill Correct Registration Number.
        </div>
    
        <input type="hidden" formControlName="rowKey">
        <input type="hidden" formControlName="variantKey">
    
        <ul class="info-list">
          <li><img src="assets/images/my-job-card-images/ic_check.png" class="circle-img" alt="Check Circle">
            <span class="get">Get accurate details</span>
          </li>
          <li><img src="assets/images/my-job-card-images/ic_check.png" class="circle-img" alt="Check Circle">
            <span class="get">Get 2X faster results</span>
          </li>
        </ul>
    
        <button type="submit" mat-raised-button color="accent" class="submit-btn" aria-label="VEHICLE"   [ngStyle]="{'background-color': vehicleForm1.invalid ? '#888' : '#144579'}"
        [disabled]="vehicleForm1.invalid">
          FIND MY CAR
        </button>
        <!-- <button mat-raised-button color="accent" class="submit-button" aria-label="VEHICLE" [disabled]="vehicleForm1.invalid">
          FIND MY CAR
      </button> -->
        <p class="manual-selection" (click)="redirectToManualSelection()" >
          Or select car manually</p>
      </form>
    </div>
    
    <!-- Confirmation Sidebar (After Find My Car) -->
    <div class="overlay" [class.visible]="isConfirmationSidebarOpen" (click)= "closeAll()"></div>
    <div class="confirmation-sidebar" *ngIf="isConfirmationSidebarOpen && jobCardDetails" (click)="$event.stopPropagation()">
        <div class="confirmation-content">
          <!-- <div class="header-container">
            <mat-icon class="back-arrow" (click)="toggleConfirmationSidebar()">arrow_back</mat-icon>
            <mat-icon style="color: #144579;">directions_car</mat-icon>
          </div> -->
          <div class="logo-container">
            <div class="logoandtext">
              <img class="logoimg"src="../../../../assets/images/logos/mobile_autoverse_logo.svg" alt="Autoverse Logo">
              <p style="font-size: 12px;"><strong>AUTOVERSE</strong></p>
            </div>
            <!-- <img class="shareimg"src="../../../../assets/images/share.png" alt="Share" (click)="shareDetails()" > -->
          </div>
          <h2 class="registration-number">{{jobCard.registrationNumber}}</h2>
          <div class="confirmdiv">Confirm your car details</div>
          <div class="details-item">
            <img src="assets/images/my-job-card-images/ic_check.png" alt="ic_check" class="circle-img">
            <div class="label">Make:</div>
            <div class="value">{{ jobCard.make }}</div>
          </div>
          <div class="details-item">
            <img src="assets/images/my-job-card-images/ic_check.png" alt="ic_check">
            <div class="label">Model:</div>
            <div class="value">{{ jobCard.model }}</div>
          </div>
          <div class="details-item">
            <img src="assets/images/my-job-card-images/ic_check.png" alt="ic_check">
            <div class="label">Variant:</div>
            <div class="value">{{ jobCard.variant }}</div>
          </div>
          <div class="details-item">
            <img src="assets/images/my-job-card-images/ic_check.png" alt="ic_check">
            <div class="label">MFG Year:</div>
            <div class="value">{{ jobCard.mfgYear }}</div>
          </div>
          <div class="details-item">
            <img src="assets/images/my-job-card-images/ic_check.png" alt="ic_check">
            <div class="label">Fuel:</div>
            <div class="value">{{ jobCard.fuelType }}</div>
          </div>
          <div class="details-item">
            <img src="assets/images/my-job-card-images/ic_check.png" alt="ic_check">
            <div class="label">VIN:</div>
            <div class="value">{{ jobCard.vin }}</div>
          </div>
          <button class="confirm-btn" (click)="confirmDetails()">Confirm</button>
          <button class="change-car-btn" (click)="toggleConfirmationSidebar()">Change Car</button>
        </div>
    </div>

    <!-- Job Card Created Pop-up Modal -->
    <div class="overlay" [class.visible]="isJobCardCreatedPopupVisible" (click)= "closeJobCardPopup()"></div>
    <!-- <div class="confirmation-sidebar" *ngIf="isConfirmationSidebarOpen && jobCardDetails" (click)="$event.stopPropagation()"> -->
    <div *ngIf="isJobCardCreatedPopupVisible" class="modal-overlay">
      <div class="modal-content">
        <div class="modal-header">
          <img src="{{successIcon}}" alt="Success Icon" class="success-icon">
          <h2>Job Card Created</h2>
          <button class="close-modal" (click)="closeJobCardPopup()">×</button>
        </div>
        <div class="modal-body">
          <p>Job Card ID: <span class="job-card-id">{{jobCardId}}</span></p>
          <p><span class="createdAt1">{{date}}</span></p>
          <p>Let us deliver an awesome experience!</p>
        </div>
        <div class="modal-footer">
          <button class="share-job-card-btn" (click)="shareOnWhatsApp()">SHARE JOB CARD</button>
        </div>
      </div>
    </div>
  <!-- </div> -->
</div>

  <div #scrollContainer class="side-slider" *ngIf="customerDetailsANDcarDetails && !jobCardDetails">
    <div class="side-slider-content">
      <div class="backspare-and-history-btn">
        <div class="close-btn-side-slider" (click)="goBack()" style="display: flex;">
            <mat-icon>keyboard_backspace</mat-icon>
            <div class="exterior-top"*ngIf="exteriorSection">Exterior Checks</div>
        </div>
        <div class="changebtn" *ngIf="showServiceHistory">
          <button class="history-btn" (click)="openServiceHistory()">SERVICE HISTORY</button>
        </div>
      </div>
      <div class="car-header">
        <div class="car-info">
          <!-- <img class="car-image" src="car-image-url" alt="Car" /> -->
          <div class="car-image">
            <img [src]="getMakeImageUrl(jobCard.make)" alt="Car Make Image" class="model-icon">
          </div>
          <div>
            <div>{{ jobCard.model }}, {{ jobCard.fuelType }}</div>
            <div style="display: flex">
              <div class="variantyearrn">{{ jobCard.variant }}</div>
              <div class="variantyearrn">[{{jobCard.mfgYear}}]</div>
            </div>
            <div class="variantyearrn">{{ jobCard.registrationNumber}}</div>
            <div class="variantyearrn">{{ jobCard.vin}}</div>
          </div>
        </div>
        <div cla="changebtn">
          <button class="change-btn"(click)="onChangeCarClick()">Change</button>
        </div>
      </div>
      <!-- <hr /> -->
      <div *ngIf="carDetailsandcustomerDetails">
        <form [formGroup]="carDetailsForm">
        <div class="car-details">
          <div style="display: flex;">
            <h3>Car Details </h3>
            <img src="../../../../assets/images/icons8-car-100.png" class="car-details-imgage">
          </div>
          <form>
            <div *ngIf="isManualSelection">
              <mat-form-field appearance="outline" class="form-field" [hideRequiredMarker]="true">
                <mat-label>
                  Reg Number<span class="required-asterisk">*</span>
                </mat-label>
                <input
                  matInput
                  placeholder="Enter Registration Number"
                  [(ngModel)]="car.regNumber"
                  name="regNumber"
                  required
                  id="regNumber"
                  class="custom-placeholder"
                />
                <mat-error *ngIf="!car.regNumber">
                  Registration Number is required.
                </mat-error>
              </mat-form-field>
              <mat-form-field appearance="outline" class="form-field" [hideRequiredMarker]="true">
                <mat-label>VIN</mat-label>
                <input
                  matInput
                  placeholder="Enter VIN"
                  [(ngModel)]="car.vin"
                  name="vin"
                  id="vin"
                  class="custom-placeholder"
                />
              </mat-form-field>
            </div>
            <mat-form-field appearance="outline" class="form-field" [hideRequiredMarker]="true">
              <mat-label>
                Engine Number
              </mat-label>
              <input
                matInput
                id="engineNumber"
                placeholder="Enter Engine Number"
                [(ngModel)]="car.engineNumber"
                
              />
            </mat-form-field>
            
            <mat-form-field appearance="outline" class="form-field" [hideRequiredMarker]="true">
              <mat-label>
                Odometer Reading<span class="required-asterisk">*</span>
              </mat-label>
              <input
                matInput
                placeholder="Enter Odometer Reading"
                [(ngModel)]="car.odometerReading"
                name="odometerReading"
                required
                id="odometerReading"
                class="custom-placeholder"
              />
              <mat-error *ngIf="rf.odometerReading?.hasError('required')">
                Enter Odometer Reading.
              </mat-error>
              <mat-error *ngIf="rf.odometerReading?.hasError('pattern')">
                Odometer reading must be numeric.
              </mat-error>
            </mat-form-field>            

            <mat-form-field appearance="outline" >
              <mat-label>Last Service Date</mat-label>
              <input matInput [matDatepicker]="picker"  />
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>

            <div class="service-form-group">
              <div class="dropdown-container" (click)="toggleDropdown()">
                <div style="display: flex; width: 100%;">
                  <div class="input-container">
                    <label for="type-select">Service Type<span class="required">*</span></label>
                  </div>
                  <mat-icon style="top: 1px; position: relative;">keyboard_arrow_down</mat-icon>
                </div>
              </div>
              <div  class="error-message-service" style="font-weight: 900;  font-size: 12px; margin-left: 6px; margin-top: 5px;">
                Please Select Service Type.
              </div>
              <div class="dropdoencompleteitems" *ngIf="isDropdownOpen">
                <div class="dropdown-options" id="dropdownOptions">
                  <div *ngFor="let service of serviceList" class="dropdown-item">
                    <label> 
                      <div class="dropdownitemnames">{{ service }}</div>
                      <input
                        type="checkbox"
                        [checked]="car.selectedServices.includes(service)"
                        (change)="selectService(service, $event)"
                      />
                    </label>
                  </div>
                </div>
              </div>
              <!-- Chips display for selected services -->
              <div *ngIf="car.selectedServices.length > 0">
                <div class="chips-container">
                  <div class="chip" *ngFor="let service of car.selectedServices">
                    {{ service }}
                    <span class="close-icon" (click)="removeService(service)">×</span>
                  </div>
                </div>
              </div>
            </div>
            
          </form>
        </div>
        <div *ngIf="errorMessage" class="error-message-new">
          {{ errorMessage }}
        </div>
        <!-- -------------------------CARDETAILS FIELD--------------- -->
        <div class="car-details">
          <div class="customerdetailsandicon">
            <div style="display: flex;">
              <h3>Customer Details</h3>
              <img src="../../../../assets/../assets/images/icons8-customer-100.png" class="customer-details-imgage">
            </div>
          </div>
          <form>
            <mat-form-field appearance="outline" class="form-field" [hideRequiredMarker]="true">
              <mat-label>
                Name<span class="required-asterisk">*</span>
              </mat-label>
              <input
                matInput
                placeholder="Enter Name"
                [(ngModel)]="customer.name"
                name="customerName"
                required
              />
              <mat-error *ngIf="rf.customerName?.hasError('required')">
                Enter the Customer's Name.
              </mat-error>
              <mat-error *ngIf="rf.customerName?.hasError('pattern')">
                Name should only contain letters.
              </mat-error>
            </mat-form-field>
            
            <mat-form-field appearance="outline" class="form-field" [hideRequiredMarker]="true">
              <mat-label>
                Mobile<span class="required-asterisk">*</span>
              </mat-label>
              <input
                matInput
                placeholder="Enter Mobile Number"
                [(ngModel)]="customer.mobile"    name="customerMobile"   required maxlength="10"  pattern="[0-9]*"
                
              />
              <mat-error *ngIf="rf.customerMobile?.hasError('required')">
                Enter Mobile Number.
              </mat-error>
              <mat-error *ngIf="rf.customerMobile?.hasError('minlength')">
                Mobile number must be exactly 10 digits.
              </mat-error>
              <mat-error *ngIf="rf.customerMobile?.hasError('maxlength')">
                Mobile number must be exactly 10 digits.
              </mat-error>
            </mat-form-field>
            
            <mat-form-field appearance="outline" class="form-field">
              <mat-label>Email</mat-label>
              <input
                matInput
                placeholder="Enter Email Address"
                type="email"
                [(ngModel)]="customer.email"
                name="customerEmail"
              />
            </mat-form-field>
            
          </form>
        </div>
        <div class="classnext">
          <button class="create-order-btn" (click)="saveDetails()">NEXT</button>
        </div>

        <!-- <div class="classnext">
          <button
            type="submit"
            mat-raised-button
            color="accent"
            class="create-order-btn"
            aria-label="NEXT"
            [ngStyle]="{
              'background-color': carDetailsForm.invalid ? '#888' : '#144579',
              'color': carDetailsForm.invalid ? '#fff' : '#fff'
            }"
            [disabled]="carDetailsForm.invalid"
            (click)="saveDetails()"
          >
            NEXT
          </button>
        </div> -->
        
      </form>
      </div>
      
      <!-- --------------------FUEL TYPE SECTION---------------- -->
      <div #scrollContainer *ngIf="fuelAccessoriesContainer && !carDetailsandcustomerDetails" class="fuel-accessories-container">
        <!-- Fuel Level Section -->
        <div class="fuel-level-section">
          <!-- <label for="fuel-level">Fuel Level</label> -->
          <p>Fuel Level: {{ fuelAndAccessories.fuelLevel }}%</p>
          <div >
            <span>E</span>
            <!-- Bind the fuel level input to fuelAndAccessories.fuelLevel and show percentage -->
            <input
              type="range"
              min="0"
              max="100"
              [(ngModel)]="fuelAndAccessories.fuelLevel"
              (ngModelChange)="updateFuelLevel($event)"
            />
            <span>F</span>
          </div>
        </div>
      
        <!-- Accessories Section -->
        <div class="accessories-section">
          <h3>Accessories</h3>
          <ul>
            <li *ngFor="let accessory of accessories">
              <img [src]="accessory.imageUrl" [alt]="accessory.description" />
              <label>
                {{ accessory.description }}
                <!-- Update the checked state based on fuelAndAccessories.checkedItems -->
                <input
                  type="checkbox"
                  [checked]="fuelAndAccessories.checkedItems.includes(accessory.description)"
                  (change)="selectAccessory(accessory.description, $event)"
                />
              </label>
            </li>
          </ul>
          <div class="classnext">
            <button class="create-order-btn" (click)="goToIssuesSection()">NEXT</button>
          </div>
        </div>
      </div>
      
      <!-- --------------------FUEL TYPE SECTION END---------------- -->
      <!-- --------------------ISSUES SECTION---------------- -->
      <div #scrollContainer *ngIf="issuesSection && !fuelAccessoriesContainer" class="issues-section">
        <div class="issues-header">
          <div style="display: flex;">
            <mat-icon class="warning-maticon">warning</mat-icon>
            <h3>Do you have more issues?</h3>
          </div>
          <p style="color: #888;">
            Please mention any symptoms (issues) you have noticed in your car. This
            will help our technician to diagnose better.
          </p>
        </div>
        <div class="SearchBarContainer" (click)="toggleSearch()">
          <div class="SearchBarButton">
            <div class="SearchBarIcon">
              <i class="material-icons-search">search</i>
            </div>
            <input  type="text" placeholder="Search Your Issues"  [(ngModel)]="searchText" (input)="filterIssues()"  class="SearchBarInput"    /> 
            <div class="remove-search-suggestion" *ngIf="filteredIssues"(click)="clearSearch()" >
              <mat-icon>close</mat-icon>
            </div>             
        </div>
        </div>
        <div class="issues-section">
          <ul>
            <li *ngFor="let issue of filteredIssues" [ngClass]="{ 'highlighted-item': issue.isHighlighted }">
              <label [innerHTML]="highlightMatch(issue, searchText)"></label>
              <input
                type="checkbox"
                [checked]="issue.selected"
                (change)="toggleIssue(issue, $event)"
              />
              {{ issue.description }}
            </li>
          </ul>
        </div>
        <div *ngIf="selectedIssues.length > 0" >
          <div  class="issue-chips-container">
            <div class="chip" *ngFor="let issue of selectedIssues">
              {{ issue }}
              <span class="close-icon" (click)="removeIssue(issue)">×</span>
            </div>
          </div>
        </div>
          
        <!-- </div> -->
        <div class="classnext">
          <button class="create-order-btn" (click)="saveIssue()" >NEXT</button>
        </div>
      </div>
      <!-- --------------------ISSUES SECTION END---------------- -->

       <!-- --------------------EXTERIOR SECTION---------------- -->
        
       <div #scrollContainer *ngIf="exteriorSection && !issuesSection" class="exterior-section">
        <div class="exterior-tabs">
          <button 
            class="tab" 
            [class.active]="selectedTab === 'Front'" 
            (click)="selectTab('Front')">Front</button>
            <button 
            class="tab" 
            [class.active]="selectedTab === 'Rear'" 
            (click)="selectTab('Rear')">Rear</button>
            <button 
            class="tab" 
            [class.active]="selectedTab === 'Right'" 
            (click)="selectTab('Right')">Right</button>
            <button 
            class="tab" 
            [class.active]="selectedTab === 'Left'" 
            (click)="selectTab('Left')">Left</button>
          <button 
            class="tab" 
            [class.active]="selectedTab === 'Top'" 
            (click)="selectTab('Top')">Top</button> 
        </div>
      
        <div class="exterior-content">
          <div class="penciliconandtext">
            <p>Mark <span class="red-x">X</span> where damaged</p>
            <button class="pencil-icon" (click)="enableDrawing()" [ngClass]="{'active': isDrawing}">
              <!-- <mat-icon [ngStyle]="{'color': isDrawing ? selectedColor : 'black'}">edit</mat-icon> -->
            </button>
            
            <button class="undo-icon" (click)="undoDrawing()">
              <mat-icon class="undoicon">undo</mat-icon>
            </button>
          </div>
      
          <div class="car-image-container">
            <div class="image-canvas-container" (mousedown)="activateDrawing($event)">
              <img *ngIf="selectedTab === 'Front'" src="../../../../assets/images/front-image1.jpg" alt="Front View">
              <img *ngIf="selectedTab === 'Top'" src="../../../../assets/images/car-top.jpg" alt="Top View">
              <img *ngIf="selectedTab === 'Rear'" src="../../../../assets/images/Rear-image.jpg" alt="Rear View">
              <img *ngIf="selectedTab === 'Right'" src="../../../../assets/images/right-image.jpg" alt="Right View">
              <img *ngIf="selectedTab === 'Left'" src="../../../../assets/images/left-image.jpg" alt="Left View">
      
              <canvas #drawingCanvas class="drawing-canvas" (mousedown)="startDrawing($event)"
                (mousemove)="draw($event)"
                (mouseup)="stopDrawing()"
                (mouseleave)="stopDrawing()"></canvas>
            </div>
          </div>
      
          <!-- <button class="save-icon" (click)="saveMarkedImage()">Save</button> -->
        </div>
        <div *ngIf="isImageSaved" class="saved-image-container">
          <!-- Loop through only tabs with damaged images -->
          <div *ngFor="let tab of getDamagedTabs()" class="saved-image-item">
            <p>{{ tab }}:</p>
            <img [src]="damagedImagesData[tab]" alt="{{ tab }} Marked Image">
          </div>
        </div>  
      
        <div class="classnext">
          <button class="create-order-btn" (click)="goToNextTab()">NEXT</button>
        </div>
      </div>
    
      <!-- --------------------EXTERIOR SECTION END---------------- -->

      <!-- --------------------IMAGE SECTION ---------------- -->
      <div #scrollContainer *ngIf="imageSection && !exteriorSection" class="image-section">
        <div class="image-grid">
          <div>
            <div class="image-box" (click)="triggerFileInput('front')">
              <ng-container *ngIf="frontImage; else defaultIcon">
                <img [src]="frontImage" alt="Front" />
              </ng-container>
              <ng-template #defaultIcon>
                <mat-icon>image</mat-icon>
              </ng-template>
            </div>
            <span class="image-gridtext">Front</span>
          </div>
          <div>
            <div class="image-box" (click)="triggerFileInput('engine')">
              <ng-container *ngIf="engineImage; else defaultIcon">
                <img [src]="engineImage" alt="Engine" />
              </ng-container>
              <ng-template #defaultIcon>
                <mat-icon>image</mat-icon>
              </ng-template>
            </div>
            <span class="image-gridtext">Engine</span>
          </div>
          <div>
            <div class="image-box" (click)="triggerFileInput('battery')">
              <ng-container *ngIf="batteryImage; else defaultIcon">
                <img [src]="batteryImage" alt="Battery" />
              </ng-container>
              <ng-template #defaultIcon>
                <mat-icon>image</mat-icon>
              </ng-template>
            </div>
            <span class="image-gridtext">Battery</span>
          </div>
          <div>
            <div class="image-box" (click)="triggerFileInput('right')">
              <ng-container *ngIf="rightImage; else defaultIcon">
                <img [src]="rightImage" alt="Right" />
              </ng-container>
              <ng-template #defaultIcon>
                <mat-icon>image</mat-icon>
              </ng-template>
            </div>
            <span class="image-gridtext">Right</span>
          </div>
          <div>
            <div class="image-box" (click)="triggerFileInput('back')">
              <ng-container *ngIf="backImage; else defaultIcon">
                <img [src]="backImage" alt="Back" />
              </ng-container>
              <ng-template #defaultIcon>
                <mat-icon>image</mat-icon>
              </ng-template>
            </div>
            <span class="image-gridtext">Back</span>
          </div>
          <div>
            <div class="image-box" (click)="triggerFileInput('left')">
              <ng-container *ngIf="leftImage; else defaultIcon">
                <img [src]="leftImage" alt="Left" />
              </ng-container>
              <ng-template #defaultIcon>
                <mat-icon>image</mat-icon>
              </ng-template>
            </div>
            <span class="image-gridtext">Left</span>
          </div>
          <!-- <div class="new-image-grid"> -->
          <div id="added-img">
            <!-- <div id="otherimages" class="image-grid"> -->
              <!-- Dynamically added images will appear here -->
            <!-- </div> -->
          </div>
          
          <!-- <div>
            <div class="image-box" (click)="triggerFileInput('add')">
              <ng-container *ngIf="addImage">
                <img [src]="addImage" />
              </ng-container>
                <mat-icon>add</mat-icon>
            </div>
            <span class="image-gridtext">Add</span>
          </div> -->

          <div>
            <div class="image-box" (click)="openBottomSheet('add')">
              <ng-container *ngIf="addImage">
                <img [src]="addImage" />
              </ng-container>
              <!-- <ng-container> -->
                <mat-icon>add</mat-icon>
              <!-- </ng-template> -->
            </div>
          </div>
        </div>
      
        <input
          #frontFileInput
          type="file"
          accept="image/*"
          style="display: none"
          (change)="onImageSelected('front', $event)"
        />
        <input
          #engineFileInput
          type="file"
          accept="image/*"
          style="display: none"
          (change)="onImageSelected('engine', $event)"
        />
        <input
          #batteryFileInput
          type="file"
          accept="image/*"
          style="display: none"
          (change)="onImageSelected('battery', $event)"
        />
        <input
          #rightFileInput
          type="file"
          accept="image/*"
          style="display: none"
          (change)="onImageSelected('right', $event)"
        />
        <input
          #backFileInput
          type="file"
          accept="image/*"
          style="display: none"
          (change)="onImageSelected('back', $event)"
        />
        <input
          #leftFileInput
          type="file"
          accept="image/*"
          style="display: none"
          (change)="onImageSelected('left', $event)"
        />
        <input
          #addFileInput
          type="file"
          accept="image/*"
          style="display: none"
          (change)="onImageSelected('add', $event)"
        />
        <div class="classnext">
          <button class="create-order-btn"(click)="createJobCard()">NEXT</button>
        </div>
      </div>
      
      
      <!-- --------------------IMAGE SECTION END---------------- -->


    </div>
  </div>
  <!-- Bottom Sheet -->
<div class="bottom-sheet" *ngIf="isBottomSheetOpen">
  <div class="bottom-sheet-content">
    <div style="display: flex;">
      <button class="close-button-text-img" mat-icon-button (click)="closeBottomSheet()">
        <mat-icon>close</mat-icon>
      </button>
      <h3>Enter Image Tag </h3>
    </div>
    <mat-form-field appearance="outline">
      <mat-label>Image Tag</mat-label>
      <input matInput [(ngModel)]="otherTag">
    </mat-form-field>
    <input type="file" accept="image/*" (change)="onOtherImageSelected($event)" />
    <div class="save-new-have">
      <button (click)="saveTag()">Save Tag</button>
    </div>
  </div>
</div>
</div>

<div *ngIf="loading">
  <div #dynamicContainers></div>
</div>


<ng-container #vehicleServiceHistoryContainer></ng-container>