import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AspPaymentlistService } from './asp-paymentlist.service';
import { ToastrService } from 'ngx-toastr'; // Import ToastrService
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-asp-paymentlist',
  templateUrl: './asp-paymentlist.component.html',
  styleUrls: ['./asp-paymentlist.component.scss']
})
export class AspPaymentlistComponent implements OnInit {
  payments: any[] = [];
  filteredPayments: any[] = [];
  isSidebarVisible: boolean = false;
  paymentLinkCreated: boolean = false;
  loading: boolean = false;  // Track the loading state
  showCancelConfirmation: boolean = false;
  selectedPayment: any;
  showCancelSuccess: boolean = false;
  createdPaymentLink: any;
  isCopied: boolean = false;
  successIcon: string;
  searchText: any = '';
  createdPaymentLinkId: any;
  date: string;
  paidAmount: string;
  status: string;
  garageKey: string;
  userId: string;
  lastSelectedStatus: string = 'All'; // Keep track of the last selected status

  // Initialize newPayment object to avoid undefined errors
  newPayment: any = {
    contactName: '',
    paymentFor: '',
    amount: '',
    paidAmount: '',
    linkId: '',
    status: '',
    date: '',
    mobile: ''
  };
  linkId: any;
  createdPaymentLink1: any;

  // Inject ToastrService into the constructor
  constructor(
    private aspPaymentlistService: AspPaymentlistService,
    private toastr: ToastrService  // Inject ToastrService
  ) {}

  ngOnInit(): void {
    this.successIcon = '../../../../assets/icons/icons8-ok.gif';
    this.fetchStoredPayments(this.lastSelectedStatus); 
  }

  // Fetch stored payments with the selected status filter
  fetchStoredPayments(status: string = 'All'): void {
    const garageKey = localStorage.getItem('garageKey');
    const userId = localStorage.getItem('spareUserKey');

    this.aspPaymentlistService.storeUserDetails(garageKey, this.searchText, status, userId).subscribe(
      (response) => {
        if (response && response.code === 200) {
          console.log("response--",response);
          this.payments = response.data.payments;
          this.createdPaymentLink1=response.data.payments.paymentLink;
          this.payments.sort((a, b) => Number(b.paymentId) - Number(a.paymentId));
          this.filteredPayments = [...this.payments];
          console.log("filtered---",this.filteredPayments)
        } else {
          console.error('API response does not contain payments array:', response);
          // this.toastr.error('Failed to fetch payments', 'Error');
          // this.payments = [];
        }
      },
      (error: HttpErrorResponse) => {
        console.error('Error fetching stored payments:', error);
        // this.toastr.error('Error fetching stored payments', 'Error');
        this.payments = [];
      }
    );
  }

  filterPayments(status: string): void {
    // Check if the clicked status is the same as the last selected
    if (this.lastSelectedStatus === status) {
      
      this.lastSelectedStatus = 'All';
      this.fetchStoredPayments('All');
    } else {
      // Otherwise, apply the selected status filter
      this.lastSelectedStatus = status;
      this.fetchStoredPayments(status);
    }
  }

  isInputVisible: boolean = false; // Flag to control input visibility

  toggleSearch(): void {
    // Toggle the visibility of the search input
    this.isInputVisible = !this.isInputVisible;

    // Automatically focus the input if it becomes visible
    if (this.isInputVisible) {
      setTimeout(() => {
        const inputElement = document.querySelector('.SearchInput') as HTMLInputElement;
        if (inputElement) {
          inputElement.focus(); // Focus on the input when visible
        }
      }, 0);
    }
  }

  clearSearch(event: Event): void {
    event.stopPropagation();
    this.searchText = ''; // Reset search text
    this.isInputVisible = false; // Hide the input field and show the placeholder again
    this.fetchStoredPayments(this.lastSelectedStatus); // Reload the full list based on the last status filter
  }


submitPayment(form: NgForm): void {
  if (form.invalid) {
    this.toastr.error('Please fill in all required fields correctly before creating the payment link.', 'Form Error');
    return;  
  }

  if (this.loading) {
    return;
  }

  // Set loading to true to prevent further submissions
  this.loading = true;

  const garageKey = localStorage.getItem('garageKey');
  const userId = localStorage.getItem('spareUserKey');

  if (!garageKey || !userId) {
    this.toastr.error('Garage Key or User ID is missing', 'Error');
    this.loading = false;
    return;
  }

  // Proceed with payment link creation
  this.aspPaymentlistService.getUserDetails(
    this.newPayment.contactName,
    this.newPayment.amount,
    garageKey,
    this.newPayment.mobile,
    this.newPayment.paymentFor,
    userId
  ).subscribe(
    (response) => {
      console.log("response---", response);
      this.newPayment.linkId = response.data.id;
      this.newPayment.status = 'Pending';
      // Use a consistent date format for the new payment
      this.newPayment.date = new Date().toISOString(); // Use ISO format to ensure consistent sorting
      this.newPayment.paidAmount = '0';
      this.createdPaymentLinkId = response.data.id;
      this.createdPaymentLink = response.data.paymentLink;
      console.log("createdpaymntLink------", this.createdPaymentLinkId);
      this.date = new Date().toISOString();
      this.paidAmount = '0';
      this.status = 'Pending';
      this.payments.unshift({ ...this.newPayment });
      this.payments.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
      this.filteredPayments = [...this.payments];
      this.paymentLinkCreated = true;
      this.toastr.success('Payment link created successfully', 'Success');
      this.closeSidebar();
      this.clearNewPayment();
    },
    (error) => {
      console.error('Error creating payment link:', error);
      this.toastr.error('Failed to create payment link', 'Error');
      this.loading = false;
    },
    () => {
      this.loading = false;
    }
  );
}

  cancelPayment(payment: any): void {
    const garageKey = localStorage.getItem('garageKey');
    const userId = localStorage.getItem('spareUserKey');

    this.aspPaymentlistService.cancelUserDetails(garageKey, payment.paymentId, userId).subscribe(
      (response) => {
        this.showCancelConfirmation = false;

        if (response && response.code === 200) {
          payment.status = 'Cancelled';
          const index = this.payments.findIndex(p => p.linkId === payment.linkId);
          console.log("payment.linkId----",payment.paymentId)
          if (index !== -1) {
            this.payments[index] = payment;
          }

          this.filteredPayments = [...this.payments];
          this.showCancelSuccess = true;
          this.toastr.success('Payment successfully cancelled', 'Success');
          this.fetchStoredPayments(this.lastSelectedStatus);
        } else {
          console.error('Failed to cancel payment:', response);
          // this.toastr.error('Cannot cancel or expire an already paid/partially paid link', 'Error');
        }
      },
      (error: HttpErrorResponse) => {
        console.error('Error cancelling payment:', error);
        // this.toastr.error('Failed to cancel payment due to server error', 'Error');
      }
    );
  }

  clearNewPayment(): void {
    this.newPayment = {
      contactName: '',
      paymentFor: '',
      amount: '',
      paidAmount: '',
      linkId: '',
      status: '',
      date: '',
      mobile: ''
    };
  }

  confirmCancelPayment(payment: any): void {
    this.selectedPayment = payment;
    this.showCancelConfirmation = true;
  }

  closeCancelPopup(): void {
    this.showCancelConfirmation = false;
  }

  closeSuccessPopup(): void {
    this.showCancelSuccess = false;
    this.paymentLinkCreated = false;
    this.fetchStoredPayments(this.lastSelectedStatus);
  }

  handleOutsideClick(event: Event): void {
    if (this.isSidebarVisible) {
      this.closeSidebar();
    }
  }

  openSidebar(): void {
    this.isSidebarVisible = true;
  }

  closeSidebar(): void {
    this.isSidebarVisible = false;
    this.clearNewPayment();
  }

  shareOnWhatsApp(): void {
    if (!this.createdPaymentLink) {
      this.createdPaymentLink = this.generateRandomLink();
    }

    const message = `Hi Customer👋,\n\nThanks for choosing Ashish Garage for your repair needs!🙌\n\nPlease make the payment at your earliest convenience to initiate the repair process.\n\n💳Please find your payment link below:\n${this.createdPaymentLink}`;
    const url = `https://web.whatsapp.com/send?text=${encodeURIComponent(message)}`;
    window.open(url, '_blank');
    this.toastr.success('The payment link has been shared on WhatsApp', 'Success');
  }

  generateRandomLink(): string {
    const randomId = Math.random().toString(36).substring(7);
    return `https://autoverse.com/payment/${randomId}`;
  }

  copyToClipboard(): void {
    if (this.createdPaymentLink) {
      navigator.clipboard.writeText(this.createdPaymentLink).then(() => {
        this.isCopied = true;
        this.toastr.success('Payment link copied to clipboard', 'Success');
        setTimeout(() => {
          this.isCopied = false;
        }, 2000);
      }).catch(err => {
        console.error('Failed to copy: ', err);
        this.toastr.error('Cannot cancel or expire an already paid/partially paid link', 'Error');
      });
    }
  }

  filterSuggestions(): void {
    const garageKey = localStorage.getItem('garageKey');
    const userId = localStorage.getItem('spareUserKey');

    if (!garageKey || !userId) {
      this.toastr.error('Garage Key or User ID is missing in localStorage.', 'Error');
      return;
    }

    this.aspPaymentlistService.storeUserDetails(garageKey, this.searchText, this.lastSelectedStatus, userId).subscribe(
      (response) => {
        if (response && response.code === 200) {
          this.filteredPayments = response.data.payments;
          this.payments.sort((a, b) => b.paymentId - a.paymentId);
          // this.filteredPayments = [...this.payments];
        } else {
          this.filteredPayments = [];
          console.error('Error retrieving filtered payments:', response);
          this.toastr.error('Error retrieving filtered payments', 'Error');
        }
      

      },
      (error: HttpErrorResponse) => {
        console.error('Error filtering payments:', error);
        this.toastr.error('Error filtering payments', 'Error');
        this.filteredPayments = [];
      }
    );
  }
}

