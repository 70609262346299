import { Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { AspHomeService } from './asp-home.service';
import { ToastrService } from 'ngx-toastr';
import { AspLoginService } from '../asp-login/asp-login.service';
import { fuseAnimations } from '@fuse/animations';
import { FormBuilder, FormGroup, Validators,AbstractControl, ValidatorFn} from '@angular/forms';
import { AspMakeComponent } from '../asp-make/asp-make.component';
import { AspModelComponent } from '../asp-model/asp-model.component';
import { AspYearComponent } from '../asp-year/asp-year.component';
import { AspVariantComponent } from '../asp-variant/asp-variant.component';
import { AspMakeService } from '../asp-make/asp-make.service';
import { AspDynamicPopupComponent } from '../asp-dynamic-popup/asp-dynamic-popup.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {SearchSuggestionService} from 'app/shared/search-suggestion.service'
import { locale } from 'moment';
import { GoogleAnalyticsService } from 'app/shared/google-analytics.service';
// import { SpareKitSidebarComponent } from '../spare-kit-sidebar/spare-kit-sidebar.component';


export function maxLengthValidator(maxLength: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (control.value && control.value.length > maxLength) {
      return { 'maxLength': { value: control.value } };
    }
    return null;
  };
}



@Component({
  selector: 'app-asp-home',
  templateUrl: './asp-home.component.html',
  styleUrls: ['./asp-home.component.scss'],  
  animations: fuseAnimations
})
export class AspHomeComponent implements OnInit {  
  loading: boolean = false;
  spareSelectedGarageName: any;
  brands: any = [];
  categories: any = [];
  allCategories: any = [];
  veiwMoreCat: boolean = true;
  veiwLessCat: boolean = false;  
  formData: any = {}; 
  vehicleForm: FormGroup;  
  submitted = false;
  makeList: any = [];
  vehicleInfo: boolean = false;
  vehicleData: any = [];
  searchText = ''
  suggestions: string[] = [];
  selectedSuggestions: Set<string> = new Set<string>();
  isVisible: boolean;
  isConfirmationSidebarOpen: boolean;

  components = {
    makeComp: AspMakeComponent,
    modelComp: AspModelComponent,
    yearComp: AspYearComponent,
    variantComp: AspVariantComponent
  };
  isMobile: boolean = false;
  isInputVisible: boolean;
  autoScrollInterval: any;
autoScrollActive = true;

  constructor(
    private router: Router,
    private homeService: AspHomeService,
    public toastr: ToastrService,
    private aspLoginService: AspLoginService,    
    private _formBuilder: FormBuilder,
    private aspMakeService: AspMakeService,
    
    private bottomSheet: MatBottomSheet,
    private breakpointObserver: BreakpointObserver,
    private searchSuggestionService:SearchSuggestionService,
    private resolver: ComponentFactoryResolver,
    private googleAnalyticsService: GoogleAnalyticsService,
  ) {
      this.breakpointObserver.observe([Breakpoints.Handset])
    .subscribe(result => {
      this.isMobile = result.matches;
    }); }

  
  ngOnInit(): void { 
    localStorage.removeItem('filterParams');
    localStorage.removeItem('selectedCategory');
    localStorage.removeItem('selectedBrands');
    localStorage.removeItem('selectedRequestStatus');
    localStorage.removeItem('selectedRequestTime');
    localStorage.removeItem('isDynamicPopup'); 
    localStorage.removeItem('registrationNumber');
    this.loading = true;    
    this.vehicleForm = this._formBuilder.group({
        regNo: ['', [Validators.required,maxLengthValidator(12)]]
    });

    this.vehicleForm.get('regNo')?.valueChanges.subscribe(value => {
      if (value) {
        // this.vehicleForm.get('regNo')?.setValue(value.toUpperCase(), { emitEvent: false });
        const formattedValue = this.formatRegNo(value);
        this.vehicleForm.get('regNo')?.setValue(formattedValue, { emitEvent: false });
      }
    });
    
    this.aspLoginService.checkLogin();
    this.spareSelectedGarageName = localStorage.getItem("spareSelectedGarageName");
    this.getPopularBrandsAndCategory();
    this.getTopMake();

    this.vehicleData = JSON.parse(localStorage.getItem("vehicleData"));
    if(this.vehicleData){
      this.vehicleInfo = true;
    }else{
      this.vehicleInfo = false;
    }
    if (window.innerWidth >= 768) {
      setTimeout(() => {
        this.autoScrollInterval = setInterval(() => {
          if (this.autoScrollActive) this.scrollRight();
        }, 5000);
      }, 5000);
    }
    
    
    this.clearLocalStorage();
  }

  get f() { return this.vehicleForm.controls; }

  onSubmit() {
    this.submitted = true;
    if (this.vehicleForm.invalid) {
        return;
    }

    this.homeService.regDecode(this.f.regNo.value.replace(/\s/g, "")).subscribe(
      res => {
        console.log("regdecorresponse",res)
        if(res['code']==200){
          this.loading = false;
          this.vehicleInfo = true; 
          this.vehicleData = res['data'];
          localStorage.setItem("vehicleData", JSON.stringify(this.vehicleData));  
          localStorage.setItem("registrationNumber",this.vehicleData.registrationNumber.replace(/"/g, ''));  

        }else{
          this.toastr.error(res['data']['description'], 'Error');          
          this.loading = false;
        }
      }
    );
  }
  formatRegNo(value: string): string {
    // Remove all spaces and uppercase the value
    value = value.replace(/\s+/g, '').toUpperCase();

    // Insert spaces after the first 2 letters and the first 2 digits
    if (value.length > 2) {
        value = value.slice(0, 2) + ' ' + value.slice(2);
    }
    if (value.length > 5) {
        value = value.slice(0, 5) + ' ' + value.slice(5);
    }

    return value;
  }

  goToMake = () => {
    this.clearSelectedBrandCat();
    
    this.router.navigate(['make']);
  }

  getPopularBrandsAndCategory = () => {
    this.loading = true;
    this.homeService.popularBrandsAndCategory().subscribe(
      res => {
        if(res && res['code']==200){
          this.loading = false;

          if(res['data']['brands'])
            this.brands = res['data']['brands'];

          if(res['data']['categories'])
            this.categories = res['data']['categories'];


        }else{    
          // this.closePopup();
          // this.router.navigate(['home']);  
          this.toastr.error(res['message'], 'Error');
          
          this.loading = false;
        }
      }
    )
  }

  goToAllBrands = () => {
    this.router.navigate(['all-brands']);
  }

  getAllCategories = () => {
    this.loading = true;
    this.homeService.getAllCategories().subscribe(
      res => {
        // console.log(res);
        if(res && res['code']==200){
          this.loading = false;
          this.veiwMoreCat = false;
          this.veiwLessCat = true;

          if(res['data']){
            this.allCategories = res['data'].sort((a, b) => a.priority - b.priority);
          }
          // console.log(this.allCategories);
        }else{    
          this.toastr.error(res['message'], 'Error');          
          this.loading = false;
        }
      }
    )
  }

  viewLessBrands = () => {    
    this.veiwMoreCat = true;
    this.veiwLessCat = false;
  }

  // brandSparesList = (brand:any, brandImg:any) => {
  //   this.clearSelectedBrandCat();

  //   localStorage.setItem("selectedBrand",  brand);   
  //   localStorage.setItem("selectedBrandImg",  brandImg);   
  //   localStorage.setItem("vehicleType",  'universal');   
  //   this.router.navigate(["brand-spares-list"]);
  // }

  // categorySparesList = (brand:any, brandImg:any) => {
  //   this.clearSelectedBrandCat();
      
  //   localStorage.setItem("selectedCategory",  brand);   
  //   localStorage.setItem("selectedCategoryImg",  brandImg);  
  //   localStorage.setItem("vehicleType",  'universal');    
  //   this.router.navigate(["category-spares-list"]);
  // }

  clearSelectedBrandCat(){
    if(localStorage.getItem("selectedBrand")){
      localStorage.removeItem('selectedBrand');
    }
    
    if(localStorage.getItem("selectedBrandImg")){
      localStorage.removeItem('selectedBrandImg');
    }
    
    if(localStorage.getItem("selectedCategory")){
      localStorage.removeItem('selectedCategory');
    }

    if(localStorage.getItem("selectedCategoryImg")){
      localStorage.removeItem('selectedCategoryImg');
    }
  }

  // onSubmit() {
  //   if(this.formData.partNumber){
  //     localStorage.removeItem('searchPartNumber');
  //     localStorage.setItem("searchPartNumber",  this.formData.partNumber);  
      
  //     this.clearSelectedBrandCat();
  //     this.router.navigate(['spare-parts-list']);
  //   }
  // }

  getTopMake() {
    let data = sessionStorage.getItem('spareVehicleDetails');   
    let vehicleDetails;
    
    if(data){
      this.loading = false;
      vehicleDetails = data;
      let res = JSON.parse(vehicleDetails); 
      Object.values(res.data).forEach(make => {
        if(make[0]['title']!='Universal' && this.makeList.length<=6){
          this.makeList.push(make[0]);
        }
      });
    }else{
      this.loading = true;
      this.aspMakeService.getMake().subscribe(
        result => {
          this.loading = false;
          // console.log(result);
          let vehicleDetails = JSON.stringify(result);          
          this.setVehicleDetails(vehicleDetails);
          
          let res = JSON.parse(vehicleDetails); 
          
          Object.values(res.data).forEach(make => {
            if(make[0]['title']!='Universal' && this.makeList.length<=6){
              this.makeList.push(make[0]);
            }
          });
        }
      )
    }
  }

  setVehicleDetails(data){
    sessionStorage.setItem('spareVehicleDetails', data); 
  }

  selectMake(make: any) {
    this.loading = true;
    if(localStorage.getItem('selectedMake') && localStorage.getItem('selectedModel') && localStorage.getItem('selectedYear') && localStorage.getItem('variant'))
      localStorage.setItem('newMake', make); 
    else
      localStorage.setItem('selectedMake', make);  

    this.router.navigate(['model']);

  }

  changeCar() {
    localStorage.removeItem('vehicleData');
    localStorage.removeItem('registrationNumber');
    this.ngOnInit();
  }

  confirmCar() {
    let vehicleData = JSON.parse(localStorage.getItem('vehicleData'));


    localStorage.setItem('selectedMake', vehicleData['make']);
    localStorage.setItem('selectedModel', vehicleData['model']);
    localStorage.setItem('rowKey', vehicleData['rowKey']);
    localStorage.setItem('selectedYear', vehicleData['year']);
    
    localStorage.setItem('selectedFuel', vehicleData['fuel']);

    localStorage.setItem('variant', vehicleData['variant']);
    localStorage.setItem('variantKey', vehicleData['variantKey']);
    this.sparesList(vehicleData['make']+' '+vehicleData['model'], 'allSpares')
    // this.router.navigate(['spare-parts-list']);
  }
  
  sparesList(name, type, imageUrl='',key=''){
    const isFindMyCar = localStorage.getItem("isFindMyCar");
    
    if (isFindMyCar !== "1") {
        localStorage.setItem("selectedSparesList",JSON.stringify({ name, type, imageUrl, key }));
    }
    localStorage.setItem("isDynamicPopup", "true");
    this.router.navigate(['spare-parts-list']);
    // if(type=='brandSpares'|| type=='categorySpares'){
    //   this.bottomSheet.open(AspDynamicPopupComponent, {
    //     panelClass: 'bottom-sheet-missmach'  
    //   });
    // }
  }
  
  private clearLocalStorage(){
    sessionStorage.removeItem('sparePartsDetails');
    localStorage.removeItem('vehicleType');
    localStorage.removeItem('newMake');
    localStorage.removeItem('newModel');
    localStorage.removeItem('newYear');
    localStorage.removeItem('newVariant');
    localStorage.removeItem('newVariantKey');
    localStorage.removeItem('newRowKey');
    localStorage.removeItem('isShippingCharges');
    localStorage.removeItem('prepayUpiDiscount');
    // localStorage.removeItem('vehicleData');
    localStorage.removeItem('selectedModel');
    localStorage.removeItem('selectedYear');
    localStorage.removeItem('selectedFuel');
    localStorage.removeItem('variant');
    localStorage.removeItem('selectedFuel');
    localStorage.removeItem('variantKey');
    localStorage.removeItem('rowKey');

    if(localStorage.getItem("isFindMyCar")!='1'){
      localStorage.removeItem('selectedSparesList');
    }
    localStorage.removeItem('preSearchValue');
  };
  redirectToVehicleForm() {
    this.router.navigate(['vehicle']);
  }

  toggleSearch() {
    this.isInputVisible = !this.isInputVisible;
    if (this.isInputVisible) {
      setTimeout(() => {
        const input = document.querySelector('.SearchInput') as HTMLInputElement;
        if (input) {
          input.focus();
        }
      }, 0);
    }
  }

  clearSearch(event: Event) {
    this.searchText = '';
   event.stopPropagation();
    // this.searchText = ''; // Clear the search text
    this.isInputVisible = false; // Hide the input field if needed
    this.filterSuggestions();
  }

    
  filterSuggestions() {
    if (this.searchText && this.searchText.length >= 3) {
        let rowKey = localStorage.getItem('rowKey');
        
        let universal = false;
        let variantKey = localStorage.getItem('variantKey');
        
        this.searchSuggestionService.getSearchSuggestions(rowKey, this.searchText, universal, variantKey).subscribe(
            result => {


                this.suggestions = [];
                if (result['data'].partNumbers && result['data'].partNumbers.length > 0)
                    this.suggestions.push(...result['data'].partNumbers.map((value: string) => ({ value, type: 'partNumber' })));
                if (result['data'].brands && result['data'].brands.length > 0)
                    this.suggestions.push(...result['data'].brands.map((value: string) => ({ value, type: 'brand' })));
                if (result['data'].categories && result['data'].categories.length > 0)
                    this.suggestions.push(...result['data'].categories.map((value: string) => ({ value, type: 'category' })));
                if (result['data'].partNames && result['data'].partNames.length > 0)
                    this.suggestions.push(...result['data'].partNames.map((value: string) => ({ value, type: 'partName' })));


                // Sort suggestions based on relevance
                this.suggestions = this.sortSuggestionsByRelevance(this.suggestions, this.searchText);

            }
        );
    } else {
        this.suggestions = [];
    }
}

selectSuggestion(suggestion: { value: string, type: string }) {
  this.selectedSuggestions.add(suggestion.value);
  this.searchText = '';
  this.filterSuggestions();
  this.isInputVisible = false;
  if(localStorage.getItem("isFindMyCar")!='1'){
    if (suggestion.type === 'partNumber') {
        this.clearLocalStorage();
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigate(['product-details/part-number/' + suggestion.value]);
        });
    } else if (suggestion.type === 'category') {
        this.clearLocalStorage();
        localStorage.setItem('selectedSparesList', JSON.stringify({"name": suggestion.value, "type": "categorySpares", "imageUrl": ""}));
        
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigate(['/spare-parts-list']);
        });
    } else if (suggestion.type === 'brand') {
        this.clearLocalStorage();
        localStorage.setItem('selectedSparesList', JSON.stringify({"name": suggestion.value, "type": "brandSpares", "imageUrl": ""}));
        
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigate(['/spare-parts-list']);
        });
    }else {          
      this.clearLocalStorage();  
        localStorage.setItem("preSearchValue", suggestion.value);
        localStorage.setItem('selectedSparesList', JSON.stringify({"name": suggestion.value, "type": "allSpares", "imageUrl": ""}));
        
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigate(['/spare-parts-list']);
        });
    }
  }
}

sortSuggestionsByRelevance(suggestions: string[], searchText: string): string[] {
  return suggestions?.sort((a, b) => {
      const relevanceA = this.getRelevance(a, searchText);
      const relevanceB = this.getRelevance(b, searchText);
      return relevanceB - relevanceA; // Higher relevance comes first
  });
}
getRelevance(suggestion: string, searchText: string): number {
  if (suggestion?.toString().toLowerCase() === searchText?.toLowerCase()) {
      return 3; // Exact match
  } else if (suggestion?.toString().toLowerCase().startsWith(searchText?.toLowerCase())) {
      return 2; // Starts with match
  } else if (suggestion?.toString().toLowerCase().includes(searchText?.toLowerCase())) {
      return 1; // Contains match
  } else {
      return 0; // No match
  }
}
isFirstSuggestion(suggestion: string, index: number): boolean {
  // Check if it's the first suggestion and if it matches preSearchValue
  let preSearchValue=localStorage.getItem('preSearchValue');
  return index === 0 && preSearchValue === suggestion['value'];
}

getHighlightedText(text: any, search: any): string {
  if (typeof text !== 'string' || typeof search !== 'string') {
      return text;
  }

  if (!search) {
      return text; 
  }

  const parts = text.split(new RegExp(`(${search})`, 'gi'));
  return parts.map(part => part.toLowerCase() === search.toLowerCase()
      ? `<span class="highlight">${part}</span>`
      : part
  ).join('');
}


// @ViewChild('dynamicContainer', { read: ViewContainerRef }) container!: ViewContainerRef;
// loadorderdetails(): void {
//   this.container.clear();
//   const factory = this.resolver.resolveComponentFactory(SpareKitSidebarComponent);
//   const componentRef = this.container.createComponent(factory);
//   const componentInstance = componentRef.instance as SpareKitSidebarComponent;
//   componentInstance.isVisible = true;  
//   componentInstance.isConfirmationSidebarOpen = false;
// }
scrollRight() {
  const container = document.querySelector('.banners-container'); 
  if (container) {
      container.scrollBy({ left: 436, behavior: 'smooth' });
  } else {
      console.error('Element not found: .banners-container');
  }
}
scrollLeft() {
  const container = document.querySelector('.banners-container');
  if (container) {
      container.scrollBy({ left: -436, behavior: 'smooth' });
  } else {
      console.error('Element not found: .banners-container');
  }
}
// Stop auto-scroll on user interaction (optional)
stopAutoScroll() {
  this.autoScrollActive = false;
  if (this.autoScrollInterval) {
    clearInterval(this.autoScrollInterval);
  }
}

categoryBanner(){
  localStorage.setItem("selectedSparesList", JSON.stringify({"name":"Body","type":"categorySpares","imageUrl":"","key":""}));
  localStorage.setItem("selectedCategory",JSON.stringify({"Body":["Bumper"]}));
  localStorage.setItem("selectedBrands","[]");
  localStorage.setItem("filterParams",JSON.stringify({"subCategory":["Bumper"],"brand":[]}))
  this.router.navigate(['spare-parts-list']);
}
brandBanner(){
  localStorage.setItem("selectedSparesList", JSON.stringify({"name":"Bosch","type":"brandSpares","imageUrl":"https://cube.getpitstop.com/assets/img/brand/bosch_logo.png","key":"ebdeb4a88164633bb71c1d9a0d647f7c"}));
  localStorage.setItem("selectedCategory",JSON.stringify({"Windscreen Cleaning System":["Wiper Blade"]}));
  localStorage.setItem("selectedBrands","[]");
  localStorage.setItem("filterParams",JSON.stringify({"subCategory":["Wiper Blade"],"brand":[]}))
  this.router.navigate(['spare-parts-list']);
}
Purolator_Filters()
{
  localStorage.setItem("selectedSparesList", JSON.stringify({"name":"Purolator","type":"brandSpares","imageUrl":"https://cube.getpitstop.com/prodimages/purolator.png","key":"3484ac680af6165afc8b35e3736cab67"}))
  localStorage.setItem("filterParams",JSON.stringify({"subCategory":["Cabin Filter","Filter Set","Fuel Filter","Oil Filter","Air Filter"],"brand":[]}));
  localStorage.setItem("selectedBrands","[]");
  localStorage.setItem("selectedCategory",JSON.stringify({"Filters":["Cabin Filter","Filter Set","Fuel Filter","Oil Filter","Air Filter"]}));
  this.router.navigate(['spare-parts-list']);
}
Floron_AC_Gas(){
  localStorage.setItem("selectedSparesList", JSON.stringify({"name":"Floron","type":"brandSpares","imageUrl":"https://cube.getpitstop.com/assets/img/brand/floron.png","key":"1be1b57c092bc60a73926d12cfb96f3f"}))
  this.router.navigate(['spare-parts-list']);
}
Hella_Coolant_Premium1(){
  localStorage.setItem("selectedSparesList", JSON.stringify({"name":"Hella","type":"brandSpares","imageUrl":"https://cube.getpitstop.com/assets/img/brand/hella_icon.png","key":"cc6dd726ba70ac8caafc8f22d82758cd"}));
  localStorage.setItem("selectedCategory",JSON.stringify({"Engine Cooling System":["Coolant"]}));
  localStorage.setItem("selectedBrands","[]");
  localStorage.setItem("filterParams",JSON.stringify({"subCategory":["Coolant"],"brand":[]}))
  this.router.navigate(['spare-parts-list']);
}
Maruti_Suzuki_Clutch_Release()
{
  localStorage.setItem("selectedSparesList", JSON.stringify({"name":"Maruti Suzuki","type":"brandSpares","imageUrl":"https://cube.getpitstop.com/assets/img/marutisuzuki.png","key":"3dd5a0e87558f673fa4caa008d3ccafa"}));
  localStorage.setItem("selectedCategory",JSON.stringify({"Clutch System":["Clutch Release Bearing"]}));
  localStorage.setItem("selectedBrands","[]");
  localStorage.setItem("filterParams",JSON.stringify({"subCategory":["Clutch Release Bearing"],"brand":[]}))
  this.router.navigate(['spare-parts-list']);
}
NGK_Spark_Plugs_1(){
  
  localStorage.setItem("selectedSparesList", JSON.stringify({"name":"NGK","type":"brandSpares","imageUrl":"https://cube.getpitstop.com/assets/img/brand/ngk.png","key":"1d530dd95b641c9bf3254f7d66c6299e"}))
  localStorage.setItem("filterParams",JSON.stringify({"subCategory":["Spark Plug"],"brand":[]}));
  localStorage.setItem("selectedCategory",JSON.stringify({"Ignition and Glowplug System":["Spark Plug"]}));
  localStorage.setItem("selectedBrands","[]");
  this.router.navigate(['spare-parts-list']);
}
TVS_Girling_Brake_Pads_1()
{
  localStorage.setItem("selectedSparesList", JSON.stringify({"name":"TVS Girling","type":"brandSpares","imageUrl":"https://cube.getpitstop.com/assets/img/brand/Tvsgirling.png","key":"91f95eb3bd81f5aceeee9b9c440d289d"}));
  localStorage.setItem("selectedCategory",JSON.stringify({"Brake System":["Brake Pads"]}));
  localStorage.setItem("selectedBrands","[]");
  localStorage.setItem("filterParams",JSON.stringify({"subCategory":["Brake Pads"],"brand":[]}))
  this.router.navigate(['spare-parts-list']);
}
Zip_AC_Filters(){
  localStorage.setItem("selectedSparesList", JSON.stringify({"name":"Zip","type":"brandSpares","imageUrl":"https://cube.getpitstop.com/prodimages/zip.png","key":"99010775ee7376912dc709e916fce456"}));
  localStorage.setItem("selectedCategory",JSON.stringify({"Filters":["Cabin Filter"]}));
  localStorage.setItem("selectedBrands","[]");
  localStorage.setItem("filterParams",JSON.stringify({"subCategory":["Cabin Filter"],"brand":[]}))
  this.router.navigate(['spare-parts-list']);
}
Head_Lamp_1(){
  localStorage.setItem("selectedSparesList", JSON.stringify({"name":"Lighting","type":"categorySpares","imageUrl":"","key":""}));
  localStorage.setItem("selectedCategory",JSON.stringify({"Lighting":["Headlight"]}));
  localStorage.setItem("selectedBrands","[]");
  localStorage.setItem("filterParams",JSON.stringify({"subCategory":["Headlight"],"brand":[]}))
  this.router.navigate(['spare-parts-list']);
}
shareDetails(){
  const message = `🚘 Vehicle Information \n\nDear Customer,\n\nHere are the details of your car with Registration Number *${this.vehicleData.registrationNumber}*\n\n 1️⃣ *Make:* ${this.vehicleData.make} \n 2️⃣ *Model:*  ${this.vehicleData.model}  \n 3️⃣ *Variant:*  ${this.vehicleData.variant}  \n 4️⃣ *MFG Year:* ${this.vehicleData.year}  \n 5️⃣ *Fuel Type:* ${this.vehicleData.fuel} \n 6️⃣ *VIN*: ${this.vehicleData.vin}  \n\n Regards, \n *Autoverse Mobility Pvt. Ltd.*`;
  console.log("message",message)
  const url = `https://api.whatsapp.com/send?text=${encodeURIComponent(message)}`;
  window.open(url, '_blank');
}


// Google Analytics
onButtonClick(){
  this.googleAnalyticsService.triggerEvent('button_click', {
    category: 'User Interaction - Testing.',
    label: 'Click Me Button',
    value: 1,
  });
}
// End Google Analytics
}


